import React, { createRef } from 'react';
import K from 'k';

import { Stage, Layer } from 'react-konva';
import { CanvasDataContext, CanvasSelectionContext, CanvasCircle, CanvasTransformer } from 'canvas';
import { ReactReduxContext, Provider } from 'react-redux';

import _ from 'lodash';
import lib from 'lib';
import memoObject from 'helpers/memo-object';
import ProjectDataContext from 'contexts/project-data-context';
import NumericInputContext from 'contexts/numeric-input-context';
import PositionHelper from 'helpers/position-helper';
// import C2S from 'canvas2svg';

//https://github.com/gliffy/canvas2svg/blob/master/canvas2svg.js

class CanvasView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      scale: this.props.scale || 1,
      offset: {x: 0, y: 0},
      containerSize: {width: 1, height: 1}, //HINT 0, 0 throws konva errors
      inAddMode: false,
      isDragging: false,
      isShifting: false,
      isPressingCtrlCmd: false,
      isStatic: this.props.isStatic,
      // inDebugMode: true
    };

    this.stageRef = props.stageRef || createRef();
    this.layerRef = props.layerRef || createRef();
    this.transformerRef = createRef();

    this.debouncedHandleWindowResize = _.debounce(this.handleWindowResize, 100);
  }

  componentDidMount() {
    if (!this.props.isStatic) {
      window.addEventListener('resize', this.debouncedHandleWindowResize);

      document.addEventListener('keydown', this.handleKeyDown);
      document.addEventListener('keyup', this.handleKeyUp);
    }

    this.handleWindowResize();

    var {containerSize, scale, offset, isStatic} = this.state;

    if (isStatic) {
      containerSize = this.props.containerSize || containerSize;
      scale = this.props.scale || scale;
    }

    const canvasData = {
      containerSize,
      scale,
      offset,
      layer: this.layerRef.current,
      stage: this.stageRef.current,
      precision: this.props.precision || 0.125,
      theme: 'light',
    };

    if (this.props.onCanvasDataChange) this.props.onCanvasDataChange(canvasData);

    // setTimeout(() => {
    //   var oldContext = this.layerRef.canvas.context._context;

    //   var c2s = this.layerRef.canvas.context._context = C2S({...this.state.containerSize, ctx: oldContext});

    //   this.forceUpdate();

    //   setTimeout(() => {
    //     console.log(c2s.getSerializedSvg());

    //     this.layerRef.canvas.context._context = oldContext;

    //     this.forceUpdate();
    //   })
    // }, 5000);
  }

  componentDidUpdate(_prevProps, prevState) {
    const relevantKeys = ['scale', 'offset', 'containerSize', 'precision', 'isShifting', 'isDragging'];
    const viewModeChanged = _prevProps.viewMode !== this.props.viewMode;
    const shouldPushCanvasDataUpdates = viewModeChanged || _.some(relevantKeys, stateKey => {
      return !_.isEqual(prevState[stateKey], this.state[stateKey]) || (this.props.isStatic && (!_.isEqual(_prevProps[stateKey], this.props[stateKey])));
    });

    if ((shouldPushCanvasDataUpdates) && this.props.onCanvasDataChange) {
      let {containerSize, scale, offset, isShifting, isDragging} = this.state;

      if (this.props.isStatic) {
        containerSize = this.props.containerSize || containerSize;
        scale = this.props.scale || scale;
      }

      if (viewModeChanged) {
        scale = 1;
        offset = {x: 0, y: 0};
        this.setState({scale, offset});
      }

      //HINT only pushes data that's relevant the PositionHelper.toReal or .toCanvas
      const canvasData = {
        containerSize,
        scale,
        offset,
        stage: this.stageRef.current,
        layer: this.layerRef.current,
        precision: this.props.precision || 0.125,
        isShifting,
        theme: 'light',
        isDragging,
        infinitePrecision: this.props.infinitePrecision,
      };

      this.props.onCanvasDataChange(canvasData);
    }

    if (this.props.onRender) this.props.onRender();
  }

  componentWillUnmount() {
    if (!this.props.isStatic) {
      window.removeEventListener('resize', this.debouncedHandleWindowResize);

      document.removeEventListener('keydown', this.handleKeyDown);
      document.removeEventListener('keyup', this.handleKeyUp);
    }
  }

  getContainerRef = (ref) => this.containerRef = ref;
  getScrollContainerRef = (ref) => this.scrollContainerRef = ref;

  handleWindowResize = () => {
    var containerBoundingRect = this.containerRef.getBoundingClientRect();

    var containerSize = {
      width: containerBoundingRect.right - containerBoundingRect.left,
      height: containerBoundingRect.bottom - containerBoundingRect.top
    };

    if (containerSize.width && containerSize.height) this.setState({containerSize});
  };

  //HINT offset is a positive value that is a pre-scaled/in-canvas value
  //HINT offset is a positive value that is subtracted
  //HINT both of these are holdovers from DEv1 that weren't worth refactoring a bunch of existing code to change to make more intuitive (ideally offset would be negative and not scaled)
  handleWheel = ({evt: event}) => {
    var {scale, offset, containerSize} = this.state;

    event.preventDefault();

    // SCALE
    var deltaY = event.deltaY;
    var magnitude = Math.abs(deltaY) * 0.001;
    var scaleDelta = deltaY < 0 ? ((1 + magnitude)) : (1 / (1 + magnitude));
    var newScale = Math.min(Math.max(scale * scaleDelta, 1 / 8), 128);

    scaleDelta = newScale / scale; //correct scale delta for constrained scale

    //don't do anything if scale hasn't changed
    if (scaleDelta !== 1) {
      // OFFSET
      //basic offset correction for scaling regardless of mouse pos
      //disable mouse logic and set offset to something like x: 250 in state to test
      var basicOffsetDelta = lib.object.difference(lib.object.multiply(offset, scaleDelta), offset);

      var mousePosOffsetPercentages = {
        x: event.layerX / containerSize.width - 0.5,
        y: event.layerY / containerSize.height - 0.5
      }; //HINT -0.5 to 0.5

      //offset relative to mouse
      var mouseOffsetDelta = _.mapValues(mousePosOffsetPercentages, (percentage, axisKey) => {
        return percentage * containerSize[axisKey === 'x' ? 'width' : 'height'] * (scaleDelta - 1);
      });

      //offset accounting for both
      var offsetDelta = lib.object.sum(basicOffsetDelta, mouseOffsetDelta);

      var newOffset = lib.object.sum(offset, offsetDelta);

      this.setState({offset: newOffset, scale: newScale});
    }
  };

  handleMouseDown = (event) => {
    if (!this.state.isStatic) {
      //HINT deselect when clicked on empty area
      if (event.evt.button === 0 && event.target === event.target.getStage() && !this.state.inAddMode && !this.state.isShifting) {
        this.deselect();
      }
      else if (event.evt.button === 1 && !this.state.isDragging) {
        const container = event.target.getStage().container();

        container.style.cursor = 'grabbing';

        this.setState({
          isDragging: true,
          lastMousePosition: event.target.getStage().getPointerPosition(),
        });
      }

      if (this.props.onMouseDown) this.props.onMouseDown({event, inAddMode: this.state.inAddMode});
    }
  };

  handleMouseUp = (event) => {
    if (!this.state.isStatic) {
      if ((event.evt.button === 1) && this.state.isDragging) {
        const container = event.target.getStage().container();

        container.style.cursor = 'inherit';

        this.setState({
          isDragging: false,
          lastMousePosition: undefined,
        });
      }

      if (this.props.onMouseUp) this.props.onMouseUp(event);
    }
  };

  handleMouseMove = (event) => {
    if (!this.state.isStatic) {
      this.lastMousePosition = event.target.getStage().getPointerPosition();

      if (this.state.isDragging) {
        const transform = lib.object.difference(this.state.lastMousePosition, event.target.getStage().getPointerPosition());
        const offset = lib.object.sum(this.state.offset, transform);

        this.setState({
          offset,
          lastMousePosition: event.target.getStage().getPointerPosition(),
        });
      }

      if (this.props.onMouseMove) this.props.onMouseMove(event);
    }
  };

  deselect = () => {
    if (this.props.selectionData) this.props.selectionData.onDeselect();
  };

  handleKeyDown = (event) => {
    if (!this.state.isStatic) {
      if (lib.event.keyPressed(event, 'esc') && _.get(this.props, 'selectionData.activeEntities.length') !== 0) {
        this.deselect();
      }
      if (!this.state.inAddMode) { //HINT need to support pasting, nothing is necessarily selected //this.props.selectionData.activeEntityId !== undefined) {
        if (this.props.selectionData) this.props.selectionData.handleKeyPress(event);
      }
      if (!this.state.isShifting && event.shiftKey) {
        this.setState({isShifting: true});
      }
      if (lib.event.keyPressed(event, 'ctrlcmd') && !this.state.isPressingCtrlCmd) {
        this.setState({isPressingCtrlCmd: true});
      }
      if (_.get(this.props.selectionData, 'activeEntities.length') > 1 && lib.event.keyPressed(event, 'delete') && document.activeElement.tagName === 'BODY') {
        const container = this.stageRef.current.container();

        container.style.cursor = 'inherit';

        if (this.props.onMultiSelectDelete) this.props.onMultiSelectDelete();
      }
      // if (lib.event.keyPressed(event, 'alt') && !this.state.isPressingAlt) {
      //   this.setState({isPressingAlt: true});

      //   if (this.state.isPressingQ) {
      //     this.setState({inDebugMode: true});
      //   }
      // }
      // if (lib.event.keyPressed(event, 'd') && !this.state.isPressingQ) {
      //   this.setState({isPressingQ: true});

      //   if (this.state.isPressingAlt) {
      //     this.setState({inDebugMode: true});
      //   }
      // }
    }
  };

  handleKeyUp = (event) => {
    if (!this.state.isStatic) {
      if (this.state.isShifting && !event.shiftKey) {
        this.setState({isShifting: false});
      }
      else if (lib.event.keyPressed(event, 'ctrlcmd') || event.which === 91 && this.state.isPressingCtrlCmd) {
        this.setState({isPressingCtrlCmd: false});
      }
      else if (lib.event.keyPressed(event, 'alt') || event.which === 18 && this.state.isPressingAlt) {
        this.setState({isPressingAlt: false, inDebugMode: false});
      }
      // else if (lib.event.keyPressed(event, 'q') || event.which === 68 && this.state.isPressingQ) {
      //   this.setState({isPressingQ: false, inDebugMode: false});
      // }
    }
  };

  forEachObject(callback) {
    _.forEach(this.props.objects, callback);
  }

  handleEnterAddMode = () => {
    this.setState({inAddMode: true});
  };

  handleExitAddMode = () => {
    this.setState({inAddMode: false});
  };

  getLastMousePosition = () => {
    return this.lastMousePosition;
  };

  //HINT attempt to cache canvasData to avoid a react rerender when nothing has actually changed
  get canvasData() {
    var {containerSize, scale, offset, isShifting, isPressingCtrlCmd, isStatic, inDebugMode, isDragging} = this.state;

    if (isStatic) {
      containerSize = this.props.containerSize || containerSize;
      scale = this.props.scale || scale;
    }

    var orthoMode = isShifting;

    if (this.props.isOrthographicLockEnabled && !isShifting) orthoMode = true;
    if (this.props.isOrthographicLockEnabled && isShifting) orthoMode = false;

    var canvasData = {
      containerSize,
      scale,
      offset,
      isShifting,
      isPressingCtrlCmd,
      inDebugMode,
      isDragging,
      isStatic: this.props.isStatic,
      orthoMode,
      precision: this.props.precision || 0.125,
      theme: 'light',
      stage: this.stageRef.current,
      layer: this.layerRef.current,
      onEnterAddMode: this.handleEnterAddMode,
      onExitAddMode: this.handleExitAddMode,
      getSnapData: this.props.getSnapData,
      getLastMousePosition: this.getLastMousePosition,
      infinitePrecision: this.props.infinitePrecision,
    };

    return memoObject(canvasData, '_canvasData', {cacheMap: this});
  }

  render() {
    var {containerSize, isStatic} = this.state;
    var {canvasData} = this;

    if (isStatic) {
      containerSize = this.props.containerSize || containerSize;
    }
    if (canvasData.inDebugMode && this.props.getSnapData) var {candidateSnapPositions, debugModeSourceSnapPositions} = this.props.getSnapData();

    return (
      <div style={{width: '100%', backgroundColor: 'white', position: 'relative', ...this.props.style}} className={this.props.className} ref={this.getContainerRef}>
        {/* WARNING stage seems to reset contexts so we need to get context values and re-provide them */}
        <ReactReduxContext.Consumer>
          {({store}) => (
            <Stage
              ref={this.stageRef}
              onWheel={!isStatic ? this.handleWheel : undefined}
              onMouseDown={!isStatic ? this.handleMouseDown : undefined}
              onMouseUp={!isStatic ? this.handleMouseUp : undefined}
              onMouseMove={!isStatic ? this.handleMouseMove : undefined}
              {...containerSize}
            >
              <Provider store={store}>
                <Layer name="main" ref={this.layerRef}>
                  <ProjectDataContext.Provider value={this.props.projectData}>
                    <NumericInputContext.Provider value={this.props.numericInputData}>
                      <CanvasSelectionContext.Provider value={this.props.selectionData}>
                        <CanvasDataContext.Provider value={canvasData}>
                          {this.props.children}
                          {canvasData.inDebugMode && _.map(candidateSnapPositions, (point, index) => <CanvasCircle key={`candidate-snap-point-${index}`} {...{...PositionHelper.toCanvas(point, canvasData)}} fill='red' radius={5} />)}
                          {canvasData.inDebugMode && _.map(debugModeSourceSnapPositions, (point, index) => <CanvasCircle key={`candidate-snap-point-${index}`} {...{...PositionHelper.toCanvas(point, canvasData)}} fill='green' radius={5} />)}
                        </CanvasDataContext.Provider>
                      </CanvasSelectionContext.Provider>
                    </NumericInputContext.Provider>
                  </ProjectDataContext.Provider>
                </Layer>
              </Provider>
              <Layer name="top-layer" />
              <Layer name="dimension-dots-layer" />
              <Layer name="hud-layer" />
              <Layer name="masking-layer" />
            </Stage>
          )}
        </ReactReduxContext.Consumer>
      </div>
    );
  }
}

export default CanvasView;
