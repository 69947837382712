import _ from 'lodash';
import Container from './container';
import Elevation from './elevation';
import lib from 'lib';
import Product from './product';
import Project from './project';
import Room from './room';

import MaterialsAndAppliancesDrawingsDataHelper from 'pages/drawings/helpers/materials-and-appliances-drawings-data';
//HINT important because it defines path.getPathData()
import 'path-data-polyfill';

var Drawings = {
  getAppliances({project}) {
    const {companyKey} = project;
    const {appliances, products, containers} = Project.get(['appliances', 'products', 'containers'], {project});
    var usedAppliances = [];

    _.forEach(products, product => {
      var applianceInstancesData = Product.getApplianceInstancesData({product});

      _.forEach(product.appliancesData, (applianceData, index) => {
        var isSTDoubleAppliance = product.productId === 1132;
        //HINT handle swap product bug where appliance data persisted on product even though the product doesn't have an appliance
        var isValidAppliance = (Product.getIsAppliance({product}) || Product.getHasSink({product})) && applianceInstancesData[index];

        if (isValidAppliance && product.productId !== 1529 && !(product.productId === 1586 && index + 1 > _.get(product, 'customData.applianceQuantity', 1)) && !(isSTDoubleAppliance && index !== 0)) {
          let appliance = _.cloneDeep(_.find(appliances, {id: applianceData.id}));

          const productData = Product.getProductData({product});
          const customData = _.get(product, 'customData', {});
          const hasSink = Product.getHasSink({product});
          var applianceType = Product.getApplianceType({product, index, showDisplayedApplianceType: true});
          var titleSet = false;

          if (applianceData.customModelNumber || applianceData.modelNumber) {
            appliance = {vendor: applianceData.customVendor || applianceData.vendor, modelNumber: applianceData.customModelNumber || applianceData.modelNumber};
          }
          else if (!appliance || applianceData.id === 0) {
            appliance = {modelNumber: 'TBD', title: `${hasSink ? 'sink' : 'appliance'} not yet selected`};
            titleSet = true;
          }

          if (appliance && !titleSet) {
            let isByOthers = true;

            //HINT currently the only appliances NOT by others are some ST sinks
            if (companyKey === 'vp' && hasSink && customData.isByOthers === 0) {
              isByOthers = false;
            }

            if (_.includes([748, 1223, 1224, 747, 746], productData.id)) isByOthers = true;

            appliance.title = `${appliance.title ? `${appliance.title}` : `${!hasSink ? applianceType : ''}`}${hasSink ? ' sink' : ''}${(isByOthers) ? (companyKey === 'vp' ? ' by others' : '') : ' provided by Space Theory'}`;
          }

          if (appliance) {
            usedAppliances.push({...appliance, applianceType, instance: product, context: 'product'});
          }
        }
      });
    });

    const applianceContainers = _.filter(containers, container => {
      const containerType = Container.getTypeDataFor({container});

      return _.includes(containerType.title.toLowerCase(), 'appliance');
    });

    _.forEach(applianceContainers, applianceContainer => {
      const customData = _.get(applianceContainer, 'customData', {});

      if (customData.vendor && customData.modelNumber) {
        usedAppliances.push({
          ..._.pick(customData, ['modelNumber', 'vendor', 'applianceType']),
          title: `${customData.label ? `${customData.label}${companyKey === 'vp' ? ' ' : ''}` : ''}${companyKey === 'vp' ? 'by others' : ''}`,
          instance: applianceContainer,
          context: 'container'
        });
      }
    });

    //HINT bandaid for large julianna project that was cutting off glossary and client signature blank
    // if (project.id === 8257) usedAppliances = [];

    return usedAppliances;
  },

  getSiteAccessDimensions({project}) {
    const products = Project.get('products', {project});
    const boxProducts = _.filter(products, product => Product.getHasBox({product}));
    const panelProducts = _.filter(products, product => Product.getIsPanel({product}));

    const siteAccessDimensions = [];

    if (boxProducts.length > 0) {
      const tallestProduct = _.maxBy(boxProducts, (productInstance) => {
        return Product.getProducibleDimensions({product: productInstance}).height;
      });

      const widestProduct = _.maxBy(boxProducts, (productInstance) => {
        const producibleDimensions = Product.getProducibleDimensions({product: productInstance});

        return _.max([producibleDimensions.width, producibleDimensions.depth]);
      });

      const tallestProducibleDimensions = Product.getProducibleDimensions({product: tallestProduct});
      const widestProducibleDimensions = Product.getProducibleDimensions({product: widestProduct});

      siteAccessDimensions.push(
        {title: 'Tallest Unit', dimensions: tallestProducibleDimensions},
        {title: 'Widest Unit', dimensions: widestProducibleDimensions}
      );
    }

    if (panelProducts.length > 0) {
      const largestPanel = _.maxBy(panelProducts, (productInstance) => {
        const dimensions = Product.getProducibleDimensions({product: productInstance});
        const diagonalDimension = Math.sqrt((Math.pow(dimensions.height, 2)) + (Math.pow(dimensions.width, 2)));

        return diagonalDimension;
      });

      const largestPanelProducibleDimensions = Product.getProducibleDimensions({product: largestPanel});

      siteAccessDimensions.push(
        {title: 'Largest Panel/Slab', dimensions: largestPanelProducibleDimensions}
      );
    }

    return siteAccessDimensions;
  },

  getPageData({detailLevel, standardPages, project, materialTypes}) {
    let pages = [];

    pages.push({id: 'cover', type: 'cover', title: 'Cover Sheet'});

    if (detailLevel === 'installation' && project.companyKey === 'hb') {
      pages.push({id: 'typicalInstallationDetails1', type: 'imgPage', title: 'Typical Installation Details'});
      pages.push({id: 'typicalInstallationDetails2', type: 'imgPage', title: 'Typical Installation Details'});
      pages.push({id: 'typicalInstallationDetails3', type: 'imgPage', title: 'Typical Installation Details'});
      pages.push({id: 'typicalInstallationDetails4', type: 'imgPage', title: 'Typical Installation Details'});
    }

    //HINT isMeasured is read later in drawings-page.js to determine whether or not to pass handleSetFooterHeight
    if (detailLevel !== 'schematic') pages.push({id: 'criticalSchedules', type: 'criticalSchedules', title: 'Site Access Verification', isMeasured: true});

    var appliancesData = MaterialsAndAppliancesDrawingsDataHelper.getAppliancesData({project});
    var rooms = Project.get('rooms', {project});
    var products = MaterialsAndAppliancesDrawingsDataHelper.getFilteredProducts({products: Project.get('products', {project})});
    var applications = MaterialsAndAppliancesDrawingsDataHelper.getApplications({products});
    var appliancesByRooms = MaterialsAndAppliancesDrawingsDataHelper.getAppliancesByRooms({rooms, appliancesData});
    var {appliedMaterials, appliedPulls} = MaterialsAndAppliancesDrawingsDataHelper.getAppliedMaterialsAndPulls({applications, materialTypes, companyKey: project.companyKey});

    var sortedAppliedMaterials = _.orderBy(_.flattenDeep(appliedMaterials), [({totalProductInstances}) => {
      return _.uniq(totalProductInstances).length;
    }], ['desc']);

    var sortedAppliedPulls = _.orderBy(_.flattenDeep(appliedPulls), [({totalProductInstances}) => {
      return _.uniq(totalProductInstances).length;
    }], ['desc']);

    var sortedAppliedMaterialsColumns = _.chunk(sortedAppliedMaterials, 6);
    var sortedAppliedPullsRows = _.chunk(sortedAppliedPulls, 3);

    var sortedAppliedMaterialsPages = _.chunk(sortedAppliedMaterialsColumns, 2); //Two columns per page
    var sortedAppliedPullsPages = _.chunk(sortedAppliedPullsRows, 3); //Three rows per page
    var totalAppliancesInList = _.sum(_.map(appliancesByRooms, ({appliances}) => (appliances[0].length)));

    var materialsAndAppliancesPages = []

    if (totalAppliancesInList > 30 || sortedAppliedMaterialsPages.length > 1 || sortedAppliedPullsPages.length > 1 || rooms.length > 8) {
      sortedAppliedPullsRows = _.chunk(sortedAppliedPulls, 6);
      sortedAppliedPullsPages = _.chunk(sortedAppliedPullsRows, 2);
      sortedAppliedMaterialsPages = [_.chunk(sortedAppliedMaterials, _.ceil(sortedAppliedMaterials.length /2))];

      materialsAndAppliancesPages.push(
        {type: 'materialsAndAppliances', title: 'Materials and Appliances', data: {sortedAppliedMaterialsPages, sortedAppliedPullsPages}, paginate: true, id: `materialAppliances-0`},
        {type: 'materialsAndAppliances', title: 'Materials and Appliances', data: {appliancesByRooms}, paginate: true, id: `materialAppliances-1`}
      );
    }
    else {
      materialsAndAppliancesPages.push(
        {id: `materialAppliances-0`, type: 'materialsAndAppliances', title: 'Materials and Appliances', data: {sortedAppliedMaterialsPages, sortedAppliedPullsPages, appliancesByRooms}, paginate: false}
      )
    }

    pages.push(...materialsAndAppliancesPages);

    _.forEach(standardPages, (standardPage, i) => {
      if (detailLevel === 'schematic' && i === 0) {
        pages.push({type: 'standard', ...standardPage, isMeasured: true});
      }
      else {
        pages.push({type: 'standard', ...standardPage});
      }
    });

    pages = _.map(pages, page => ({...page,
      title: page.title || _.startCase(page.type),
    }));

    return pages;
  },

  getSizeForPrintArea({printArea, scale, project}) {
    var {padding, contentSize} = printArea;
    var canvasSize;

    padding = _.defaults(padding, {width: 3, height: 3});

    canvasSize = lib.object.sum(contentSize, lib.object.multiply(padding, 2));
    canvasSize = lib.object.multiply(canvasSize, scale);

    return canvasSize;
  },

  getMaxSizes({project, detailLevel}) {
    const {rooms, elevations} = Project.get(['rooms', 'elevations'], {project});

    return {
      elevation: lib.object.max(..._.map(_.values(elevations), elevation => {
        return lib.object.sum(Elevation.getSize({elevation}), {width: 120, height: Elevation.getProjectionHeightFor({elevation, drawingsMode: detailLevel})});
      })),
      room: lib.object.max(..._.map(_.values(rooms), room => Room.getSize({room}))),
    };
  },

  getAlphabetLabelForIndex(index) {
    var temp, letter = '';
    while (index >= 0)
    {
      temp = index % 26;
      letter = String.fromCharCode(temp + 65) + letter;
      index = (index - temp - 1) / 26;
    }
    return letter.toLowerCase();
  },

  async autogenerateStandardPages({project, continuousDrawingsAutogenerationDisabled, override = false, autogenerateStrategy='standard', containerDimensions, resourceActionDispatchers}) {
    var {rooms, floors, containers} = Project.get(['floors', 'rooms', 'containers'], {project});
    const isSpaceTheory = project.companyKey === 'vp';

    var floorsUsingRank = _.some(floors, floor => floor.rank);

    floors = _.sortBy(floors, floorsUsingRank ? 'rank' : 'id');

    let standardPages = [];

    const scaleValue = Drawings.getScale({project, containerDimensions});

    _.forEach(floors, floor => {
      //if floor rooms.length > 1
      //add a page with the floor overview
      var floorRooms = _.filter(rooms, {floorId: floor.id});
      var roomsUsingRank = _.some(floorRooms, room => room.rank);

      floorRooms = _.sortBy(floorRooms, roomsUsingRank ? 'rank' : 'id');

      if (floorRooms.length > 0) {
        var getDataForOutlines = (outlines) => {
          var points = _.flatten(outlines);
          var xs = _.map(points, 'x'), ys = _.map(points, 'y');
          var minX = _.min(xs), minY = _.min(ys);
          var maxX = _.max(xs), maxY = _.max(ys);
          var size = {width: maxX - minX, height: maxY - minY};

          return {size};
        };

        var {size: floorSize} = getDataForOutlines(_.map(floorRooms, floorRoom => _.map(floorRoom.plan.points, point => lib.object.sum(floorRoom.plan.position, point))));
        var floorScale = parseFloat(_.min([containerDimensions.width / (floorSize.width + 100), (containerDimensions.height * 0.6) / (floorSize.height + 87)]).toFixed(2));

        if (floorRooms.length !== 1) standardPages.push({floorId: floor.id, layout: [{floorId: floor.id, position: {x: 0.5, y: 0.5}}], scale: floorScale, title: `overview - ${floor.title}`});

        _.forEach(floorRooms, room => {
          let elevations = Room.get('sortedElevations', {room});

          if (!project.continuousElevationAutogenerationDisabled && containers.length > 0) {
            elevations = _.filter(elevations, elevation => {

              //HINT created because frontContainers may be more specific than just containers with the same angle and sideKey doesn't get set by default so is sometimes undefined/incorrect until page completes loading
              return Elevation.getIsSection({elevation}) || Elevation.getSameAngleContainers({elevation}).length > 0;
            });
          }

          if (elevations.length > 0) {
            var elevationPagesFor = (elevationChunk) => {
              return _.map(elevationChunk, (elevation) => {
                var layout;
                var rank = elevations.indexOf(elevation) + 1;

                var elevationScale = parseFloat(_.min([containerDimensions.width / (Elevation.getWidth({elevation}) + 100), (containerDimensions.height * 0.6) / (Elevation.getHeight({elevation}) + 87)]).toFixed(2));
                var roomScale = parseFloat(_.min([(containerDimensions.width * 0.25) / (Room.getWidth({room}) + 100), (containerDimensions.height * 0.25) / (Room.getHeight({room}) + 87)]).toFixed(2));

                layout = [
                  {roomId: room.id, position: {x: isSpaceTheory ? 0.885 : 0.115, y: isSpaceTheory ? 0.115 : 0.885}, scale: roomScale, isContext: true}, // double check the 0.115 back to original.                {elevationId: elevation.id, position: {x: 0.5, y: 0.5}, scale: elevationScale}
                  {elevationId: elevation.id, position: {x: isSpaceTheory ? 0.45 : 0.55, y: isSpaceTheory ? 0.55 : 0.45}, scale: elevationScale}
                ];

                return {elevationId: elevation.id, layout, scale: scaleValue, title: `elevation ${rank} - ${room.title}`};
              });
            };

            var elevationChunks = autogenerateStrategy === 'excludeOverviews' ? [elevations] : _.chunk(elevations, 3);

            _.forEach(elevationChunks, (elevationChunk, index) => {
              var layout;

              if (elevationChunk.length === 1) {
                layout = [
                  {roomId: room.id, position: {x: isSpaceTheory ? 0.75 : 0.25, y: isSpaceTheory ? 0.25 : 0.75}},
                  {elevationId: elevationChunk[0].id, position: {x: isSpaceTheory ? 0.25 : 0.75, y: isSpaceTheory ? 0.75 : 0.25}}
                ];
              }
              else if (autogenerateStrategy === 'standard' || autogenerateStrategy === 'overviewsOnly') {
                layout = _.map(elevationChunk, elevation => ({elevationId: elevation.id}));
                layout.splice(isSpaceTheory ? 0 : 2, 0, {roomId: room.id});
                layout = _.map(layout, (current, index) => ({
                  ...current,
                  position: {
                    x: 0.25 + 0.5 * (index % 2),
                    y: 0.25 + 0.5 * Math.floor(index / 2)
                  }
                }));
              }

              if (autogenerateStrategy === 'standard' || autogenerateStrategy === 'overviewsOnly') {
                standardPages.push({layout, scale: scaleValue, title: `overview - ${room.title} ${elevationChunks.length > 1 ? Drawings.getAlphabetLabelForIndex(index) : ''}`});
              }

              var roomScale = parseFloat(_.min([(containerDimensions.width) / (Room.getWidth({room}) + 100), (containerDimensions.height) / (Room.getHeight({room}) + 87)]).toFixed(2));

              if (autogenerateStrategy === 'standard') {
                standardPages.push({layout: [{roomId: room.id, position: {x: 0.5, y: 0.5}}], scale: roomScale - 0.5, title: `${room.title}`});
                standardPages.push(..._.map(elevationPagesFor(elevationChunk), (page, index) => ({...page})));
              }
              else if (autogenerateStrategy === 'excludeOverviews') {
                //HINT: only add the blown up room and elevation views
                standardPages.push({layout: [{roomId: room.id, position: {x: 0.5, y: 0.5}}], scale: roomScale - 0.5, title: `${room.title}`});
                standardPages.push(..._.map(elevationPagesFor(elevationChunk), (page, index) => ({...page})));
              }
              else if (autogenerateStrategy === 'overviewsOnly') {
                // HINT: not adding the blown up room and elevation views
              }
            });
          }
          else {
            if (autogenerateStrategy === 'standard') standardPages.push({layout: [{roomId: room.id, position: {x: 0.5, y: 0.5}}], scale: scaleValue, title: `overview - ${room.title}`});
          }
        });
      }
    });

    if (override || !_.isEqual(standardPages, project.drawingsData.standardPages)) {
      standardPages = _.map(standardPages, page => ({id: lib.string.uuid(), ...page}));

      await Drawings.handleUpdateDrawingsData({project, standardPages, autogenerateStrategy, continuousDrawingsAutogenerationDisabled, resourceActionDispatchers});

      return true;
    }

    return false;
  },

  getPositionInPage({position, size, containerDimensions}) {
    return ({
      top: (position.y - (size.height / 2 / containerDimensions.height)) * containerDimensions.height,
      left: (position.x - (size.width / 2 / containerDimensions.width)) * containerDimensions.width
    });
  },

  getPositionInRelative({top, left, size, containerDimensions}) {
    return {
      x: (left / containerDimensions.width) + (size.width / 2 / containerDimensions.width),
      y: (top / containerDimensions.height) + (size.height / 2 / containerDimensions.height)
    };
  },

  getDocumentTitle({project}) {
    const dateString = lib.date.moment().format('YYYYMMDD');

    if (project.companyKey === 'vp') {
      return `${project.id}_ST for ${project.title || project.clientName} - Drawings ${dateString}`;
    }

    const projectTitle = project.title || project.clientName || `Order #${project.id}`;

    return `${project.id}_${projectTitle} - ${project.versionTitle} - Drawings ${dateString}`;
  },

  getScale({project, containerDimensions}) {
    const maxSizes = Drawings.getMaxSizes({project});
    const {elevation, room} = maxSizes;
    const maxSize = lib.object.max(elevation, room);

    const scale = {
      width: (containerDimensions.width / 2) / (maxSize.width + 100),
      height: (containerDimensions.height / 2) / (maxSize.height + 87),
    };

    const scaleValue = parseFloat(_.min([scale.width, scale.height]).toFixed(2));

    return scaleValue;
  },

  async handleUpdateDrawingsData({project, standardPages, autogenerateStrategy, continuousDrawingsAutogenerationDisabled, designerContact, resourceActionDispatchers, notes}) {
    const updatedDrawingsData = _.cloneDeep(project.drawingsData);

    if (standardPages) {
      _.set(updatedDrawingsData, 'standardPages', standardPages);
    }

    if (continuousDrawingsAutogenerationDisabled !== undefined) {
      _.set(updatedDrawingsData, 'continuousDrawingsAutogenerationDisabled', continuousDrawingsAutogenerationDisabled);
    }

    if (designerContact === '') {
      _.unset(updatedDrawingsData, 'designerContact');
    }
    else if (designerContact) {
      _.set(updatedDrawingsData, 'designerContact', designerContact);
    }

    if (notes) {
      _.set(updatedDrawingsData, 'notes', notes);
    }
    if (autogenerateStrategy) {
      _.set(updatedDrawingsData, 'autogenerateStrategy', autogenerateStrategy)
    }

    // HINT update projectVersion
    lib.api.update('projectVersion', {where: {id: project.versionId}, props: {drawingsData: updatedDrawingsData}});

    // HINT update project.drawingsData in redux without hitting the API
    resourceActionDispatchers.updateProject({id: project.id, props: {drawingsData: updatedDrawingsData}, hitApi: false});
  },

  async exportToDxf (svgs) {
    // var makerjs = require('makerjs');
    // var dxfs = [];
    function getSvgData({index, scale, svg}) {

      var svgLines = _.split(svg, '\n');

      var paths = [], circles = [];
      var offset = [3000 * index * scale, 0];

      var getAttributes = (element, keys) => {
        return lib.object.fromKeys(keys, key => {
          var attribute = element.attributes.getNamedItem(key);

          if (!attribute) {
            if (key === 'transform') attribute = {value: 'translate(0,0)'}; //WARNING intentionally skipping space as a workaround for replace below
            if (key === 'd') attribute = {value: ''}; //WARNING intentionally skipping space as a workaround for replace below
          }
          if (!attribute) console.log(key);

          return attribute.value;
        });
      };

      var elementForLine = (line) => {
        var div = document.createElement('div');

        div.innerHTML = line.trim();

        return div.firstChild;
      };

      var translateForTransform = (transform) => {
        var translate = transform.replace('translate(', '').replace(')', '').replace('translate(0, 0)', '').replace(/rotate\((\d+(?:\.\d+)?)\)/, '');

        translate = _.split(_.trim(translate), ',').map(value => parseFloat(value));

        return translate;
      };

      var rotateForTransform = (transform) => {
        var match = transform.match(/rotate\(-?\d+(?:.\d+)?\)/);

        if (match) {
          var rotate = parseFloat(match[0].replace('rotate(', '').replace(')', ''));

          return rotate;
        }
      };

      svgLines = _.filter(svgLines, line => !(_.includes(line, 'visibility: hidden;') || (_.includes(line, 'opacity: 0;') && !_.includes(line, 'opacity: 1;')))); //HINT svgs sometimes say opacity 0 then opacity 1

      _.forEach(svgLines, line => {
        var element = elementForLine(line);
        var points, type;
        var isDashed = _.includes(line, 'stroke-dasharray: 3 6') || _.includes(line, 'stroke-dasharray: 5 5');

        if (_.includes(line, '<polyline') || _.includes(line, '<circle') || _.includes(line, '<rect') || _.includes(line, '<line') || _.includes(line, '<path')) {
          if (_.includes(line, '<line')) {
            var {x1, y1, x2, y2, transform} = getAttributes(element, ['x1', 'y1', 'x2', 'y2', 'transform']);

            points = [[x1, y1], [x2, y2]];
            type = 'line';
          }
          else if (_.includes(line, '<polyline')) {
            var {points, transform} = getAttributes(element, ['points', 'transform']);

            points = _.map(_.split(points.trim(), ' '), pair => _.split(pair, ','));
            type = 'polyline';
          }
          else if (_.includes(line, '<rect')) {
            var {x, y, width, height, transform} = _.mapValues(getAttributes(element, ['x', 'y', 'width', 'height', 'transform']), (value, key) => {
              return key === 'transform' ? value : parseFloat(value);
            });

            points = [[x, y], [x + width, y], [x + width, y + height], [x, y + height], [x, y]];
            type = 'rect';
          }
          else if (_.includes(line, '<path')) {
            var {d, transform} = getAttributes(element, ['d', 'transform']);

            if (!_.includes(d, 'NaN') && d) {
              //HINT some paths contain 1 arc, but aren't circles (IE door actions)
              var isCircle = _.split(d, 'A').length === 2 && _.split(d, 'M').length === 2 && _.split(d, 'L').length < 2;

              if (isCircle) {
                try {
                  var splitPath = _.split(d, ' ');

                  var cx = splitPath[2];
                  var cy = splitPath[3];
                  var r = splitPath[5];
                  var translate = translateForTransform(transform);
                  var edgeCount = 16;
                  var theta = Math.PI * 2 / edgeCount;

                  cx = parseFloat(cx);
                  cy = parseFloat(cy);
                  r = parseFloat(r);


                  points = _.times(edgeCount, i => [cx + r * Math.cos(i * theta) - r, cy + r * Math.sin(i * theta)]);
                  points.push(points[0]);
                }
                catch(e) {
                  console.log(e);
                }
              }
              else {
                var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');

                // if (d) {
                //   try {
                //     // if (!model)
                //     // else {
                //     //   model.addModel(model, makerjs.importer.fromSVGPathData(d), lib.string.uuid());
                //     // }
                //     console.log(model, d);
                //     var model = makerjs.importer.fromSVGPathData(d);
                //     dxfs.push(makerjs.exporter.toDXF(model));
                //   }
                //   catch(e) {
                //     console.log(e)
                //   }
                // }

                d = d.replace('Z Z', 'Z');

                path.setAttribute('d', d.trim());

                var pathData = _.map(path.getPathData(), data => {
                  if (data.type === 'A') {
                    return {
                      ...data,
                      values: _.takeRight(data.values, 2)
                    };
                  }
                  else {
                    return data;
                  }
                });
                var points = _.map(pathData, 'values');

                if (_.includes(d, 'Z') && isNaN(_.last(points)[0])) {
                  points[points.length - 1] = points[0];
                }
                type = 'path';

              }
            }
          }
          else if (_.includes(line, '<circle')) {
            var element = elementForLine(line);
            var {cx, cy, r, transform} = getAttributes(element, ['cx', 'cy', 'r', 'transform']);
            var translate = translateForTransform(transform);
            var edgeCount = 16;
            var theta = Math.PI * 2 / edgeCount;

            cx = parseFloat(cx);
            cy = parseFloat(cy);
            r = parseFloat(r);

            points = _.times(edgeCount, i => [cx + r * Math.cos(i * theta), cy + r * Math.sin(i * theta)]);
            points.push(points[0]);
          }

          if (points) {
            var translate = translateForTransform(transform);
            var rotate = rotateForTransform(transform);

            var everyPointZero = _.every(_.flatten(points), p => parseFloat(p) === 0);

            points = _.map(points, point => {
              return [(parseFloat(point[0]) + translate[0]) * scale + offset[0], (parseFloat(point[1]) + translate[1]) * scale + offset[1]];
            });

            if (rotate) {
              var toXY = (point) => ({x: point[0], y: point[1]});

              var center = type === 'line' ? lib.math.midpoint({p1: toXY(points[0]), p2: toXY(points[1])}) : lib.math.polygon.center({points: _.map(points, toXY)});

              points = _.map(points, point => _.values(lib.trig.rotate({point: toXY(point), byDegrees: rotate, aroundOrigin: center})));
            }

            var hasNonNumber = _.some(_.flatten(points), p => isNaN(p));

            if (hasNonNumber || everyPointZero) {
              console.log(JSON.stringify(points), line);
            }
            else if (points.length) {
              paths.push({points, line, type, isDashed});
            }
          }
          else {
            console.log('invalid points', line);
          }
        }
      });

      return {paths, circles};
    }

    function downloadSvgs() {
      var scale = 1 / 4;
      var paths = [], circles = [], index = 0;

      _.forEach(svgs, (svg) => {
        var data = getSvgData({index, scale, svg});

        paths.push(...data.paths);
        circles.push(...data.circles);

        index += 1;
      });

      var allPoints = [..._.flatMap(paths, 'points'), ..._.map(circles, ({cx, cy}) => [cx, cy])];
      var largeValue = 10000000;
      var padding = 100;

      var round = number => number; //lib.number.round(number, {toNearest: 1/64});

      var minPoint = _.reduce(allPoints, (minPoint, point) => {
        return [round(Math.min(point[0], minPoint[0])), round(Math.min(point[1], minPoint[1]))];
      }, [largeValue, largeValue]);

      var maxPoint = _.reduce(allPoints, (maxPoint, point) => {
        return [round(Math.max(point[0], maxPoint[0])), round(Math.max(point[1], maxPoint[1]))];
      }, [-largeValue, -largeValue]);

      var offset = [-minPoint[0] + padding, -minPoint[1] + padding];

      var maxSize = Math.max(maxPoint[0] - minPoint[0], maxPoint[1] - minPoint[1]);
      var size = {width: maxSize, height: maxSize};

      //https://github.com/jscad/openscad-openjscad-translator/blob/master/tests/examples/example009.dxf

      //WARNING careful - it's crucial the indentation of these dxf lines doesn't change as it's syntactically important for DXFs
      var dxf = `  0
SECTION
  2
HEADER
  9
$ACADVER
  1
AC1009
  9
$FILLMODE
 70
 0
  9
$SPLFRAME
 70
 1
  9
$EXTMIN
 10
0
 20
0
 30
0.0
  9
$EXTMAX
 10
${size.width}
 20
${size.height}
 30
0.0
  0
ENDSEC
  0
SECTION
  2
TABLES
  0
TABLE
  2
LAYER
 70
1
  0
LAYER
  2
0
 70
     0
 62
     7
  6
CONTINUOUS
  0
ENDTAB
  0
ENDSEC
  0
SECTION
  2
ENTITIES`;

      _.forEach(paths, path => {
        var points = _.map(path.points, point => [round(point[0] + offset[0]), round(-1 * (point[1] + offset[1]) + size.height)]);

        if (points[0]) {
          dxf += `
  0
POLYLINE
  8
0
 62
     0
 66
     1
 10
0
 20
0
 30
0.0
 70
     1
 40
0
 41
0`;

          _.forEach(points, point => {
            dxf += `
  0
VERTEX
  8
0
 10
${point[0]}
 20
${point[1]}
 30
0.0
 40
0
 41
0`;

          });

          dxf += `
  0
SEQEND
 8
0`;
        }
        else {
          console.log('malformed path', path);
        }
      });

      dxf += `
  0
ENDSEC
  0
EOF
`;

      var filename = 'export.dxf';
      var href = 'data:image/x-dxf;charset=utf-8,' + encodeURIComponent(dxf);
      var element = document.createElement('a');

      element.href = href;
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }

    downloadSvgs();
  }
};

export default Drawings;
