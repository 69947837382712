import React from 'react';
import _ from 'lodash';
import lib from 'lib';
import K from 'k';

import CanvasView from 'canvas/canvas-view';
import CanvasRoom from 'project-components/canvas-room';
import CanvasRooms3D from 'project-components/canvas-rooms-3d';

export default class ContextHudElement extends React.PureComponent {
  render() {
    const {projectData, activeViewContextEntity, visibleElevations, hasActiveEntities, showWallsAndArchElements, selectionData, viewMode, room, activeDetailLevel, activeFillMode} = this.props;


    const points = activeViewContextEntity.plan.points;
    const xs = _.map(points, 'x'), ys = _.map(points, 'y');
    const minX = _.min(xs), minY = _.min(ys);
    const maxX = _.max(xs), maxY = _.max(ys);
    const size = {width: maxX - minX, height: maxY - minY};
    const scale = Math.min(0.5, 90 / size.width, 90 / size.height);
    const style = {position: 'absolute', top: 10, right: K.spacing * 2 + (hasActiveEntities ? K.paneWidth : 0), zIndex: 2};
    const containerSize = {width: 100, height: 100};

    const viewOffset = lib.object.difference({x: -minX - size.width * 0.5, y: -minY - size.height * 0.5});

    return (
      <div style={{zIndex: 2}}>
        <div style={{...style, width: hasActiveEntities ? 150 : 200, height: 150}}>
          <CanvasRooms3D
            isArchetype={false}
            isContextElement={true}
            {...{showWallsAndArchElements, viewOffset, room, activeDetailLevel, activeFillMode}}
          />
        </div>
      </div>
    );
  }
}
