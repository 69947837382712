import React, { Fragment } from 'react';

import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import BKey from 'helpers/b-key';
import Color from 'color';

import { calcScriptValue, numericPropKeys } from './canvas-script-object';
import { CanvasLine, CanvasRect, CanvasCircle, CanvasText, CanvasPath, EditableCanvasPolyline } from 'canvas';

const CanvasScriptNode = (props) => {
  if (!props.nodeProps) return null;

  const {scale} = props.canvasData;
  const {rotation = 0} = props.objectProps;
  const isShape = props.type !== 'group';
  const canvasObjectIsCentered = !(props.type === 'shape' && props.nodeProps.shape === 'rect');
  const layoutPropKeys = ['width', 'height', 'top', 'left', 'right', 'bottom', 'x1', 'x2', 'y1', 'y2', 'radius'];

  let {parentOffset, parentSize, objectScaleX, objectScaleY} = props;
  let nodeProps = {rotation};

  if (props.nodeProps.rotation) nodeProps.rotation += props.nodeProps.rotation;

  let appearanceProps = {..._.pick(props.objectProps, ['fill'])};
  var objectProps = props.objectProps;

  const defaultPropsFor = ({props}) => {
    let defaultProps = {
      isEnabled: false,
      top: 0,
      left: 0,
    };

    const isShape = props.type !== 'group';

    if (isShape && props.nodeProps.shape === 'text') {
      defaultProps.fill = 'black';
    }
    else {
      defaultProps.stroke = _.get(props, 'objectProps.stroke', objectProps.shouldInvertStroke ? 'rgba(255, 255, 255, 0.5)' : 'black');
      defaultProps.strokeWidth = _.get(props, 'objectProps.strokeWidth', 1);
    }

    if (isShape && _.get(props, 'nodeProps.shape') === 'line') {
      defaultProps = _.defaults(defaultProps, {x1: 0, x2: 0, y1: 0, y2: 0, top: 0, left: 0});
    }
    else if (isShape && _.get(props, 'nodeProps.shape') === 'text') {
      defaultProps = _.defaults(defaultProps, {top: 0, left: 0});
    }
    else if (isShape && _.get(props, 'nodeProps.shape') === 'polyline') {
      defaultProps = _.defaults(defaultProps, {points: []});
    }
    else {
      defaultProps = _.defaults(defaultProps, {width: '100%', height: '100%', top: 0, left: 0});
    }

    return defaultProps;
  };

  const calc = ({value, sizeKey, scale}) => {
    return calcScriptValue(value, {size: props.parentSize[sizeKey], scale, parentSize: props.parentSize});
  };

  const applyStackTransforms = ({props}) => {
    var stackProps = _.cloneDeep(props);

    if (_.get(props, 'nodeProps.groupType') === 'stack') {
      stackProps.nodeProps = _.defaults(stackProps.nodeProps || {}, {...defaultPropsFor({props: stackProps}), axis: 'y', spacing: 0});

      var stackAxis = new BKey(stackProps.nodeProps.axis);
      var stackSize = 0;
      var sizeKey = stackAxis.toSize();
      var sideKey = stackAxis.toSide();

      stackProps.children = _.map(_.filter(stackProps.children, child => child && !!child.nodeProps), child => {
        child.nodeProps = _.defaults(child.nodeProps, defaultPropsFor({props: child}));

        child = applyStackTransforms({props: child});

        child.nodeProps[sideKey] = stackSize;

        var childSize = calc({value: child.nodeProps[sizeKey], sizeKey, scale});
        var spacing = calc({value: stackProps.nodeProps.spacing, sizeKey, scale});

        stackSize += childSize + spacing;

        return child;
      });

      stackProps.nodeProps[sizeKey] = stackSize;
    }

    return stackProps;
  };

  if (isShape) {
    const appearancePropKeys = [
      'stroke', 'strokeWidth', 'fontWeight', 'fill', 'hatchFill',
      'hatchFills', 'dashed', 'opacity', 'strokeDashArray','points',
      'closed', 'text', 'fontSize', 'textAlign', 'origin',
      'borderColor', 'hasBorder', 'fontFamily', 'backgroundColor', 'backgroundStroke', 'backgroundStrokeWidth', 'padding', 'preventPaddingScale',
      'fillPriority', 'fillLinearGradientStartPoint', 'fillLinearGradientEndPoint', 'fillLinearGradientColorStops', 'showToArrow', 
    ];

    if (!props.nodeProps.isFillable && !props.nodeProps.fill) _.pull(appearancePropKeys, 'fill');
    if (!props.nodeProps.isHatchFillable) _.pull(appearancePropKeys, ['hatchFill', 'hatchFills']);

    appearanceProps = {
      ..._.pick(props.objectProps, appearancePropKeys),
      ..._.pick(props.nodeProps, appearancePropKeys)
    };

    //HINT the stroke width from object props is already scaled.
    if (props.nodeProps.shouldScaleStrokeWidth && props.nodeProps.strokeWidth) appearanceProps.strokeWidth = props.nodeProps.strokeWidth * scale;

    var shouldInvertStroke = !props.nodeProps.preventInvertStroke && (props.nodeProps.shouldInvertStroke || props.objectProps.shouldInvertStroke);

    if (isShape && props.nodeProps.shape === 'text') {
      appearanceProps.stroke = props.nodeProps.stroke;

      if (shouldInvertStroke && !appearanceProps.backgroundColor) {
        appearanceProps.backgroundColor = 'rgba(255, 255, 255, 0.5)';
      }
    }
    else if (shouldInvertStroke && appearanceProps.stroke === 'black') appearanceProps.stroke = 'rgba(255, 255, 255, 0.5)';

    var {isHatchFillable, hatchKey} = props.nodeProps;

    var isSubproductHatch = _.split(hatchKey, '-').length > 1;
    //HINT don't hatch subproduct hatches when undefined, prevents overlapping hatches
    var hatchFill = _.get(appearanceProps, `hatchFills.${hatchKey}`, isSubproductHatch ? '' : _.get(appearanceProps, 'hatchFill'));

    //TODO handle different glass materials
    if (hatchKey === 'glass' || (isSubproductHatch && _.split(hatchKey, '-')[2] === 'glass')) {
      //HINT svg can't export opacity, so applying it to the fill directly
      // appearanceProps.opacity = _.get(appearanceProps, 'hatchFills.glassOpacity', 0.5);

      hatchFill = Color(hatchFill).alpha(_.get(appearanceProps, 'hatchFills.glassOpacity', 0.5)).hexa();

      //TODO test/fix framed fronts
      // hatchFill = 'white';//_.get(appearanceProps, `hatchFills.front`, _.get(appearanceProps, 'hatchFill'));
    }

    if (isHatchFillable && hatchFill) {
      var cachedFill = hatchFill;

      //HINT svgcanvas needs a reference to the original image
      // because the pattern needs to be drawn on the same context
      // as the SVG canvas and Konva uses a "dummy" canvas by default.
      // We're only doing this because CanvasPattern is an opaque
      // object (can't get the original image from it).
      // https://developer.mozilla.org/en-US/docs/Web/API/CanvasPattern
      nodeProps.innerRef = (ref) => {
        if (ref && ref._cache.get('patternImage')) {
          ref._cache.get('patternImage').image = cachedFill;
        }
      };

      appearanceProps.fill = hatchFill;
    }

    if (typeof(appearanceProps.fill) === 'object') {
      var cachedFill = appearanceProps.fill;

      //HINT see previous comment for hatchFill
      nodeProps.innerRef = (ref) => {
        if (ref && ref._cache.get('patternImage')) {
          ref._cache.get('patternImage').image = cachedFill;
        }
      };

      appearanceProps.fillPatternImage = appearanceProps.fill;

      delete appearanceProps.fill;
    }

    if (appearanceProps.strokeDashArray) appearanceProps.dash = appearanceProps.strokeDashArray;
    if (appearanceProps.dashed) appearanceProps.dashEnabled = appearanceProps.dashed;

    if (props.objectProps.isInvalid && !props.renderForDrawings) appearanceProps.stroke = K.colors.invalid;
  }

  nodeProps = {...nodeProps, ...defaultPropsFor({props}), ...appearanceProps, ..._.pick(props.nodeProps, layoutPropKeys)};

  if (nodeProps.dashed) {
    if (appearanceProps.strokeDashArray) {
      nodeProps.dash = appearanceProps.strokeDashArray
    }
    else {
      const strokeDashLength = 5 * (nodeProps.strokeWidth * 2);

      nodeProps.dash = [strokeDashLength, strokeDashLength];
    }

    nodeProps.opacity = nodeProps.opacity || 1;
  }

  //Parse dimensional calculations
  _.forEach(nodeProps, (value, propKey) => {
    _.forEach([new BKey('x'), new BKey('y')], axisKey => {
      if (_.includes(numericPropKeys[axisKey], propKey)) {
        nodeProps[propKey] = calc({value, sizeKey: axisKey.toSize(), scale});
      }
    });
  });

  if (isShape && props.nodeProps.shape === 'path') {
    nodeProps.points = lib.object.clone(props.nodeProps.points);

    _.forEach(nodeProps.points, point => {
      _.forEach(['x', 'y'], axis => {
        const sizeKey = axis === 'x' ? 'width' : 'height';

        point[axis] = calc({value: point[axis], sizeKey, scale});
      });
    });
  }

  if (props.type === 'group') {
    parentOffset = lib.object.sum(parentOffset, {top: nodeProps.top, left: nodeProps.left});

    parentSize = {width: nodeProps.width, height: nodeProps.height};
  }

  if (isShape) {
    const shape = props.nodeProps.shape;

    nodeProps.top += parentOffset.top;
    nodeProps.left += parentOffset.left;

    let origin = {x: 0, y: 0};
    let position;

    //correct for rect being positioned using top-left
    //as origin in script and center-center in FabricCanvasObject
    if (shape === 'rect' && canvasObjectIsCentered) {
      nodeProps.left += nodeProps.width / 2;
      nodeProps.top += nodeProps.height / 2;
    }

    //Rotate positions
    origin = props.objectProps.position || {x: 0, y: 0};

    // if (shape === 'line') {
    //   let p1 = {x: nodeProps.left + nodeProps.x1, y: nodeProps.top + nodeProps.y1}, p2 = {x: nodeProps.left + nodeProps.x2, y: nodeProps.top + nodeProps.y2};

    //   const midpoint = lib.math.midpoint({p1, p2});

    //   position = lib.math.trig.rotate({point: midpoint, byDegrees: 0, aroundOrigin: origin});

    //   p1 = lib.object.difference(p1, midpoint);
    //   p2 = lib.object.difference(p2, midpoint);

    //   _.extend(nodeProps, {x: position.x, y: position.y, x1: p1.x, y1: p1.y, x2: p2.x, y2: p2.y});
    // }
    // else {
    position = lib.math.trig.rotate({point: {x: nodeProps.left, y: nodeProps.top}, byDegrees: 0, aroundOrigin: origin});

    _.extend(nodeProps, {x: position.x, y: position.y});
    // }
  }
  else if (props.nodeProps && props.nodeProps.groupType === 'stack') {
    props = applyStackTransforms({props});
  }

  _.forEach(['width', 'height', 'x1', 'x2', 'y1', 'y2', 'radius', 'fontSize', 'x', 'y', 'fillLinearGradientStartPoint', 'fillLinearGradientEndPoint'], scaledPropKey => {
    if (nodeProps[scaledPropKey] !== undefined) {
      if (_.includes(['fillLinearGradientStartPoint', 'fillLinearGradientEndPoint'], scaledPropKey)) {
        nodeProps[scaledPropKey] = lib.object.multiply(nodeProps[scaledPropKey], scale);
      }
      else {
        nodeProps[scaledPropKey] = nodeProps[scaledPropKey] * scale;
      }
    }
  });

  var {shape} = props.nodeProps;

  if (!props.renderForDrawings) {
    if (shape === 'text' && nodeProps.fontSize < 4) return null;
    if (shape === 'circle' && nodeProps.radius < 0.5) return null;
    if (shape === 'rect' && nodeProps.width < 0.5 || nodeProps.height < 0.5) return null;
  }

  if (shape === 'text' && props.hideText) return null;

  if (shape === 'text' && objectScaleX && objectScaleX !== 1) nodeProps.scaleX = 1 / objectScaleX;
  if (shape === 'text' && objectScaleY && objectScaleY !== 1) nodeProps.scaleY = 1 / objectScaleY;


  let nodeComponent;

  if (nodeProps.opacity && nodeProps.opacity === true) nodeProps.opacity = 1;

  if (props.nodeProps.shape === 'rect') {
    nodeComponent = <CanvasRect listening={props.listening} onClick={props.onClick} {...{...nodeProps}}/>;
  }
  else if (props.nodeProps.shape === 'line') {
    nodeComponent = <CanvasLine listening={props.listening} onClick={props.onClick} {...{...nodeProps}}/>;
  }
  else if (props.nodeProps.shape === 'circle') {
    nodeComponent = <CanvasCircle listening={props.listening} onClick={props.onClick} {...{...nodeProps}}/>;
  }
  else if (props.nodeProps.shape === 'text') {
    nodeComponent = <CanvasText listening={props.listening} onClick={props.onClick} {...{...nodeProps}}/>;
  }
  else if (props.nodeProps.shape === 'polyline') {
    nodeComponent = <EditableCanvasPolyline listening={props.listening} onClick={props.onClick} {...{...nodeProps}}/>;
  }
  else if (props.nodeProps.shape === 'path') {
    nodeComponent = <CanvasPath listening={props.listening} onClick={props.onClick} {...{...nodeProps}}/>;
  }
  else if (!isShape) {
    nodeComponent = _.map(_.filter(props.children, child => child && !!child.type), (child, index) => {
      var opacity = _.isNumber(props.nodeProps.opacity) ? props.nodeProps.opacity : props.objectProps.opacity;

      if (opacity === true) opacity = 1;

      return (
        <CanvasScriptNode
          key={`group-child-${index}`}
          listening={props.listening}
          onClick={props.onClick}
          {...{parentOffset, parentSize, objectScaleX, objectScaleY}}
          {...child}
          {..._.pick(props, ['isDisabled', 'renderForDrawings',  'canvasData', 'hideText'])}
          {...{objectProps: {
            ...props.objectProps,
            shouldInvertStroke: (props.nodeProps.shouldInvertStroke !== undefined) ? props.nodeProps.shouldInvertStroke : props.objectProps.shouldInvertStroke,
            opacity
          }}}
        />
      );
    });
  }

  return <Fragment key={props.id}>{nodeComponent}</Fragment>;
};

export default React.memo(CanvasScriptNode, function (prevProps, nextProps) {
  var changedKeys = [];

  for (const key in nextProps) {
    var prevProp = prevProps[key];
    var nextProp = nextProps[key];

    if (_.includes(['objectProps', 'parentOffset', 'parentSize', 'nodeProps'], key)) {
      if (!_.isEqual(prevProp, nextProp)) {
        changedKeys.push(key);
      }
    }
    else {
      if (prevProp !== nextProp) {
        changedKeys.push(key);
      }
    }
  }

  var componentShouldUpdate = false;

  if (changedKeys.length === 1 && changedKeys[0] === 'children' && nextProps.isTopLevel) {
    componentShouldUpdate = !_.isEqual(prevProps.children, nextProps.children);
  }
  else {
    componentShouldUpdate = changedKeys.length > 0;
  }

  //HINT React.memo returning false means to update
  return !componentShouldUpdate;
});
