import React, {Fragment} from 'react';

import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import Cube3D from './cube-3d';
import * as THREE from 'three';

class CanvasScriptNode3D extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    var {objectProps, nodeProps} = this.props;
    var {opacity, receiveShadow, fill, dimensions, position} = nodeProps;

    var rotation = nodeProps.rotation;
    var type = _.get(nodeProps, 'type') || objectProps.type;

    //HINT y will represent z in 3d space
    var offset = {x: dimensions.width / 2, y: dimensions.depth / 2};

    offset = lib.trig.rotate({point: offset, byRadians: -rotation});

return (
      <Cube3D
        //HINT y is never rotated, so we can safely offset by half of the height
        position={lib.object.sum(nodeProps.position, {x: offset.x, y: dimensions.height / 2, z: offset.y})}
        {...{dimensions, rotation, fill, opacity}}
        receiveShadow={false}
        key={`${type}-${objectProps.key}`}
      />
    )
  }
}

class CanvasScript3D extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    var {objectProps} = this.props;
    var {dimensions, rotation, position, type} = objectProps;

    return (<>
      <group
        position={[position.x, position.y, position.z]}
        rotation={[0, rotation, 0]}
      >
        {this.props.nodes && this.props.nodes.length > 0 && (<>
          {_.map(this.props.nodes, ({nodeProps}, i) => {
            return (
                <CanvasScriptNode3D
                  key={i}
                  {...{nodeProps, objectProps}}
                  // getObjectOrigin={this.getObjectOrigin}
                />
            )
          })}
        </>)}
      </group>
    </>)
  }
}

export default CanvasScript3D;
