function Thumbnail(props) {
  var {src, ...restProps} = props;

  var hideBrokenImage = ({event}) => {
    //HINT hide images that didn't load
    event.target.style.display = 'none';
  };

  return (
    <img src={src} onError={() => hideBrokenImage({event})} {...restProps}/>
  );
}

export default Thumbnail;