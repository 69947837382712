import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import ArchElement from 'project-helpers/arch-element';
import Elevation from 'project-helpers/elevation';
import addLineToNearestSide from 'dimensions/addLineToNearestSide';

export default function addElevationArchElementsDimensions({allPoints, getIsTrapped, elevation, dimensionSets, outsideDimensionSets, archElements, room}) {
  var isSection = Elevation.getIsSection({elevation});
  if (!isSection || (isSection && !_.get(room, 'customData.preventVariousSectionDimensions'))) {
    _.forEach(archElements, archElement => {
      var {type} = archElement;
      var wall = ArchElement.get('wall', {archElement});
      let vertices = ArchElement.getWallprintInElevation({archElement, elevation});
      vertices = _.map(vertices, vertex => ({...vertex, y: -vertex.y}));

      _.forEach(vertices, (from, v) => {
        if (type === 'polygon' || _.includes([0, 1], v)) {
          var to = lib.array.next(vertices, v);

          // lineProps.onChange = ({numericInput: value, matchesAbsoluteLine}) => {
          //   //width/height
          //   if (_.includes(['opening', 'window', 'door', 'soffit', 'pillar', 'outlet', 'rectangularWallArchitecturalElement', 'rectangularFloorArchitecturalElement'], type)) {
          //     if (matchesAbsoluteLine({from: from.absolute, to: to.absolute})) {
          //       var sizeKey = (_.includes([0, 2], v)) ? 'width' : 'height';

          //       obstacle.staticallyChangeSize({sizeKey, value});
          //     }
          //   }
          // };

          var idPrefix = `${archElement.id}-${v === 0 ? 'height' : 'width'}`;

          var candidateLines = [{from, to}, {from: vertices[(v + 2) % vertices.length], to: vertices[(v + 3) % vertices.length]}];

          candidateLines = _.filter(candidateLines, candidateLine => {
            return !getIsTrapped({line: candidateLine, entityType: 'archElement', entityId: archElement.id});
          });

          //TODO question, do we prefer to show outlet centerline from wall dim or outlet width dim
          //maybe should always make outlet width standalone
          if (candidateLines.length) {
            addLineToNearestSide({
              allPoints,
              outsideDimensionSets,
              summarize: true, //summarize countertop and top of tall unit dims
              candidateLines,
              idPrefix: `elevation-arch-element-${archElement.id}-${v === 0 ? 'height' : 'width'}`
            });
          }
          else {
            dimensionSets.push({
              alpha: lib.trig.alpha({p2: from, p1: to}),
              offset: type === 'outlet' && v === 0 ? 0 : 5,
              type: 'standalone',
              id: `${idPrefix}-standalone`,
              showLabelBorder: true,
              targets: [
                {position: from, id: `${idPrefix}-from`},
                {position: to, id: `${idPrefix}-to`}
              ]
            });
          }
        }
      });

      //height off ground
      // if (this.props.project.editorStepKey === 'doors-windows') {
      var {y, x} = archElement.position;

      if (y && type !== 'soffit') {
        var from = vertices[0];
        var to = {...from, y: 0};

        if (type === 'outlet') {
          from = {
            x: (from.x + archElement.customData.width / 2), y: (from.y - archElement.customData.height / 2)
          };

          to = {...from, y: 0};
        }

        var lineProps = {
          // onChange: ({numericInput: value}) => {
          //   obstacle.staticallyChangeResourceProp({key: 'position.y', value});
          // }
        };

        var idPrefix = `${archElement.id}-height-off-ground`;

        var candidateLines = [{from, to}, {from: to, to: from}];

        candidateLines = _.filter(candidateLines, candidateLine => {
          return !getIsTrapped({line: candidateLine, entityType: 'archElement', entityId: archElement.id});
        });

        if (type !== 'outlet' && candidateLines.length) {
          addLineToNearestSide({
            allPoints,
            outsideDimensionSets,
            summarize: true, //summarize countertop and top of tall unit dims
            candidateLines: [{from, to}],
            idPrefix
          });
        }
        else {
          dimensionSets.push({
            alpha: lib.trig.alpha({p1: from, p2: to}),
            offset: 0,
            type: 'standalone',
            id: `${idPrefix}-standalone`,
            showLabelBorder: true,
            targets: [
              {position: from, id: `${idPrefix}-from`},
              {position: to, id: `${idPrefix}-to`}
            ]
          });
        }
      }

      if (x && type !== 'soffit') {
        var to = vertices[0];
        to = {...to};
        //HINT x 0 position needs to be relative to elevation origin
        //TODO dimension to nearest wall
        var from = {...to, x: Elevation.getWallX({elevation, wall})};

        var archElementHeight = _.get(archElement, 'customData.dimensions.height') || archElement.customData.height || archElement.customData.openingHeight;

        if (type === 'outlet') {
          from = {y: to.y - archElementHeight / 2, x: Elevation.getWallX({elevation, wall})};

          to = {y: to.y - archElementHeight / 2, x: (to.x + archElement.customData.width / 2)};
        }
        else {
          from = {...from, y: from.y - archElementHeight / 3};
          to = {...to, y: to.y - archElementHeight / 3};
        }

        var lineProps = {
          from, to, isStandalone: true,
          // onChange: ({numericInput: value}) => {
          //   obstacle.staticallyChangeResourceProp({key: 'position.x', value});
          // }
        };

        var idPrefix = `${archElement.id}-to-nearest-wall`;

        var candidateLines = [{from, to}, {from: to, to: from}];

        candidateLines = _.filter(candidateLines, candidateLine => {
          return !getIsTrapped({line: candidateLine, entityType: 'archElement', entityId: archElement.id});
        });

        if (type !== 'outlet' && candidateLines.length) {
          addLineToNearestSide({
            allPoints,
            outsideDimensionSets,
            summarize: true,
            candidateLines,
            idPrefix
          });
        }
        else {
          dimensionSets.push({
            alpha: lib.trig.alpha({p1: from, p2: to}),
            offset: 0,
            type: 'standalone',
            id: `${idPrefix}-standalone`,
            showLabelBorder: true,
            targets: [
              {position: from, id: `${idPrefix}-from`},
              {position: to, id: `${idPrefix}-to`}
            ]
          });
        }

      }
    });
  }
}
