import React, {useContext} from 'react';
import _ from 'lodash';
import { connect, resourceActions } from 'redux/index.js';
import {CanvasDataContext, CanvasSelectionContext, CanvasErrorFallback} from 'canvas';

import ProjectDataContext from 'contexts/project-data-context';

import { Html } from 'react-konva-utils';

import Product from 'project-helpers/product';
import Elevation from 'project-helpers/elevation';
import Container from 'project-helpers/container';
import CanvasProductDetailsRow from './canvas-product-details-row';
import {withErrorBoundary} from 'react-error-boundary';

function CanvasLineItems(props) {
  var {canvasData, position, containersData, projectData, selectionData, ...restProps} = props;

  var handleDeleteProduct = (product) => () => {

    selectionData.onDeselect();

    Product.destroy({product, resourceActions: restProps, pushToUndoQueue: projectData.pushToUndoQueue});
  };

  var handleSelectProduct = (product) => {
    const {setActiveEntities} = selectionData;

    var isManagedProduct = Product.getIsManaged({product}) || (_.get(product, 'managedData.key') === 'autofilledStorage');

    if (isManagedProduct) {
      var {parentProduct, container} = Product.get(['parentProduct', 'container'], {product});

      if (parentProduct) {
        setActiveEntities({entities: [{resourceKey: 'product', id: parentProduct.id}], isMultiSelect: false});
      }
      else if (container) {
        setActiveEntities({entities: [{resourceKey: 'container', id: container.id}], isMultiSelect: false});
      }
    }
    else {
      setActiveEntities({entities: [{resourceKey: 'product', id: product.id}], isMultiSelect: false});
    }
  };

  return (
    <Html divProps={{style: {textTransform: 'uppercase', transformOrigin: 'top left', transform: `translate(${position.x}px, ${position.y}px) scale(${canvasData.scale / 3})`}}}>
      <div style={{zIndex: -0.5, display: 'flex', flexDirection: 'column', maxWidth: 1800}}>
        {
          _.map(containersData, (containerData, index) => {
            return (
              <div key={`container-data-${index}`} style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                {_.map(_.sortBy(containerData.products, [product => product.productionId ? 0 : 1, 'rank']), (product) => {
                  var detailApplications = Product.getDetailApplications({products: [product]});
                  var isManagedProduct = Product.getIsManaged({product}) || (_.get(product, 'managedData.key') === 'autofilledStorage');

                  return (
                    <CanvasProductDetailsRow
                      {...{
                        canvasData,
                        product,
                        detailApplications,
                          isSelected: selectionData && (selectionData.getIsActiveEntity({resourceKey: product.primaryAssociationKey === 'productInstance' ? 'product' : 'container', id: product.primaryAssociationKey === 'productInstance' ? product.productInstanceId : product.containerInstanceId}) || selectionData.getIsActiveEntity({resourceKey: 'product', id: product.id})),
                        ...restProps
                      }}
                      onTitleClick={handleSelectProduct}
                      key={`canvas-product-details-row-line-item-${product.id}-${index}`}
                      onDelete={handleDeleteProduct(product)}
                    />
                  );
                })
                }
              </div>
            );
          })
        }
      </div>
    </Html>
  );
}

function CanvasLineItemsWithContext(props) {
  var canvasData = useContext(CanvasDataContext);
  var projectData = useContext(ProjectDataContext);
  var selectionData = useContext(CanvasSelectionContext);

  return (<CanvasLineItems {...props} {...{canvasData, projectData, selectionData}}/>);
}

export default withErrorBoundary(connect({
  mapState: (state, ownProps) => {
    if (ownProps.elevation) {
      var {elevation} = ownProps;
      var {containers} = Elevation.get(['products', 'containers'], {elevation});
      var containersData = [{products: []}];

      // _.forEach(containers,
      //   _c => {
      //     var _cProducts = Container.get(['products'], {container: _c}).products;
      //     var _cContainerType = Container.get(['containerType'], {container: _c}).containerType;
      //     if (_.size(_cProducts) !== 0) {
      //       containersData[_c.id] = {
      //         container: _c,
      //         containerType: _cContainerType,
      //         products: _cProducts
      //       };
      //     }
      //   }
      // );

      _.forEach(containers,
        _c => {
          var containerShowingUnitNumbers = ((elevation.id ? _.includes([0, 360], lib.round(Container.getElevationTheta({container: _c, elevation}), {toNearest: 1})) : true) || _c.type === 'countertop' || (_c.type === 'ocSolidCorner' && _.includes(['left', 'front'], Container.getSideKey({elevation, container: _c, viewKey: 'front'}))));

          if (containerShowingUnitNumbers) {
            var _cProducts = Container.get(['allProducts'], {container: _c}).allProducts;
            if (_.size(_cProducts) !== 0) {
              containersData[0].products.push(..._cProducts);
            }
          }
        }
      );

      return {containersData};
    }
    else {
      return {};
    }
  },
  mapDispatch: {
    ..._.pick(resourceActions.rooms, ['updateRoom', 'modifyRooms']),
    ..._.pick(resourceActions.archetypes, ['createArchetype', 'destroyArchetype']),
    ..._.pick(resourceActions.containers, ['updateContainer', 'modifyContainers']),
    ..._.pick(resourceActions.archElements, ['updateArchElement']),
    ..._.pick(resourceActions.products, ['updateProduct', 'updateProducts', 'createProducts', 'destroyProducts', 'modifyProducts']),
    ..._.pick(resourceActions.productOptions, ['createProductOptions', 'destroyProductOptions', 'modifyProductOptions']),
    ..._.pick(resourceActions.projectGraphics, ['updateProjectGraphic', 'trackProjectGraphics']),
    ..._.pick(resourceActions.elevations, ['updateElevation', 'modifyElevations']),
    ..._.pick(resourceActions.walls, ['updateWalls']),
    ..._.pick(resourceActions.volumes, ['updateVolume']),
    ..._.pick(resourceActions.parts, ['updateParts']),
  }
})(CanvasLineItemsWithContext), {
  FallbackComponent: CanvasErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'Elevation Line Items'})
});
