import React, { Component } from 'react';

import K from 'k';
import _ from 'lodash';
import lib from 'lib';
import Popup from 'components/popup';
import TextInput from 'components/text-input';

export default class AddProjectTreeNodePopup extends React.PureComponent {
  state = {roomTitle: '', floorTitle: '', scopeTitle: ''};

  render() {
    return (
      <Popup onClose={() => this.props.onClose()} style={{width: (this.props.type === 'floor' ? K.paneWidth * 1.75 : K.paneWidth * 1.25), display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', gap: K.spacing * 3, paddingTop: K.spacing * 4}}>
        <div style={{alignSelf: 'flex-end', display: 'flex', flexDirection: 'row'}}>
          <label style={{marginRight: K.margin}}>{`New ${this.props.type} title:`}</label>
          <TextInput value={this.state[`${this.props.type}Title`]}
            onInput={(value) => {this.setState({[`${this.props.type}Title`]: value})}}
            onSubmit={() => {
              this.props.handleAddProjectTreeNode({[`${this.props.type}Title`]: this.state[`${this.props.type}Title`]});
              this.props.onClose();
            }}
            autoFocus submitOnEnter={this.props.type !== 'floor'}
          />
        </div>
        {this.props.type === 'floor' && (
          <div style={{alignSelf: 'flex-end', display: 'flex', flexDirection: 'row'}}>
            <label style={{marginRight: K.margin}}>Title of a room on this floor:</label>
            <TextInput value={this.state.roomTitle} onInput={(value) => {this.setState({roomTitle: value})}}
              submitOnEnter
              onSubmit={() => {
                var roomTitle = this.state.roomTitle === '' ? 'Untitled Room' : this.state.roomTitle;

                this.props.handleAddProjectTreeNode({roomTitle, floorTitle: this.state.floorTitle})
                this.props.onClose();
              }}
            />
          </div>
        )}
        <div style={{justifyContent: 'flex-end', display: 'flex'}}>
          <button onClick={() => this.props.onClose()} style={{marginRight: 5}}>Cancel</button>
          <button onClick={() => {
            if (this.props.type === 'floor') {
              var roomTitle = this.state.roomTitle === '' ? 'Untitled Room' : this.state.roomTitle;

              this.props.handleAddProjectTreeNode({roomTitle, floorTitle: this.state.floorTitle})
            }
            else {
              this.props.handleAddProjectTreeNode({[`${this.props.type}Title`]: this.state[`${this.props.type}Title`]});
            }

            this.props.onClose();
          }}>Create</button>
        </div>
      </Popup>
    );
  }
}