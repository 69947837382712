import React, { useContext } from 'react';

import K from 'k';
import _ from 'lodash';
import lib from 'lib';
import ProjectDataContext from 'contexts/project-data-context';
import Distance from './canvas-dimension-line-distance';
import Elevation from 'project-helpers/elevation';
import Wall from 'project-helpers/wall';

import { withErrorBoundary } from 'react-error-boundary';
import CanvasErrorFallback from 'canvas/canvas-error-fallback';

import { CanvasDataContext, CanvasSelectionContext, EditableCanvasLine } from 'canvas';
import { connect, resourceActions } from 'redux/index.js';

class CanvasDimensionLine extends React.PureComponent {
  state = {hasShownInitialDimPopup: false};

  get dimensionsData() {
    return this.props.projectData.dimensionsData;
  }

  get activeLayerDimensionsData() {
    return _.defaults(this.props.projectData.dimensionsData[this.props.projectData.activeDimensionsLayer], {
      disabledPositionIds: {},
      disabledLineIds: {},
      offsetsById: {},
      tolerancesById: {},
      shouldHoldTosById: {},
      customDimensionsById: {},
      swapShortDimSideById: {}
    });
  }

  get additiveDimensionsEditing() {
    return this.activeLayerDimensionsData.additiveDimensionsEditing;
  }

  updateDimensionsData = (getDimensionsData) => {
    var {activeLayerDimensionsData, dimensionsData} = this;

    var shouldUpdate = true;

    if (_.includes(['installation', 'production'], this.props.projectData.activeDimensionsLayer)) {
      var isInitialEdit = !activeLayerDimensionsData || _.every(activeLayerDimensionsData, data => {
        return !data || _.isEmpty(data) || _.isNumber(data);
      });

      if (isInitialEdit && !this.state.hasShownInitialDimPopup) {
        shouldUpdate = false;
        //ask if user wants to copy over from another layer
        //if they say yes open copy edits popup
        //if they say no allow edit to happen
        var wantsToTransferOver = confirm(`We see that you are editing ${this.props.projectData.activeDimensionsLayer} dimensions for the first time. Would you like to copy edits from another layer to use as a starting point?

Click OK to copy edits from another layer.
Click Cancel to start from scratch.`);

        if (wantsToTransferOver) {
          var dimTransferFrom = {
            installation: 'production',
            production: 'client'
          }[this.props.projectData.activeDimensionsLayer];
          var dimTransferTo = this.props.projectData.activeDimensionsLayer;

          this.props.projectData.toggleDimEditsCopyPopupShowing({dimTransferFrom, dimTransferTo});
        }

        this.setState({hasShownInitialDimPopup: true});
      }
    }

    if (shouldUpdate) {
      var updatedDimensionsData = {
        ...dimensionsData,
        [this.props.projectData.activeDimensionsLayer]: getDimensionsData(activeLayerDimensionsData)
      };

      lib.api.update('projectVersion', {where: {id: this.props.projectData.versionId}, props: {dimensionsData: updatedDimensionsData}});

      this.props.updateProject({id: this.props.projectData.id, props: {dimensionsData: {...updatedDimensionsData}}, hitApi: false});
    }
  };

  contextMatches({entity}) {
    var {contextId, oldContextId, contextResourceKey} = this;

    return entity.contextType === contextResourceKey && (entity.contextId === contextId || entity.contextId === oldContextId);
  }

  handleCustomDimensionTransformEnd({props, line}) {
    var {viewDepth: offset} = props;
    var {dimensionSet, viewOffset, parentOffset, elevation, viewKey} = this.props;
    var {customDimension} = dimensionSet;

    if (!_.isFinite(offset)) offset = customDimension.offset;

    //HINT swapped axis
    if (offset !== customDimension.offset) {
      if (!_.isEqual([line.from, line.to], [props.from, props.to]) && offset !== customDimension.offset) {
        line = {from: line.to, to: line.from};
      }
    }
    else {
      line = {from: props.from, to: props.to};
    }

    var line = {
      from: lib.object.difference(line.from, viewOffset, parentOffset),
      to: lib.object.difference(line.to, viewOffset, parentOffset)
    };

    //HINT convert back to 3d position in room
    if (viewKey === 'front') {
      var threeDPositionFor = ({position2d}) => {
        //convert the existing position3d to a position on the wall, so it is effectively 2d
        var elevationXYOrigin = elevation.lineInRoom.from;
        var elevationXZOrigin = {x: elevationXYOrigin.x, z: elevationXYOrigin.y};

        var offsetXZPositionInElevation = {x: 0, z: 0};
        var rotatedXZPositionInElevation = lib.math.trig.rotate({point: {x: offsetXZPositionInElevation.x, y: offsetXZPositionInElevation.z}, byDegrees: -Elevation.getRotation({elevation})});

        var newXZPosition = {x: position2d.x, y: rotatedXZPositionInElevation.y};

        //convert the position back to 3d
        var rotatedXZPositionInRoom = lib.math.trig.rotate({point: newXZPosition, byDegrees: Elevation.getRotation({elevation})});
        var position3d = {x: rotatedXZPositionInRoom.x, y: -position2d.y, z: rotatedXZPositionInRoom.y};

        position3d = lib.object.sum(position3d, elevationXZOrigin);

        return position3d;
      };

      line = {
        from: threeDPositionFor({position2d: line.from}),
        to: threeDPositionFor({position2d: line.to}),
      };
    }

    this.updateDimensionsData(dimensionsData => ({
      ...dimensionsData,
      customDimensionsById: {
        ...dimensionsData.customDimensionsById,
        [customDimension.id]: {
          ...customDimension,
          line,
          offset
        }
      }
    }));
  }

  handleDestroyCustomDimension() {
    this.updateDimensionsData(dimensionsData => ({
      ...dimensionsData,
      customDimensionsById: _.omit(dimensionsData.customDimensionsById, [this.props.dimensionSet.customDimension.id])
    }));
  }

  handleDimensionViewDepthTransformEnd({props, line, targetId}) {
    var {viewDepth: offset} = props;

    //HINT swapped axis
    if (!_.isEqual([line.from, line.to], [props.from, props.to])) offset = -offset;

    this.updateDimensionsData(dimensionsData => ({...dimensionsData, offsetsById: {...dimensionsData.offsetsById, [targetId]: offset}}));
  }

  render() {
    var {dimensionSet, viewOffset, canvasData, renderForDrawings, isExportingSvg} = this.props;
    var {targets} = dimensionSet;
    var {isEditingDimensions, toggleTolerancePopupShowing} = this.props.projectData;
    var {activeLayerDimensionsData} = this;
    var dimensionSelectionData = _.get(this.props, 'selectionData.activeDimensionData') || {};
    var {additiveDimensionsEditing} = activeLayerDimensionsData;

    if (dimensionSet.isCustomDimension) {
      var line = {
        from: lib.object.sum(targets[0].position, viewOffset),
        to: lib.object.sum(targets[1].position, viewOffset)
      };

      var isSelected = dimensionSet.id === dimensionSelectionData.dimensionSetId;
    }

    return (<>
      {isSelected && (
        <EditableCanvasLine
          {...line}
          stroke={K.colors.customDimensions}
          strokeWidth={K.drawingsStrokeWidths.light}
          isSelected={isSelected}
          viewDepth={dimensionSet.customDimension.offset || 0}
          centerDepthHandle
          listening={!canvasData.isStatic && isEditingDimensions}
          portalSelector={'.hud-layer'}
          onClick={() => {
            if (!isSelected) this.props.selectionData.setActiveDimensionData({line, ..._.pick(this, ['contextId', 'contextResourceKey'])});
          }}
          onDelete={() => this.handleDestroyCustomDimension({line})}
          onTransformEnd={(props) => this.handleCustomDimensionTransformEnd({props, line})}
        />
      )}
      {_.map(targets, (target, index) => {
        var isSelected = target.id === dimensionSelectionData.targetId;
        var tolerance = activeLayerDimensionsData.tolerancesById[target.id];
        var shouldHoldTo = activeLayerDimensionsData.shouldHoldTosById[target.id];
        var customOffset = activeLayerDimensionsData.offsetsById[target.id] || 0;

        if (target.previousEnabledTarget) target.previousEnabledTarget.tolerance = activeLayerDimensionsData.tolerancesById[target.previousEnabledTarget.id]
        if (target.nextEnabledTarget) target.nextEnabledTarget.tolerance = activeLayerDimensionsData.tolerancesById[target.nextEnabledTarget.id]

        return (
          <Distance
            key={target.id}
            dimensionSetId={dimensionSet.id}
            onOffsetChange={this.handleDimensionViewDepthTransformEnd}
            onSelect={(activeDimensionData) => {
              if (!isSelected && activeDimensionData) this.props.selectionData.setActiveDimensionData(activeDimensionData);
            }}
            showLabelBorder={dimensionSet.showLabelBorder || (tolerance && tolerance !== 0 && tolerance !== 'eq')}
            {...{target, index, isEditingDimensions, toggleTolerancePopupShowing, tolerance, shouldHoldTo, customOffset, isSelected, additiveDimensionsEditing, renderForDrawings, isExportingSvg}}
            {..._.pick(this.props, ['canvasData', 'viewOffset', 'showBindingDimensions'])}
            {..._.pick(dimensionSet, ['alpha', 'lineIsHorizontal', 'originLine', 'isCustomDimension'])}
            {..._.pick(this, ['updateDimensionsData'])}
          />
        );
      })}
    </>);

  }
}

function CanvasDimensionLineWithContext(props) {
  let canvasData = useContext(CanvasDataContext);
  let projectData = useContext(ProjectDataContext);
  let selectionData = useContext(CanvasSelectionContext);

  if (!projectData.isEditingDimensions) selectionData = {};

  return (
    <CanvasDimensionLine {...props} {...{canvasData: _.pick(canvasData, ['scale', 'containerSize', 'offset', 'isStatic', ...(projectData.isEditingDimensions ? ['isShifting'] : [])]), selectionData, projectData}}/>
  );
}

export default withErrorBoundary(connect({
  mapDispatch: {
    ..._.pick(resourceActions.projects, ['updateProject'])
  }
})(CanvasDimensionLineWithContext), {
  FallbackComponent: CanvasErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'Canvas Dimension Line'})
});