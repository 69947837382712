import React, { useEffect, useState, Fragment } from 'react';

import K from 'k';
import _ from 'lodash';
import TextInput from 'components/text-input';
import DxfPopup from '../../popups/dxf-popup';
import DragDropList from 'components/drag-drop-list';
import CanvasRooms3D from 'project-components/canvas-rooms-3d';

import {withErrorBoundary} from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';

import dxfIcon from 'assets/dxf-icon.png';
import upArrow from '../../../../assets/up-arrow-black.png';
import createIconBlack from '../../../../assets/create-icon.png';
import deleteIcon from '../../../../assets/trash-icon-black.png';
import AddableItem from './addable-item';

var MenuNode = (props) => {
  var {menuNode, level, index, sharedNodeAttributes, productsExpanded, shouldAutoExpand} = props;
  var {childNodeGroups, onTitleChange, onNavigateTo, onClick, onDelete, isDxfUploadable, entity, id, type, title} = menuNode;
  var {activeViewEntityResourceKey, activeViewEntity, isSearching, activeAddingNodeIds, handleSetActiveAddingNodeIds, activeEntityResourceKey, expandedMenuNodeIds} = props.sharedNodeAttributes;
  var [isEditing, setIsEditing] = useState(false);
  var [isUploadingDxf, setIsUploadingDxf] = useState(false);
  var autoExpandWhenIndex0 = (shouldAutoExpand) || (sharedNodeAttributes.companyKey === 'vp' && _.includes(['productCategory', 'containerCategory'], type));
  var isManuallyCollapsed = expandedMenuNodeIds?.[menuNode.id] === false;
  var autoExpandedWhileSearching = level === 1 ? (productsExpanded ? menuNode.id === 'products' : true) : true;
  var isExpanded = isManuallyCollapsed ? false : (autoExpandWhenIndex0 && index === 0) ? true : (expandedMenuNodeIds?.[menuNode.id] || (isSearching && autoExpandedWhileSearching));

  var toggleIsExpanded = () => {
    sharedNodeAttributes.handleMenuNodeIsExpandedChange({id: menuNode.id, value: !isExpanded, category: sharedNodeAttributes.category});
  }

  var handleTitleChange = ({value}) => {
    if (onTitleChange) onTitleChange({value});
  };

  var handleTitleOnBlur = () => {
    setIsEditing(false);
  };

  var handleClick = () => {
    if (onClick) onClick();
  }

  var handleDoubleClick = () => {
    if (onTitleChange) {
      setIsEditing(true);
    }
  };

  var expandArrowSize = {width: 14, height: 14}

  var menuStyles = {
    header: {fontFamily: 'Work Sans', textTransform: 'uppercase', fontSize: 14, fontWeight: 510},
    uppercase: {fontFamily: 'Work Sans', textTransform: 'uppercase', fontSize: 13, fontWeight: 401},
    lowercase: {fontFamily: 'Open Sans', textTransform: 'uppercase', fontSize: 11},
    addProjectTreeNodeButton: {fontFamily: 'Open Sans', textTransform: 'lowercase', fontSize: 11, cursor: 'pointer'},
    childNodeGroupTitle: {marginLeft: expandArrowSize.width + K.spacing, fontWeight: 401, textTransform: 'uppercase', fontSize: 11, fontStyle: 'italic', opacity: 0.6},
    addableItemStyles: {fontFamily: 'Work Sans', textTransform: 'uppercase', fontSize: 11},
    addButtonTitle: {textTransform: 'uppercase', fontSize: 11,}
  }

  var headerStyle;
  if (level === 1) headerStyle = menuStyles.header;
  if (level === 2) headerStyle = menuStyles.uppercase;
  if (level >= 3) headerStyle = menuStyles.lowercase;

  var getAddButton = (addButtonData) => {
    return (
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', paddingLeft: 0, marginLeft: (K.spacing + expandArrowSize.width) * 2 - K.spacing / 2, gap: K.spacing / 2, opacity: 0.4}}
      onClick={addButtonData.onClick}
        // data-conditional-opacity={1}
      >
        <div style={menuStyles.addButtonTitle}>{addButtonData.title}</div>

        <div
          style={{
            ...menuStyles.addProjectTreeNodeButton,
            height: 12, width: 12, marginLeft: K.spacing / 2, marginRight: K.spacing / 2, marginLeft: 0
          }}
        >
          <img src={createIconBlack} style={{height: 12, width: 12}} />
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        paddingBottom: K.spacing * (level === 1 && (isExpanded && !_.includes(['elevation', 'scope'], type)) ? 2.5 : 1),
        ...(level !== 1 && _.includes(['project', 'floor', 'room', 'elevation', 'scope'], type) ? {marginLeft: expandArrowSize.width + K.spacing / 2} : {}),
        ...((props.isHeader === true && isExpanded) ? {flex: '1 1 auto', minHeight: 0, display: 'flex', flexDirection: 'column'} : {})

      }}
    >
      <div
        style={{
          display: 'flex', flexDirection: 'row',
        }}
        data-conditional-opacity-parent={1}
      >
        <div style={{...expandArrowSize, marginRight: K.spacing / 2, marginLeft: K.spacing / 2}}>
          {childNodeGroups && childNodeGroups.length > 0 &&
          !(_.includes(['elevation', 'scope'], type) && _.get(childNodeGroups, '[0].childNodes.length') === 0) && (
            <img {...(!isExpanded ? {'data-conditional-opacity': 1} : {})} src={upArrow}
              style={{...expandArrowSize, objectFit: 'contain', cursor: 'pointer', ...(isExpanded ? {transform: 'rotate(180deg)'} : {transform: 'rotate(90deg)'})}}
              onClick={toggleIsExpanded}
            />
          )}
        </div>
        <div
          onClick={(_.includes(['containers', 'props', 'project', 'archElements', 'volumes', 'products', 'archetypes', 'nonSpacialContainers'], id) || _.includes(['containerCategory', 'productCategory'], type)) ? toggleIsExpanded : handleClick}
          onDoubleClick={handleDoubleClick}
          style={{
            ...headerStyle, display: 'flex', flexDirection: 'row', alignItems: 'center',
            cursor: 'pointer', ...(_.includes(['container', 'room', 'elevation'], type) && sharedNodeAttributes.getIncludesActiveEntity({resourceKey: type, id: menuNode.draggableId}) ? {fontWeight: 'bold'} : {})
            // ...(_.get(childNodeGroups, '[0].childNodes.length') === 0 ? {cursor: 'default'} : {cursor: 'pointer'})
          }}
          // {...{...(!(_.includes(['elevation', 'scope'], type) && _.get(childNodeGroups, '[0].childNodes.length') === 0)) ? {onClick: toggleIsExpanded} : {}}}
        >
          {isEditing ? (
            <TextInput
              autoFocus={true}
              noFill
              value={menuNode.title}
              onChange={handleTitleChange}
              onBlur={handleTitleOnBlur}
            />
          ) : (menuNode.title)}
        </div>
        {onNavigateTo && (
          <div data-conditional-opacity={1} style={{marginLeft: K.spacing, cursor: 'pointer'}} onClick={onNavigateTo}>→</div>
        )}
        {isDxfUploadable && (
          <img data-conditional-opacity={1} src={dxfIcon} style={{marginLeft: K.spacing, cursor: 'pointer', width: 16, objectFit: 'contain'}} onClick={() => setIsUploadingDxf(true)}/>
        )}
        {onDelete && (
          <img data-conditional-opacity={1} src={deleteIcon} style={{marginLeft: K.spacing, cursor: 'pointer', width: 16, objectFit: 'contain'}} onClick={onDelete}/>
        )}
      </div>
      {isUploadingDxf && (
        <DxfPopup
          updateFloor={sharedNodeAttributes.updateFloor}
          floorId={entity.id}
          projectId={entity.projectId}
          close={() => setIsUploadingDxf(false)}
        />
      )}
      {isExpanded && (
        <div
          style={{
            paddingTop: K.spacing,
            ...(props.isHeader && isExpanded ? {overflow: 'auto', flex: '1 1 auto', minHeight: 0} : {})
          }}>
          {_.map(childNodeGroups, (childNodeGroup, childNodeGroupIndex) => {
            var nodes = _.map(childNodeGroup.childNodes, (childNode, index) => {
              var {draggableId, isReorderDisabled} = childNode;
              var node;

              if (childNode.isProjectMenuNodeContainer) {
                if (childNode.isOrnament) return;

                node = (
                  <AddableItem
                    key={childNode.id || childNode.type}
                    option={childNode}
                    optionIndex={index}
                    {..._.pick(sharedNodeAttributes, ['showThumbnails', 'getIncludesActiveEntity'])}
                    headerDivStyles={menuStyles.addableItemStyles}
                    onClick={childNode.onClick}
                    isProjectMenuNodeContainer={true}
                  />
                )
              }
              else if (childNode.isCreatable) {
                node = (
                  <AddableItem
                    key={childNode.id || childNode.type}
                    option={childNode}
                    optionIndex={index}
                    addingGroup={childNode.resourceKey}
                    {..._.pick(sharedNodeAttributes, ['toggleInfoPopupShowing', 'createDragItem', 'setDragData', 'viewMode', 'showThumbnails', 'handleAddMenuMouseUp'])}
                    headerDivStyles={menuStyles.addableItemStyles}
                  />
                )
              }
              else {
                node = (
                  <MenuNode
                    menuNode={childNode}
                    key={childNode.id || childNode.type}
                    level={level + 1}
                    {...{sharedNodeAttributes, index}}
                  />
                )
              }

              if (_.includes(['project', 'floor', 'room'], type)) {
                return ({
                  draggableId,
                  isDragDisabled: isReorderDisabled
                    && _.includes(['project', 'floor', 'room'], type)
                    && draggableId,
                  node
                })
              }
              else {
                return node;
              }
            })

            return (
              <div
                key={childNodeGroup.id}
              >
                {
                  childNodeGroup.title &&
                  (
                    (childNodeGroup.title === 'Elevations' && childNodeGroup.childNodes.length > 0) ||
                    (childNodeGroup.title === 'Pricing Scopes' && childNodeGroup.childNodes.length > 0) ||
                    (_.includes(['project', 'floor', 'room'], type))
                  ) &&
                (
                  <div
                    style={{
                      ...menuStyles.childNodeGroupTitle,
                      paddingBottom: K.spacing, display: 'flex',
                      flexDirection: 'row', alignItems: 'center',
                      // ...(childNodeGroup.addButtonData ? {cursor: 'pointer'} : {}),
                      cursor: 'default',
                      paddingLeft: 1,
                      paddingTop: childNodeGroupIndex !== 0 ? K.spacing : 0,
                      // ...(_.includes(['Elevations', 'Pricing Scopes'], childNodeGroup.title) ? {paddingLeft: K.spacing / 2 + expandArrowSize.width} : {})
                    }}
                    data-conditional-opacity-parent={1}
                    {...{...(childNodeGroup.addButtonData ? {onClick: childNodeGroup.addButtonData.onClick} : {})}}
                  >
                    {childNodeGroup.title}
                  </div>
                )}
                {menuNode.id === 'archetypes' && (
                  <div style={{display: 'flex', flexDirection: 'column'}} key={`archetypes-${sharedNodeAttributes.configurationLibraryCategory}`}>
                    <div style={{width: '100%', height: K.paneWidth, position: 'relative'}}>
                      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: K.spacing + 7, flexGrow: 1, paddingTop: K.spacing}}>
                        {_.map([
                          {title: 'Archetypes', value: 'archetype'},
                          {title: 'Common Configurations', value: 'commonConfiguration'},
                          {title: 'Atlantic Fields', value: 'atlanticFields'},
                          {title: 'Project Room Copies', value: 'projectRoomCopy'},
                          ...(sharedNodeAttributes.viewMode === 'both' || (sharedNodeAttributes.viewMode === 'top' && sharedNodeAttributes.roomIsSelected) ? [{title: 'Building Blocks', value: 'buildingBlock'}] : [])
                        ], configurationLibraryCategoryOption => (
                          <input
                            key={configurationLibraryCategoryOption.value}
                            value={configurationLibraryCategoryOption.title}
                            type={'button'}
                            style={{background: 'none', display: 'block', border: 'none', outline: 'inherit', textTransform: 'uppercase', fontSize: 12, letterSpacing: '0.1em', cursor: 'pointer', paddingBottom: 18, ...(configurationLibraryCategoryOption.value === sharedNodeAttributes.configurationLibraryCategory ? {fontWeight: 'bold'} : {opacity: 0.5})}}
                            onClick={() => sharedNodeAttributes.handleUpdateConfigurationLibrary({configurationLibraryCategory: configurationLibraryCategoryOption.value})}
                          />
                        ))}
                      </div>
                      {
                        _.includes(['projectRoomCopy', 'atlanticFields'], sharedNodeAttributes.configurationLibraryCategory) &&
                        _.find(childNodeGroup.childNodes, ['type', sharedNodeAttributes.configurationLibraryCategory]) &&
                      (
                        _.map(_.find(childNodeGroup.childNodes, ['type', sharedNodeAttributes.configurationLibraryCategory]).childNodeGroups[0].childNodes, (childNode, index) => (
                          <div style={{display: 'flex', flexDirection: 'column'}} key={childNode.id}>
                            <Fragment key={`archetypes-atlantic-fields-${index}`}>
                              <AddableItem
                                showThumbnails={false}
                                resourceKey={'archetype'}
                                optionIndex={index}
                                addingGroup={'archetype'}
                                createDragItem={sharedNodeAttributes.createDragItem}
                                setDragData={sharedNodeAttributes.setDragData}
                                bothIsShowingElevations={sharedNodeAttributes.bothIsShowingElevations}
                                toggleInfoPopupShowing={sharedNodeAttributes.toggleInfoPopupShowing}
                                companyKey={sharedNodeAttributes.companyKey}
                                viewMode={sharedNodeAttributes.viewMode}
                                handleAddMenuMouseUp={sharedNodeAttributes.handleAddMenuMouseUp}
                                option={childNode}
                                headerDivStyles={menuStyles.addableItemStyles}
                              />
                            </Fragment>
                          </div>
                        ))
                      )}
                      {
                        !_.includes(['projectRoomCopy', 'atlanticFields'], sharedNodeAttributes.configurationLibraryCategory) &&
                        _.find(childNodeGroup.childNodes, ['type', sharedNodeAttributes.configurationLibraryCategory]) &&
                      (
                        <div style={{paddingBottom: K.spacing * 3, height: 400}}>
                          <CanvasRooms3D
                            isArchetype={true}
                            roomsData={
                              _.map(_.find(childNodeGroup.childNodes, ['type', sharedNodeAttributes.configurationLibraryCategory]).childNodeGroups[0].childNodes, option => (
                                {
                                  ...option.roomData,
                                  ..._.pick(option, ['gridPosition', 'gridOffset', 'title', 'gridTextOffset']),
                                  option
                                }
                              ))
                            }
                            viewOffset={{x: 0, y: 0}}
                            activeDetailLevel={sharedNodeAttributes.activeDetailLevel}
                            activeFillMode={'materialColors'}
                            cameraZoom={0.1 * sharedNodeAttributes.scale}
                            cameraPosition={[100 * sharedNodeAttributes.scale, 300 * sharedNodeAttributes.scale, 200 * sharedNodeAttributes.scale]}
                            cameraMouseControls={true}
                            hideArchitecture={sharedNodeAttributes.configurationLibraryCategory === 'buildingBlock'}
                            onRoomClick={({event, roomData}) => {
                              sharedNodeAttributes.setDragData({
                                isDragging: true,
                                dragItem: sharedNodeAttributes.createDragItem(roomData.option),
                                lastMouseEvent: event,
                                activeRoomId: _.map(sharedNodeAttributes.activeEntitiesData, activeEntity => {
                                  if (activeEntity.resourceKey === 'room') return activeEntity.id;
                                })});
                            }}
                            showLabel={true}
                          />
                        </div>
                      )}
                  </div>
                </div>
                )}
                {!_.includes(['archetypes-group'], childNodeGroup.id) && (
                  <div
                    style={{
                      ...(childNodeGroup.childNodesContainThumbnails ? {paddingBottom: K.spacing * 1.5} : {}),
                      ...(sharedNodeAttributes.showThumbnails && childNodeGroup.childNodesContainThumbnails ?
                        {marginLeft: K.spacing + 14, gap: K.spacing}
                      : {}),
                    }}
                  >
                    {childNodeGroup.childNodes && childNodeGroup.childNodes.length > 0 && (
                    <div
                        style={{...((sharedNodeAttributes.showThumbnails && childNodeGroup.childNodesContainThumbnails) ? {display: 'flex', flexWrap: 'wrap', flexDirection: 'row'} : {})}}
                    >
                      {_.includes(['project', 'floor', 'room'], type)
                        ? (
                          <DragDropList
                            onDragEnd={(updates) => childNodeGroup.onReorder({updates})}
                            items={nodes}
                          />
                        )
                        : (
                          nodes
                        )
                      }
                    </div>
                    )}
                  </div>
                )}
                <div data-conditional-opacity-parent={1}>
                  {childNodeGroup.addButtonData && (
                    getAddButton(childNodeGroup.addButtonData)
                  )}
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  );
};

export default withErrorBoundary(MenuNode, {
  FallbackComponent: ErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'Menu Node'})
});