import React from 'react';
import _ from 'lodash';
import K from 'k';
import HudElement from './hud-element';
import Dropdown from 'components/dropdown';
import Popup from 'components/popup';

import InfoIcon from '../../../assets/info-icon.png';
import upArrowBlack from '../../../assets/up-arrow-black.png';

class VisibilityLayersHudElement extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    isHoveringColorKeyInfoIcon: false,
    visibilityControlsGroupIsExpanded: true
  };

  setIsHoveringColorKeyInfoIcon = (value) => {
    this.setState({isHoveringColorKeyInfoIcon: value});
  };

  render() {
    var {viewMode, companyKey, visibilityLayers, activeDetailLevel, activeDimensionsLayer, activeFillMode, setActiveFillMode, bothIsShowingElevations, floor, room, colorKeyPopupIsVisible} = this.props;
    var {isHoveringColorKeyInfoIcon, dimTransferFrom, dimTransferTo, visibilityControlsGroupIsExpanded} = this.state;

    var layers = [
      ...(!_.includes(['threeD', 'lite'], viewMode) ? [
        {key: 'dimensions', title: 'Dimensions'},
        //HINT removing because we always want binding dims visible
        //and this toggle only shows/hides tolerance symbols
        // ...(visibilityLayers.dimensions ? [{key: 'bindingDimensions', title: 'Binding Dimensions'}] : []),
        {key: 'datums', title: 'Datums'},
      ] : []),
      ...(!_.includes(['lite'], viewMode) ? [
        {key: 'wallsAndArchElements', title: 'Hide Walls'},
      ] : []),
    ];

    if (_.includes(['top', 'both'], viewMode)) {
      layers.push(
        ...(floor && !!floor.stencil ? [{key: 'stencil', title: 'DXF Stencil'}] : []),
        {key: 'elevationLines', title: 'Elevation Lines'},
        {key: 'scopes', title: 'Pricing Scopes'},
        {key: 'unitLabels', title: 'Plan Labels'}
      );
    }

    if (_.includes(['front', 'both'], viewMode) && !(viewMode === 'both' && !bothIsShowingElevations)) {
      layers.push({key: 'productTitles', title: 'Product Titles'});
    }

    if (_.includes(['top', 'both'], viewMode)) {
      layers.push(
        {key: 'ornamentTopIndicators', title: 'Prop Indicators'}
      );
    }

    if (_.includes(['front', 'lite'], viewMode)) {
      layers.push(
        {key: 'canvasSettings', title: 'Quick Toggles'},
        {key: 'canvasSettingsProductDetails', title: 'Product Details'}
      );
    }
    // if (_.includes(['front', 'both'], viewMode) && !(viewMode === 'both' && !bothIsShowingElevations) && activeDetailLevel === 'rendering') {
    //   layers.push(
    //     {key: 'perspective', title: 'Perspective'}
    //   );
    // }

    if (activeDetailLevel === 'fullDetail' && (viewMode === 'front' || (viewMode === 'both' && bothIsShowingElevations)) && (companyKey === 'hb' || _.get(room, 'customData.grainFlowEnabled'))) {
      layers.push({key: 'grainFlow', title: 'Grain Flow'});
    }

    if (_.includes(['fullDetail', 'intermediate'], activeDetailLevel) && _.includes(['front', 'both'], viewMode) && !(viewMode === 'both' && !bothIsShowingElevations)) {
      layers.push({key: 'unitNumbers', title: 'Unit Numbers'});
    }

    const detailLevel = [
      {title: 'Production', key: 'production'},
      {title: 'Installation', key: 'installation'},
      {title: 'Full Design', key: 'fullDetail'}, //HINT: current DE
      {title: 'Intermediate', key: 'intermediate'}, //HINT: current actions off
      {title: 'Schematic', key: 'schematic'}, //HINT: current Schematic
      {title: 'Rendering', key: 'rendering'}, //HINT: intermediate + lighter stroke + noise
    ];

    const fillModes = [
      {title: 'Default', key: 'default'}, //HINT: current DE material hatches off
      {title: 'Material Colors', key: 'materialColors'}, //HINT: hatch mode 'color'
      {title: 'Material Hatches', key: 'materialHatches'}, //HINT: hatch mode 'hatch'
      {title: 'Grayscale', key: 'grayscale'}, //HINT: schematic
      {title: 'Unit Type', key: 'unitType'} //HINT: DPC mode
    ];

    const buttonWidth = '115px';

    const styles = {
      infoButton: {
        width: 16, height: 16
      },
      expandButton: {
        width: 16, height: 16, opacity: 0.5
      },
      controlsGroup: {
        containerStyle: {paddingLeft: K.spacing},
        labelStyle: {fontSize: '0.85rem', textAlign: 'right', fontWeight: 'bold', marginBottom: 8, paddingRight: 10, marginTop: K.spacing * 2},
        contentContainerStyle: {display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}
      },
      button: {
        style: {width: buttonWidth, textAlign: 'right', backgroundColor: '#fff', border: '1px solid #eee', borderRadius: 1000, padding: '3px 10px', color: 'black', cursor: 'pointer', marginBottom: 4},
        labelStyle: {fontSize: '0.75rem', cursor: 'pointer'},
      },
      dropdown: {
        style: {fontSize: '0.75rem', marginBottom: 4},
        labelStyle: {width: buttonWidth, textAlign: 'right', backgroundColor: 'black', color: 'white', border: '1px solid black', borderRadius: 1000, padding: '3px 10px'},
        contentContainerStyle: {width: buttonWidth, textAlign: 'right', position: 'absolute'},
      }
    };

    var dimensionLayers = [
      {key: 'client', title: 'Client'},
      {key: 'production', title: 'Production'},
      {key: 'installation', title: 'Installation'}
    ];

    return (
      <>
        <HudElement
          x='right' y='top'
          style={{
            height: 'calc(100% - 150px - 20px)', zIndex: 10, display: 'flex', flexDirection: 'column', alignItems: 'flex-end', overflow: 'scroll',
            ...(_.includes(['front', 'both', 'top'], this.props.viewMode) ? {top: 150} : {}),
            ...(this.props.viewMode === 'threeD' && _.get(floor, 'customData.threeDBackgroundColor') ? {backgroundColor: floor.customData.threeDBackgroundColor} : {})
          }}
        >
          <div style={{...styles.controlsGroup.containerStyle}}>
            <div style={{...styles.controlsGroup.labelStyle}}>DETAIL LEVEL</div>
            <div style={{...styles.controlsGroup.contentContainerStyle}}>
              <Dropdown
                value={activeDetailLevel}
                onChange={(value) => this.props.setActiveDetailLevel({key: value})}
                options={_.map(detailLevel, ({key, title}) => ({value: key, title}))}
                style={{...styles.dropdown.style}}
                labelStyle={{...styles.dropdown.labelStyle}}
                contentContainerStyle={{...styles.dropdown.contentContainerStyle}}
                hasFixedHeight={true}
                showCaret={false}
              />
            </div>
          </div>
          {_.includes(['front', 'both', 'threeD', 'lite'], viewMode) && !(viewMode === 'both' && !bothIsShowingElevations) && (
            <div style={{...styles.controlsGroup.containerStyle}}>
              <div style={{...styles.controlsGroup.labelStyle}}>FILL MODE</div>
              <div style={{...styles.controlsGroup.contentContainerStyle}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: K.spacing / 2}}>
                  {_.includes(['materialColors', 'materialHatches', 'unitType'], activeFillMode) && (
                    <div
                      onMouseEnter={() => this.setIsHoveringColorKeyInfoIcon(true)}
                      onMouseLeave={() => this.setIsHoveringColorKeyInfoIcon(false)}
                      style={{cursor: 'pointer'}}
                      onClick={() => this.props.setColorKeyPopupIsVisible({value: !colorKeyPopupIsVisible})}
                    >
                      <div style={{opacity: isHoveringColorKeyInfoIcon ? 0.9 : 0.5}}>
                        <img src={InfoIcon} style={styles.infoButton} />
                      </div>
                    </div>
                  )}
                  <Dropdown
                    value={activeFillMode}
                    onChange={(value) => setActiveFillMode({key: value})}
                    options={_.map(fillModes, ({key, title}) => ({value: key, title}))}
                    style={{...styles.dropdown.style}}
                    labelStyle={{...styles.dropdown.labelStyle}}
                    contentContainerStyle={{...styles.dropdown.contentContainerStyle}}
                    hasFixedHeight={true}
                    showCaret={false}
                  />
                </div>
              </div>
            </div>
          )}
          {<div style={{...styles.controlsGroup.containerStyle}}>
            <div
              style={{...styles.controlsGroup.labelStyle, cursor: 'pointer', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 2}}
              onClick={() => this.setState({visibilityControlsGroupIsExpanded: !visibilityControlsGroupIsExpanded})}
            >
              <img
                src={upArrowBlack}
                style={{...styles.expandButton, ...(visibilityControlsGroupIsExpanded ? {transform: 'rotate(180deg)'} : {transform: 'rotate(90deg)'})}}
              />
              <div style={{marginLeft: '10px'}}>VISIBILITY</div>
            </div>
            <div style={{...styles.controlsGroup.contentContainerStyle}}>
              {visibilityControlsGroupIsExpanded && (
                <>
                  {_.map(layers, layer => {
                    var isActive = layer.key === 'wallsAndArchElements' ? !visibilityLayers[layer.key] : visibilityLayers[layer.key];

                    return (
                      <div
                        key={layer.key}
                        style={{...styles.button.style, width: 'none', marginRight: 4, backgroundColor: isActive ? 'black' : '#fff', border: isActive ? '1px solid black' : '1px solid #eee', color: isActive ? 'white' : 'black'}}
                        onClick={() => this.props.updateVisibilityLayers({key: layer.key, isVisible: !visibilityLayers[layer.key]})}
                      >
                        <label style={{...styles.button.labelStyle}}>{layer.title}</label>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>}
          {visibilityLayers.dimensions && companyKey !== 'vp' && (
          <div style={{...styles.controlsGroup.containerStyle}}>
            <div style={{...styles.controlsGroup.labelStyle}}>DIMS</div>
            <div style={{...styles.controlsGroup.contentContainerStyle}}>
              <div style={{...styles.button.style}} onClick={() => this.props.toggleDimEditsCopyPopupShowing()}>
                <label style={{...styles.button.labelStyle}}>Copy Edits</label>
              </div>
            </div>
          </div>
        )}
        </HudElement>
      </>
    );
  }
}

export default VisibilityLayersHudElement;
