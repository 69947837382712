import K from 'k';
import React, { useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';

import lib from 'lib';
import { connect, resourceActions } from '../../../redux/index';

import DrawingsSidePanel from 'pages/editor/hud-elements/drawings-side-panel-hud-element';
import DrawingsPageSettingsPopup from './drawings-page-settings-popup';
import SetScalePopup from './drawings-page-set-scale-popup';
import AddGraphicPopup from './drawings-page-add-graphic-popup';
import EnableAutogenerationPopup from './drawings-page-enable-autogeneration-popup';
import DeleteStandardPagePopup from './drawings-page-delete-standard-page-popup';

import autogenerateIconWhite from '../../../assets/autogenerate-icon-white.png';
import exportToDxfIconWhite from '../../../assets/export-to-dxf-icon-white.png';
import projectTreeIcon from '../../../assets/list-icon-white.png';
import settingsIconWhite from '../../../assets/settings-icon-white.png';
import Drawings from 'project-helpers/drawings';

const DrawingsPageHudElements = ({
  isEditing, handleAutogenerate, containerDimensions, handleSettingsChange, isExportingDxf, setIsExportingDxf,
  //HINT visibility settings
  detailLevel, visibilityLayers, activeDimensionsLayer, fillMode,
  isFinalApproval, showGrid, imageMode,
  //HINT redux
  project, elevations, rooms, floors, materialTypes, leadingPagesCount,
  ...resourceActionDispatchers
}) => {
  const drawingsData = _.get(project, 'drawingsData', {});
  const standardPages = _.get(drawingsData, 'standardPages', []);

  const [showSettings, setShowSettings] = useState(false);
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [showScalePopup, setShowScalePopup] = useState(false);
  const [showGraphicPopup, setShowGraphicPopup] = useState(false);
  const [showAutogenerationConfirmationPopup, setShowAutogenerationConfirmationPopup] = useState(false);

  const [showDeleteStandardPagePopup, setShowDeleteStandardPagePopup] = useState(false);
  const [standardPageIdToRemove, setStandardPageIdToRemove] = useState(null);

  const [activePageData, setActivePageData] = useState(null);

  const settingsButtonRef = useRef();

  const styles = {
    controls: {
      display: 'flex',
      flexDirection: 'row',
      position: 'absolute',
      bottom: 26,
      left: 13,
      marginRight: K.spacing * 2,
      alignItems: 'center',
      justifyContent: 'center',
      userSelect: 'none',
      zIndex: 3,
    },
    icon: {
      cursor: 'pointer',
      zIndex: 2,
      bottom: 26,
      marginLeft: K.spacing,
      width: 36,
      height: 36,
      backgroundColor: 'black',
      color: 'white',
      borderRadius: 1000,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
  };

  // Handlers
  const handleAddStandardPage = () => {
    const scale = Drawings.getScale({project, containerDimensions});

    const newPage = {
      id: lib.string.uuid(),
      layout: [],
      scale,
      title: 'Untitled Page',
    };

    Drawings.handleUpdateDrawingsData({
      project,
      standardPages: standardPages.concat(newPage),
      continuousDrawingsAutogenerationDisabled: 1,
      resourceActionDispatchers
    });
  };

  const handleUpdateStandardPage = (id) => (pageData) => {
    const updatedStandardPages = _.cloneDeep(standardPages).concat();
    const indexOf = _.findIndex(updatedStandardPages, {id});
    const {layout} = pageData;
    //HINT overrides all graphic scales except isContext
    const updatedLayout = _.map(layout, graphic => {
      if (graphic.isContext) return graphic;

      return _.omit(graphic, ['scale']);
    });

    const updatePage = {...pageData, layout: updatedLayout};

    updatedStandardPages.splice(indexOf, 1, updatePage);

    Drawings.handleUpdateDrawingsData({
      project,
      standardPages: updatedStandardPages,
      continuousDrawingsAutogenerationDisabled: 1,
      resourceActionDispatchers
    });
    setActivePageData(null);
  };

  const handleUpdateAllStandardPage = (pageData) => {
    let updatedStandardPages = _.cloneDeep(standardPages).concat();

    updatedStandardPages = _.map(updatedStandardPages, page => {
      const {layout} = page;
      //HINT overrides all graphic scales except isContext
      const updatedLayout = _.map(layout, graphic => {
        if (graphic.isContext) return graphic;

        return _.omit(graphic, ['scale']);
      });

      return {...page, ...pageData, layout: updatedLayout};
    });

    Drawings.handleUpdateDrawingsData({
      project,
      standardPages: updatedStandardPages,
      continuousDrawingsAutogenerationDisabled: 1,
      resourceActionDispatchers
    });
    setActivePageData(null);
  };

  const handleAddGraphic = async (value) => {
    const [type, stringId] = value.split('-');
    const id = parseInt(stringId);
    const position = {x: 0.5, y: 0.5};
    const graphic = {[`${type}Id`]: id, position};
    const updatedStandardPages = _.cloneDeep(standardPages).concat();
    const {pageId} = activePageData;
    const indexOf = _.findIndex(updatedStandardPages, {id: pageId});

    updatedStandardPages[indexOf].layout.push(graphic);

    await Drawings.handleUpdateDrawingsData({
      project,
      standardPages: updatedStandardPages,
      continuousDrawingsAutogenerationDisabled: 1,
      resourceActionDispatchers
    });
    setActivePageData(null);
  };

  const handleExportDXF = () => {
    setIsExportingDxf(!isExportingDxf);
  };

  const closeDeleteStandardPagePopup = () => {
    setShowDeleteStandardPagePopup(false);
    setStandardPageIdToRemove(null);
  };

  const handleShowDeletePopup = (id) => () => {
    setShowDeleteStandardPagePopup(true);
    setStandardPageIdToRemove(id);
  };

  const handleDeleteStandardPage = () => {
    if (standardPages.length > 0 && standardPageIdToRemove !== null) {
      const updatedStandardPages = _.cloneDeep(standardPages).concat();

      const indexOf = _.findIndex(updatedStandardPages, {id: standardPageIdToRemove});

      updatedStandardPages.splice(indexOf, 1);

      Drawings.handleUpdateDrawingsData({
        project,
        standardPages: updatedStandardPages,
        continuousDrawingsAutogenerationDisabled: 1,
        resourceActionDispatchers
      });
    }

    setStandardPageIdToRemove(null);
    setShowDeleteStandardPagePopup(false);
  };

  const handleShowScalePopup = (pageId) => () => {
    setShowScalePopup(true);
    setActivePageData({pageId});
  };

  const handleReorderStandardPages = (updatedPages) => {
    Drawings.handleUpdateDrawingsData({
      project,
      standardPages: updatedPages,
      continuousDrawingsAutogenerationDisabled: 1,
      resourceActionDispatchers
    });
  };

  const handleCopyPageClick = (id) => () =>  {
    const updatedStandardPages = _.cloneDeep(standardPages);
    const indexOf = _.findIndex(updatedStandardPages, {id});
    const pageToCopy = updatedStandardPages[indexOf];
    const newPage = {...pageToCopy, id: lib.string.uuid()};

    updatedStandardPages.splice(indexOf + 1, 0, newPage);

    Drawings.handleUpdateDrawingsData({
      project,
      standardPages: updatedStandardPages,
      continuousDrawingsAutogenerationDisabled: 1,
      resourceActionDispatchers
    });
  }

  const toggleSettingsVisibility = () => {
    setShowSettings(!showSettings);
  };

  const closeSettingsPopup = () => {
    setShowSettings(false);
  };

  const toggleSidePanelVisibility = () => {
    setShowSidePanel(!showSidePanel);
  };

  const hideSidePanel = () => {
    setShowSidePanel(false);
  };

  const closeScalePopup = () => {
    setShowScalePopup(false);
  };

  const handleAddGraphicClick = (pageId) => () => {
    setActivePageData({pageId});
    setShowGraphicPopup(true);
  };

  const closeGraphicPopup = () => {
    setShowGraphicPopup(false);
  };

  const closeAutogeneratePopup = () => {
    setShowAutogenerationConfirmationPopup(false);
  };

  const submitAutogeneratePopup = ({enableContinuousAutogeneration, autogenerateStrategy}) => {
    setShowAutogenerationConfirmationPopup(false);

    handleAutogenerate({override: true, enableContinuousAutogeneration, autogenerateStrategy});
  };

  const onSubmitSettings = (updates) => {
    handleSettingsChange(updates);

    setShowSettings(false);
  };
  const pages = Drawings.getPageData({standardPages, detailLevel, project, materialTypes});

  return (
    <>
      {showSidePanel && (
        <DrawingsSidePanel
          {...{standardPages, isEditing, leadingPagesCount}}
          onAddStandardPage={handleAddStandardPage}
          onCopyPage={handleCopyPageClick}
          onShowDeletePopup={handleShowDeletePopup}
          onHide={hideSidePanel}
          onShowScalePopup={handleShowScalePopup}
          onShowGraphicPopup={handleAddGraphicClick}
          onReorderStandardPages={handleReorderStandardPages}
        />
      )}
      {showSettings && (
        <DrawingsPageSettingsPopup
          {...{detailLevel, visibilityLayers, activeDimensionsLayer, fillMode,
            isFinalApproval, showGrid, isEditing, imageMode
          }}
          onCloseExceptions={[settingsButtonRef]}
          onClose={closeSettingsPopup}
          onSubmit={onSubmitSettings}
        />
      )}
      {showScalePopup && (
        <SetScalePopup
          {...{page: _.find(pages, {id: activePageData?.pageId})}}
          onClose={closeScalePopup}
          onUpdatePageData={handleUpdateStandardPage(activePageData?.pageId)}
          onUpdateAllPages={handleUpdateAllStandardPage}
        />
      )}
      {showGraphicPopup && (
        <AddGraphicPopup
          {...{project, elevations, rooms, floors, activePageData}}
          onClose={closeGraphicPopup}
          onChange={handleAddGraphic}
        />
      )}
      {showAutogenerationConfirmationPopup && (
        <EnableAutogenerationPopup onClose={closeAutogeneratePopup} onSubmit={submitAutogeneratePopup} autogenerateStrategy={drawingsData.autogenerateStrategy}/>
      )}
      {showDeleteStandardPagePopup && (
        <DeleteStandardPagePopup
          onClose={closeDeleteStandardPagePopup}
          {...{handleDeleteStandardPage}}
        />
      )}
      <div style={styles.controls}>
        {isEditing && <>
          <div onClick={toggleSidePanelVisibility} style={styles.icon} data-tip='tooltip' data-for={'table-of-content-tooltip'}>
            <ReactTooltip id={'table-of-content-tooltip'} effect='solid'>Table of Contents</ReactTooltip>
            <img src={projectTreeIcon} style={{width: 16, height: 16}}/>
          </div>
          <div
            onClick={() => setShowAutogenerationConfirmationPopup(true)}
            style={styles.icon} data-tip='tooltip'
            data-for={'autogenerate-tooltip'}
          >
            <ReactTooltip id={'autogenerate-tooltip'} effect='solid'>Autogenerate</ReactTooltip>
            <img src={autogenerateIconWhite} style={{width: 16, height: 16}}/>
          </div>
        </>}
        <div onClick={handleExportDXF} style={styles.icon} data-tip='tooltip' data-for={'export-dxf-tooltip'}>
          <ReactTooltip id={'export-dxf-tooltip'} effect='solid'>Export to DXF</ReactTooltip>
          <img src={exportToDxfIconWhite} style={{width: 16, height: 16}}/>
        </div>
        <div ref={settingsButtonRef} onClick={toggleSettingsVisibility} style={styles.icon} data-tip='tooltip' data-for={'settings-tooltip'}>
          <ReactTooltip id={'settings-tooltip'} effect='solid'>Settings</ReactTooltip>
          <img src={settingsIconWhite} style={{width: 16, height: 16}}/>
        </div>
      </div>
    </>
  );
};

export default connect({
  mapState: state => {
    return {
      elevations: state.resources.elevations.byId,
      project: _.values(state.resources.projects.byId)[0],
      rooms: state.resources.rooms.byId,
      floors: state.resources.floors.byId,
      materialTypes: _.values(state.resources.materialTypes.byId)
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.projects, ['updateProject']),
  }
})(DrawingsPageHudElements);
