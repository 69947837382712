import React, { useContext, useEffect, useState } from 'react';

import { Circle, Rect, Group, Label, Line, Tag, Text, Shape } from 'react-konva';
import { CanvasText, NumericInputContext, CanvasDataContext } from 'canvas';

import PositionHelper from 'helpers/position-helper';
import Product from 'project-helpers/product';
import Container from 'project-helpers/container';

import lib from 'lib';
import _ from 'lodash';
import K from 'k';

const CanvasSettingsGroup = (props) => {
  const {canvasData, position, size, resourceKey, resourceId, resource, ...rest} = props;

  //get settings for resource key and id
  //dont recalculate while transforming

  var settings = [];

  if (resourceKey === 'product') {
    settings = Product.getCanvasSettings({product: resource});
  }

  if (resourceKey === 'container') {
    settings.push(...Container.getCanvasSettings({container: resource}));
  }

  var handleSmallLargeCircleClick = (value) => () => {
    props.onSettingsChange({key: 'size', value: value === 'small' ? 'large' : 'small'});
  };

  var handleRadioClick = (value, key) => () => {
    props.onSettingsChange({key, value: value === 'left' ? 'right' : 'left'});
  };

  var handleToggleClick = (value, key) => () => {
    props.onSettingsChange({key, value: value === 0 ? 1 : 0});
  };

  return (
    <Group position={PositionHelper.toCanvas(position, canvasData)} fill="green">
      {_.map(settings, (setting, index) => {
        var {type, value, key, shape} = setting;

        if (type === 'smallLarge') {
          return (
            <Group key={index} position={{x: 2 * canvasData.scale, y: (size.height - 2) * canvasData.scale}}>
              {
                value === 'small' ? (
                  <Circle strokeWidth={2} stroke={'black'} position={{x: 0.2 * canvasData.scale, y: 0}} radius={0.5 * canvasData.scale} onClick={handleSmallLargeCircleClick('small')}/>
                ) :
                  (
                    <Circle strokeWidth={0.5} stroke={'black'} fill={'black'} position={{x: 0.2 * canvasData.scale, y: 0}} radius={0.8 * canvasData.scale} onClick={handleSmallLargeCircleClick('large')}/>
                  )
              }
            </Group>
          );
        }
        else if (type === 'radio') {
          var {position: shapePosition} = setting;
          shapePosition = lib.object.multiply(shapePosition, canvasData.scale);

          return value === 'left' ? (
            <Shape
              key={`${index}_radio`}
              sceneFunc={(context, shape) => {
                context.beginPath();
                context.moveTo(1 * canvasData.scale, 0);
                context.lineTo(0, 0.5 * canvasData.scale);
                context.lineTo(1 * canvasData.scale, 1 * canvasData.scale);
                context.closePath();
                context.fillStrokeShape(shape);
              }}
              stroke={K.colors.black}
              strokeWidth={0.20 * canvasData.scale}
              origin={{x: 'center', y: 'center'}}
              listening
              onClick={handleRadioClick(value, key)}
              offsetX={0.5 * canvasData.scale}
              offsetY={0.5 * canvasData.scale}
              {...shapePosition}
            />
          ) : (
            <Shape
              key={`${index}_radio`}
              sceneFunc={(context, shape) => {
                context.beginPath();
                context.moveTo(0 * canvasData.scale, 0);
                context.lineTo(1 * canvasData.scale, 0.5 * canvasData.scale);
                context.lineTo(0, 1 * canvasData.scale);
                context.closePath();
                context.fillStrokeShape(shape);
              }}

              stroke={K.colors.black}
              strokeWidth={0.20 * canvasData.scale}
              origin={{x: 'center', y: 'center'}}
              listening
              onClick={handleRadioClick(value, key)}
              offsetX={0.5 * canvasData.scale}
              offsetY={0.5 * canvasData.scale}
              {...shapePosition}
            />
          );
        }
        else if (type === 'toggle') {
          var {position: togglePosition} = setting;

          return (
            shape === 'rect' ? (
              <Rect key={`${index}_toggle`} strokeWidth={0.5} stroke={'black'} fill={value ? 'black' : 'rgb(204, 204, 204)'} position={lib.object.multiply(togglePosition, canvasData.scale)} width={0.75 * canvasData.scale} height={0.75 * canvasData.scale} onClick={handleToggleClick(value, key)}/>
            ) : (
              <Circle key={`${index}_toggle`} strokeWidth={0.5} stroke={'black'} fill={value ? 'black' : 'rgb(204, 204, 204)'} position={lib.object.multiply(togglePosition, canvasData.scale)} radius={1 * canvasData.scale} onClick={handleToggleClick(value, key)}/>
            )
          );
        }
        else {
          return null;
        }
      })}
    </Group>
  );
};

export default function CanvasSettingsGroupWithContext(props) {
  let canvasData = useContext(CanvasDataContext);
  let numericInputData = useContext(NumericInputContext);

  return <CanvasSettingsGroup {...props} {...{canvasData, numericInputData}} />;
}
