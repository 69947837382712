import React, { useState, useEffect, useRef } from 'react';
import Product from 'project-helpers/product';
import DetailsHelper from 'helpers/details-helper';
import useClickOutside from '../hooks/click-outside';

import { PropertyField } from 'components/property-field';

import Dropdown from 'components/dropdown';

import { handleProductPropertyChange } from 'properties-view-data/product-properties-view-helpers';

import xIconBlack from 'assets/x-icon-black.png';
import K from 'k';
import _ from 'lodash';

import ErrorFallback from 'components/error-fallback';
import {withErrorBoundary} from 'react-error-boundary';

export default withErrorBoundary(function CanvasProductDetailsRow(props) {
  var [isExpanded, setIsExpanded] = useState({});
  const expandedDetailRef = useRef(null);

  //HINT only 1 property expanded at one time
  useClickOutside(expandedDetailRef, () => {
    if (_.size(isExpanded) !== 0) {
      setIsExpanded({});
    }
  });

  var {product, detailApplications, containerStyle, onDelete, onTitleClick, viewMode, isSelected, ...restProps} = props;
  var {productType, productOptionInstances} = Product.get(['productType', 'productOptionInstances', 'dependencies'], {product});

  var isManagedProduct = Product.getIsManaged({product}) || (_.get(product, 'managedData.key') === 'autofilledStorage');

  var details = DetailsHelper.getFilteredCleanedOwnedDetailsFor({product});

  var filteredDetailApplications = _.filter(detailApplications, (detailApplication) => {
    return details[detailApplication.key];
  });

  var {compatibleProductOptions} = Product.getCompatibleProductOptions({product, includeAutomanagedOptions: true});

  var productOptionInstancesIds = new Set(_.map(productOptionInstances, 'productOptionId'));

  useEffect(() => {
    var expanded = {};

    _.forEach(detailApplications, (detailApplication) => {
      expanded[detailApplication.key] = false;
    });

    setIsExpanded(expanded);
  }, []);

  var removeProductOption = (productOption) => () => {
    handleProductPropertyChange({activeEntityId: product.id, activeEntity: product, path: `customData.productOptions.${productOption.id}.enabled`, value: 0, resourceActions: restProps});
  };

  var addProductOption = (productOptionId) => {
    handleProductPropertyChange({activeEntityId: product.id, activeEntity: product, path: `customData.productOptions.${productOptionId}.enabled`, value: 1, resourceActions: restProps});
  };

  var handlePropertyFieldOnClick = (detailApplication) => () => {setIsExpanded({[detailApplication.key]: !isExpanded[detailApplication.key]});};

  var enabledOptions = _.filter(compatibleProductOptions, (option) => {
    if (option.isAutoManaged === true) {
      return productOptionInstancesIds.has(option.id);
    }
    else {
      return false;
    }
  });

  var handleTitleOnClick = (product) => () => {
    props.onTitleClick(product);
  };

  enabledOptions = [...enabledOptions, ..._.chain(compatibleProductOptions).filter({isAutoManaged: false}).map(po => productOptionInstancesIds.has(po.id) ? po : null).filter(null).value()];

  var availableOptions = _.chain(compatibleProductOptions).filter({isAutoManaged: false}).map(po => !productOptionInstancesIds.has(po.id) ? po : null).filter(null).value();

  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 10, columnGap: 5, ...(viewMode === 'lite' ? {marginLeft: -39} : {}), ...containerStyle}}>
      <div style={{position: 'relative'}}>
        <div className={'canvas-product-details-row-product-title-section'} style={{display: 'flex', alignItems: 'center', width: 150 + (10 + (K.spacing - 4)) + 230 + 36}}>
          <div className={'canvas-product-details-row-add-option-dropdown'} style={{flexBasis: 120, flexGrow: 0, flexShrink: 0}}>
            {availableOptions.length > 0 && (<Dropdown
              options={_.map(availableOptions, (po) => ({title: po.title, value: po.id}))}
              onChange={(value) => addProductOption(value)}
              disabled={isManagedProduct}
              labelStyle={{fontSize: 12, fontFamily: 'Work Sans', letterSpacing: '0.05rem', opacity: !isManagedProduct ? 1 : 0, cursor: 'pointer'}}
              showCaret={false}
              placeholder={'Add Option'}
              contentContainerStyle={{paddingTop: K.spacing, paddingBottom: K.spacing, paddingLeft: K.spacing, paddingRight: K.spacing}}
              isLiteMode
            />)}
          </div>
          <img className={'canvas-product-details-row-delete-product-icon'} onClick={isManagedProduct ? () => {} : onDelete} src={xIconBlack} style={{width: 10, height: 10, marginRight: K.spacing, ...(isManagedProduct ? {opacity: 0} : {cursor: 'pointer'})}}/>
          <div style={{display: 'flex', cursor: 'pointer'}} onClick={handleTitleOnClick(product)}>
            <div style={{opacity: 0.4, minWidth: 36, fontSize: 12, fontFamily: 'Work Sans', letterSpacing: '0.05em'}}>{product.productionId}</div>
            <div className={'canvas-product-details-row-product-title'} style={{opacity: 0.8, fontSize: 12, fontFamily: 'Work Sans', letterSpacing: '0.05em', width: 250, position: 'realtive', ...(isSelected ? {fontWeight: 'bold'} : {})}}>{productType.title}</div>
          </div>
        </div>
        {enabledOptions.length > 0 && ( <div style={{marginTop: 2, marginLeft: 123}}>
          {_.map(enabledOptions, (productOption, index) => (
            <div
              key={`product-details-row-product-option-${productOption.id}-${index}`}
              className={'product-details-row-product-option'}
              style={{display: 'flex', alignItems: 'center'}}
            >
              {!productOption.isAutoManaged ? (
                <img onClick={removeProductOption(productOption)} className={'product-details-row-option-x-icon'} src={xIconBlack} style={{width: 10, height: 10, marginRight: K.spacing - 3.5}}/>
              ) : (
                <img src={xIconBlack} style={{width: 10, height: 10, marginRight: K.spacing - 3.5, opacity: 0}}/>
              )}
              <span style={{opacity: 0.5, fontSize: 12, fontFamily: 'Work Sans', letterSpacing: '0.05em'}}>{productOption.proposalAlias || productOption.title}</span>
            </div>
          ))}
        </div>
        )}
      </div>
      {_.map(filteredDetailApplications, (detailApplication, index) => {
        return (
          <div key={index} {...(isExpanded[detailApplication.key] ? {ref: expandedDetailRef} : {})}>
            <div className={'custom-tooltip-parent'}>
              <div className={'custom-tooltip-container'}>{_.first(_.split(detailApplication.title, ' '))}</div>
              <PropertyField
                key={`product-details-row-property-field-${detailApplication.key}-${index}`}
                type={'select'}
                path={`details.${detailApplication.key}.id`}
                hideTitleAndValueText
                hasOptionGroups={true}
                isExpanded={isExpanded[detailApplication.key]}
                title={detailApplication.title}
                placeHolder={'Search...'}
                options={detailApplication.options}
                optionGroups={detailApplication.optionGroups}
                details={details}
                activeEntity={product}
                activeEntityId={product.id}
                activeEntityResourceKey={'product'}
                widerOptionsDropdown={true}
                noThumbnail={false}
                context={'editor'}
                isLiteMode
                isEditable={!isManagedProduct}
                onClick={handlePropertyFieldOnClick(detailApplication)}
                {...restProps}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}, {
  FallbackComponent: ErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'product detail row'})
});
