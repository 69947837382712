import React, {useState} from 'react';

import ReactTooltip from 'react-tooltip';

import K from 'k';
import _ from 'lodash';

import questionMark from 'assets/question-mark.png';

const ExpandedPropertyFieldOption = ({onClick, noThumbnail, isEditable, thumbnail, id, value, title, thumbnailStyles, invalid, hasOptionGroups, radioActiveOption, highlightRadioActiveOption, optionGroupTitle, isLiteMode, tooltipBottom = true}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [src, setSrc] = noThumbnail ? [null, null] : thumbnail ? useState(thumbnail) : useState(questionMark);
  const isActive = !highlightRadioActiveOption
    ? false
    : ((_.get(radioActiveOption, 'id') || _.get(radioActiveOption, 'value')) === (id || value));

  var label = optionGroupTitle ? _.replace(title, optionGroupTitle, '') : title;

  const handleOnError = () => setSrc(questionMark);

  return (noThumbnail || !thumbnail) ? (
    <div
      style={{
        ...isActive ? {fontWeight: 'bold'} : {},
        ...hasOptionGroups ? {display: 'flex', alignItems: 'center', marginBottom: K.margin / 2, cursor: 'pointer', opacity: invalid ? 0.3 : 1} : {},
        ...isHovering ? {backgroundColor: 'rgb(245, 245, 245)'} : {}
      }}
      onClick={onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div style={hasOptionGroups ? {left: K.spacing, width: 160, textOverflow: 'ellipsis'} : {}}>{label}</div>
    </div>
  ) : (<div style={{display: 'flex'}}>
    {!isLiteMode && (
      <>
        <ReactTooltip id={`${title}-tooltip`} effect='solid'>
          {label}
        </ReactTooltip>
        <div
          data-tip='tooltip'
          data-for={`${title}-tooltip`}
          onClick={onClick}
          style={{...thumbnailStyles, marginLeft: K.margin, marginBottom: K.margin}}>
          <img
            src={src}
            onError={handleOnError}
            style={{...thumbnailStyles, ...(invalid ? {border: '1px solid red'} : isActive ? {border: '1px solid black'} : {})}}
          />
          {(invalid && !hasOptionGroups) ? (
            <div style={{position: 'absolute', color: 'red'}}>X</div>
          ) : null}
        </div>
      </>
    )}
    {isLiteMode && (
      <>
        <div className={tooltipBottom ? 'custom-tooltip-parent-expanded-property-field-option-tooltip-bottom' : 'custom-tooltip-parent-expanded-property-field-option'} onClick={onClick}>
          <div className={tooltipBottom ? 'custom-tooltip-container-expanded-property-field-option-tooltip-bottom' : 'custom-tooltip-container-expanded-property-field-option'}>{label}</div>
          <div
            style={{...thumbnailStyles, marginLeft: K.margin, marginBottom: K.margin}}>
            <img
              src={src}
              onError={handleOnError}
              style={{...thumbnailStyles, ...(invalid ? {border: '1px solid red'} : isActive ? {border: '1px solid black'} : {})}}
            />
            {(invalid && !hasOptionGroups) ? (
              <div style={{position: 'absolute', color: 'red'}}>X</div>
            ) : null}
          </div>
        </div>
      </>
    )}
  </div>
  );
};

export default ExpandedPropertyFieldOption;
