import React from 'react';
import { resourceActions, connect } from 'redux/index.js';
import DetailsHelper from 'helpers/details-helper';

import K from 'k';
import _ from 'lodash';
import createPlusIcon from '../../../../assets/create-plus-icon-black.png';
import xIconBlack from '../../../../assets/x-icon-black.png';
import checkIconBlack from '../../../../assets/check-icon-black.png';
import Product from 'project-helpers/product';

import { pluralize } from 'inflection';

class ParameterInstanceRow extends React.Component {
  constructor(props) {
    super(props);
    this.instanceTitleRef = React.createRef();

    this.state = {
      hoveredMaterialKey: '',
      wrapInstanceTitle: false
    };
  }

  componentDidMount () {
    if ((this.props.type === 'material') && (this.instanceTitleRef.current.offsetWidth > 60 && this.props.materialKeys.length > 3)) {
      this.setState({wrapInstanceTitle: true});
    }
  }

  render() {
    var {instanceIndex, hoveredInstanceIndex, instance, type, materialKeys, defaultMaterialKeys, instanceType, instanceTypeObject} = this.props;
    var rowTitle = '';

    if (instanceType === 'container') {
      rowTitle = _.upperCase(instanceTypeObject.type);
    }
    if (instanceType === 'product') {
      var product = instanceTypeObject;

      if (product) {
        var productType = Product.get('productType', {product});

        rowTitle = productType.title;
      }
    }

    return (
      <div
        style={{display: 'flex', flexDirection: 'row', marginLeft: K.spacing * 2.5}}
        onMouseOver={() => this.props.handleUpdateHoveredInstanceIndex({instanceIndex})}
        onMouseLeave={() => this.props.handleUpdateHoveredInstanceIndex({instanceIndex: -1})}
      >
        <div
          style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: K.spacing}}
        >
          <div
            style={{
              marginLeft: K.spacing * 1.5, display: 'flex', alignItems: 'center',
              ...(type === 'material' ? {width: 140} : {width: '100%'})
            }}>
              <div
                style={{
                  display: 'flex', flexDirection: 'row', alignItems: 'center',
                  ...(this.state.wrapInstanceTitle ? {flexDirection: 'column'} : {})
                }}
                // onClick={() => set active entity}
              >
                <div
                  ref={this.instanceTitleRef}
                  style={{...this.props.styles.tertiaryHeader, opacity: 0.8, fontWeight: 500, overflow: 'hidden', fontSize: 10}}
                >
                  {rowTitle}
                </div>
                <div
                  style={{
                    opacity: 0.4, marginLeft: K.spacing, fontSize: 10,
                    ...(this.state.wrapInstanceTitle ? {marginLeft: 0, alignSelf: 'flex-start'} : {})
                  }}
                >
                  {`#${instanceType === 'product' ? instanceTypeObject.productionId : instanceTypeObject.id}`}
                </div>
              </div>
            <div style={{...K.defaultIconSize, marginLeft: K.spacing, minWidth: K.defaultIconSize.width}}>
              {hoveredInstanceIndex === instanceIndex && (
                <img
                  style={{...K.defaultIconSize, opacity: 0.7, cursor: 'pointer', opacity: 0.4, zIndex: 10}}
                  onClick={() => this.props.handleDestroyInstance({instance})} src={xIconBlack}
                />
              )}
            </div>
          </div>
          {type === 'material' && (
            <>
              {_.map(materialKeys, materialKey => {
                var materialKeysDefinedOnInstance = _.get(instance, 'materialKeys', []).length > 0;
                var isDefaultChecked = materialKeysDefinedOnInstance ? false : _.includes(defaultMaterialKeys, materialKey);
                var isChecked = materialKeysDefinedOnInstance ? _.includes(_.get(instance, 'materialKeys', []), materialKey) : isDefaultChecked;

                var styles = {
                  checkmark: {
                    opacity: isChecked ? 1 : 0.1,
                  }
                }

                return (
                  <div
                    style={{
                      display: 'flex', flex: 1, justifyContent: 'center',
                      ...styles.checkmark, alignSelf: 'center'
                    }}
                    key={`${instance.id || instance.resourcePersistentId}-${materialKey}`}
                    onClick={() => this.props.handleUpdateInstanceMaterialKeys({materialKey, instance, action: isChecked ? 'remove' : 'add'})}
                    onMouseEnter={() => this.setState({hoveredMaterialKey: materialKey})}
                    onMouseLeave={() => this.setState({hoveredMaterialKey: ''})}
                  >
                    <img
                      src={checkIconBlack} style={{...K.defaultIconSize, cursor: 'pointer'}}
                    />
                  </div>
                )
              })}
            </>
          )}
        </div>
      </div>
    )
  }
}

class InstanceTypeInstances extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      hoveredInstanceIndex: -1,
      hoveredMaterialKeyColumn: ''
    };
  }

  getInstanceTypeObject({instanceType, instance}) {
    var instanceTypeObject;

    if (instanceType === 'container') {
      instanceTypeObject = _.find(this.props.containers, {'persistentId': instance.resourcePersistentId});
    }

    if (instanceType === 'product') {
      instanceTypeObject = _.find(this.props.products, {'persistentId': instance.resourcePersistentId});
    }

    return instanceTypeObject;
  }

  render() {
    var {instanceType, instances, type, defaultMaterialKeys} = this.props;
    var materialKeys = []

    if (type ==='material') {
      _.forEach(instances, instance => {
      var instanceTypeObject = this.getInstanceTypeObject({instanceType, instance});
      var compatibleDetails = [];

      if (instanceTypeObject) {
          var compatibleDetails = _.filter(DetailsHelper.getCompatibleDetailsFor({[instanceType]: instanceTypeObject}), compatibleDetail => {
            var isSubproductDetail = _.split(compatibleDetail.key, '-').length > 1;
            var filteredCompatibleDetailKeys = ['leftScribeMaterial', 'pullMaterial', 'rightScribeMaterial', 'topScribeMaterial'];

            return (
                compatibleDetail.type === 'material'
                && !isSubproductDetail
                && !_.includes(filteredCompatibleDetailKeys, compatibleDetail.key)
            );
          })
      }

      materialKeys.push(..._.map(compatibleDetails, detail => (detail.key)))
      });

      materialKeys = _.compact(_.uniq(materialKeys));
    }

    var filteredInstances = _.filter(instances, instance => (instance.resourceKey === instanceType));

    return (<>
      {(filteredInstances.length !== 0 || (
        this.props.activeEntitiesData.length !== 0
        && _.includes([instanceType], _.get(this.props.activeEntitiesData[0], 'resourceKey'))
      )) && (
        <div style={{minHeight: 50}}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{...this.props.styles.secondaryHeader, fontSize: 13, marginLeft: K.spacing * 4, width: 120}}>{_.upperCase(instanceType)}</div>
            {_.map(materialKeys, (materialKey, index) => (
              <div
                key={`parameter-instance-${materialKey}-${index}`}
                style={{display: 'flex', flex: 1, justifyContent: 'center', fontSize: 10, textWrap: 'wrap', cursor: 'pointer', ...(_.includes(defaultMaterialKeys, materialKey) ? {fontWeight: 'bold', textDecoration: 'underline'} : {})}}
                onMouseEnter={() => this.setState({hoveredMaterialKeyColumn: materialKey})}
                onMouseLeave={() => this.setState({hoveredMaterialKeyColumn: ''})}
                onClick={() => this.props.handleUpdateDefaultMaterialKeys({
                  materialKey,
                  action: _.includes(defaultMaterialKeys, materialKey) ? 'remove' : 'add'
                })}
              >
                {_.upperCase(_.replace(materialKey, 'Material', ''))}
              </div>
            ))}
          </div>
          {_.map(filteredInstances, (instance, instanceIndex) => {
            var instanceTypeObject = this.getInstanceTypeObject({instanceType, instance});

            if (instanceTypeObject) {
              return(<>
                <ParameterInstanceRow
                  key={instance.id || instance.resourcePersistentId}
                  hoveredInstanceIndex={this.state.hoveredInstanceIndex}
                  handleUpdateHoveredInstanceIndex={({instanceIndex})=> this.setState({hoveredInstanceIndex: instanceIndex})}
                  handleUpdateInstanceMaterialKeys={({materialKey, instance, action}) => this.props.handleUpdateInstanceMaterialKeys({materialKey, instance, type, action})}
                  handleDestroyInstance={({instance})=> this.props.handleDestroyInstance({type, instance})}
                  {...{instance, instanceIndex, instanceTypeObject, instanceType, materialKeys, type, defaultMaterialKeys}}
                  products={this.props.products}
                  containers={this.props.containers}
                  styles={this.props.styles}
                />
              </>)
            }
          })}
          <div style={{height: K.spacing * 2, marginBottom: K.spacing * 1.5}}>
            {
              this.props.activeEntitiesData.length !== 0
              && _.includes([instanceType], _.get(this.props.activeEntitiesData[0], 'resourceKey'))
              &&
            (
              <div
                style={{marginLeft: K.spacing * 4, cursor: 'pointer', display: 'flex', flexDirection: 'row', marginTop: K.spacing}}
                onClick={() => this.props.handleAddInstances()}
              >
                <div style={{...K.fonts.label}}>Add</div>
                <img src={createPlusIcon} style={{width: 10, height: 10, marginTop: 2}}/>
              </div>
            )}
          </div>
        </div>
      )}
    </>)
  }
}

class ParameterInstances extends React.Component {

  handleUpdateInstanceMaterialKeys({materialKey, instance, type, action}) {
    var {parameter} = this.props;

    if (type === 'material') {
      var instanceIndex = _.findIndex(parameter.instances, ({resourcePersistentId}) => (resourcePersistentId === instance.resourcePersistentId));
      var updatedInstances = _.cloneDeep(parameter.instances);
      var updatedMaterialKeys = _.get(updatedInstances, `${instanceIndex}.materialKeys`, []);

      if (action === 'add') {
        updatedMaterialKeys.push(materialKey);
      }

      if (action === 'remove') {
        _.remove(updatedMaterialKeys, key => key === materialKey);
      }

      _.set(updatedInstances, `${instanceIndex}.materialKeys`, updatedMaterialKeys);

      this.props.handleParameterChange({instances: updatedInstances});
    }
  }

  render() {
    var instances = [];
    var {type} = this.props.parameter;
    var instanceTypes = ['container', 'product'];

    if (_.includes(['material', 'pull'], type)) {
      instances = this.props.parameter.instances;
    }
    if (type === 'conditionalResources' && this.props.optionIndex !== -1) {
        instances = _.get(this.props.parameter, `options.${this.props.optionIndex}.instances`, []);
    }

    return (<>
      {_.map(instanceTypes, instanceType => (
        <InstanceTypeInstances
          key={instanceType}
          handleAddInstances={() => this.props.handleAddInstances()}
          activeEntities={this.props.activeEntities}
          activeEntitiesData={this.props.activeEntitiesData}
          {...{instances, instanceType, type}}
          handleUpdateInstanceMaterialKeys={({materialKey, instance, action}) => this.handleUpdateInstanceMaterialKeys({materialKey, instance, type, action})}
          handleDestroyInstance={({instance})=> this.props.handleDestroyInstance({type, instance})}
          handleUpdateDefaultMaterialKeys={({materialKey, action}) => this.props.handleUpdateDefaultMaterialKeys({materialKey, action})}
          containers={this.props.containers}
          products={this.props.products}
          defaultMaterialKeys={this.props.defaultMaterialKeys}
          styles={this.props.styles}
        />
      ))}
    </>
    )
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var {activeEntitiesData, archetype} = ownProps;

    var activeEntities = _.map(activeEntitiesData, (activeEntity) => {
      return _.get(state.resources, `[${pluralize(activeEntity.resourceKey)}].byId[${activeEntity.id}]`);
    });

    return {
      project: _.values(state.resources.projects.byId)[0],
      containerTypes: state.resources.containerTypes.byId,
      containers: state.resources.containers.byId,
      productTypes: state.resources.productTypes.byId,
      products: state.resources.products.byId,
      activeEntities,
      activeEntitiesData,
      archetype: _.find(state.resources.archetypes.byId, {id: archetype.id}),
      materialClasses: state.resources.materialClasses.byId,
      materials: state.resources.materials.byId
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.rooms, ['createRoom', 'trackRooms', 'updateRoom', 'destroyRoom', 'destroyRooms']),
    ..._.pick(resourceActions.archetypes, ['updateArchetype', 'destroyArchetype']),
    ..._.pick(resourceActions.products, ['updateProducts', 'modifyProducts']),
    ..._.pick(resourceActions.containers, ['updateContainers', 'modifyContainers']),
  }
})(ParameterInstances);
