import React, { useState, useCallback, useEffect, useRef } from 'react';
import { createSearchParams, useParams, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import Konva from 'konva';

import { connect, resourceActions } from '../../redux/index';
import Drawings from 'project-helpers/drawings';
import K from 'k';
import lib from 'lib';
import loadProjectData from 'helpers/load-project-data';
import Project from 'project-helpers/project';
import useDetectPrint from 'hooks/use-detect-print';

import './drawings-page.scss';
import Page from './drawing-page-types/page';
import Loader from './drawings-components/drawings-loader';
import DrawingsPageHudElements from './drawings-components/drawings-page-hud-elements';
import getDimensionsLayerForDetailLevel from 'helpers/get-dimensions-layer-for-detail-level';

export const detailLevelOptions = {PRODUCTION: 'production', INSTALLATION: 'installation', FULLDETAIL: 'fullDetail', INTERMEDIATE: 'intermediate', SCHEMATIC: 'schematic', RENDERING: 'rendering'};
export const fillModeOptions = {DEFAULT: 'default', MATERIALCOLORS: 'materialColors', MATERIALHATCHES: 'materialHatches', GRAYSCALE: 'grayscale', UNITTYPE: 'unitType'};
export const projectGraphicsLayers = {GENERAL: 'general', ANNOTATIONS: 'annotations', INSTALLATION: 'installationNotes'};
export const imageModeOptions = {RASTER: 'raster', VECTOR: 'vector'};

global.visibilityLayers = {};
global.dimensionsByEntityType = {
  room: {},
  elevation: {}
};

const drawingsCookieKey = 'drawings-settings';
const defaultSettings = {
  editing: true,
  finalApproval: false,
  grid: false,
  imageMode: 'vector',
  fillMode: fillModeOptions.MATERIALHATCHES,
  detailLevel: detailLevelOptions.FULLDETAIL,
  visibilityLayers: {
    dimensions: true,
    bindingDimensions: true,
    grainFlow: true,
    projectGraphics: true,
    projections: false,
    unitLabels: true,
    productTitles: false,
    unitNumbers: false,
    reveals: false,
    scopes: false,
    wallsAndArchElements: true,
    isExportingDxf: false,
    perspective: false,
    quickToggles: false,
    canvasSettingsProductDetails: false
  },
};

const stringToBoolean = (value) => {
  return (/true/i).test(value);
};

//TODO dxf export
//TODO create settings context and reducers
const DrawingsPage = ({
  project, materialTypes,
  ...resourceActionDispatchers
}) => {
  Konva.pixelRatio = 2;
  const drawingsData = _.get(project, 'drawingsData', {});
  const standardPages = _.get(drawingsData, 'standardPages', []);

  const isPrinting = useDetectPrint();
  const {projectId, versionId} = useParams();

  const [isLoaded, setIsLoaded] = useState(false);

  const [isAutogenerating, setIsAutogenerating] = useState(false);

  const projectRef = useRef();

  projectRef.current = project;
  //HINT 4.5 is defaultFontSize
  // const footerHeight = useRef(4.5 * 5 + 1);

  const [settings, setSettings] = useSearchParams();

  const visibilityLayers = _.mapValues(defaultSettings.visibilityLayers, (layer, key) => {
    let value = settings.get(key);

    if (value === undefined || value === null) value = defaultSettings.visibilityLayers[key];

    //HINT: for bindingDimensions: "only" because stringToBoolean("only") = false.
    //it causes a bug when only is set in binding dimensions popup and we check again its value is set to false.
    if (value === "only") {}
    else {
      value = stringToBoolean(value);
    }

    return value;
  });

  const isEditing = stringToBoolean(settings.get('editing'));
  const isFinalApproval = stringToBoolean(settings.get('finalApproval'));
  const detailLevel = (settings.get('detailLevel') && settings.get('detailLevel') !== 'null') ? settings.get('detailLevel') : defaultSettings.detailLevel;
  const imageMode = (settings.get('imageMode') && settings.get('imageMode') !== 'null') ? settings.get('imageMode') : defaultSettings.imageMode;
  const showGrid = stringToBoolean(settings.get('grid'));

  const fillMode = (settings.get('fillMode') && settings.get('fillMode') !== 'null') ? settings.get('fillMode') : defaultSettings.fillMode;
  const showRevealSymbols = !visibilityLayers.isExportingDxf && (detailLevel === detailLevelOptions.PRODUCTION || detailLevel === detailLevelOptions.INSTALLATION);
  const showProjections = !visibilityLayers.isExportingDxf && detailLevel === detailLevelOptions.PRODUCTION;

  visibilityLayers.reveals = showRevealSymbols;
  visibilityLayers.projections = showProjections;

  global.visibilityLayers = visibilityLayers;
  global.drawingsMode = detailLevel;

  useEffect(() => {
    //HINT pull existing settings from cookies
    const cookieSettings = _.pick(_.defaultsDeep(lib.cookie.get({scope: 'cfg', key: drawingsCookieKey}), defaultSettings), _.keys(defaultSettings));

    handleSettingsChange(cookieSettings);

    //HINT set html styles
    document.documentElement.style.fontSize = '80%';
    document.documentElement.style.lineHeight = '1.4';
  }, []);

  useEffect(() => {
    if (isLoaded) {
      const value = getSettingsForCookies(settings);

      lib.cookie.set({scope: 'cfg', key: drawingsCookieKey, value, domain: window.location.href.includes('localhost') ? 'localhost' : 'henrybuilt.com'});
    }
  }, [settings]);

  const handleLoadProjectData = useCallback(async () => {
    await loadProjectData({match: {params: {projectId, versionId}}, resourceActionDispatchers, isDrawings: true});

    //TODO remove setTimeout
    setTimeout(() => {
      setIsLoaded(true);
    });
  }, [projectId, versionId]);

  useEffect(() => {
    if (projectId !== undefined && versionId !== undefined) {
      setIsLoaded(false);

      handleLoadProjectData();
    }
  }, [handleLoadProjectData]);

  useEffect(() => {
    if (isLoaded) {
      if (projectId && !project.issueDate) {
        const issueDate = lib.date.moment().utc().format('YYYY-MM-DD');

        resourceActionDispatchers.updateProject({id: projectId, props: {issueDate}, hitApi: true});
      }

      if (project.companyKey === 'vp') {
        var link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.head.appendChild(link);
        }
        link.href = 'https://s3-us-west-2.amazonaws.com/henrybuilt-cdn/images/st-favicon.ico';
      }

      const shouldAutogenerate = project && projectId && (_.isEmpty(drawingsData) || drawingsData?.continuousDrawingsAutogenerationDisabled === 0);

      if (shouldAutogenerate) {
        var autogenerateStrategy = drawingsData?.autogenerateStrategy;

        if (!autogenerateStrategy) autogenerateStrategy = project.companyKey === 'hb' ? 'overviewsOnly' : 'standard';

        handleAutogenerate({override: false, autogenerateStrategy});
      }

      //HINT set document.title for printing
      document.title = Drawings.getDocumentTitle({project});

      if (project.companyKey === 'vp') handleSettingsChange({unitNumbers: true});
    }
  }, [isLoaded]);

  useEffect(() => { //TODO results in full re-render when standardPages are updated and hitApi is true since the UUIDs are stripped, to fix we should cache UUIDs based on standardPage content/indexes
    if (!_.isEmpty(drawingsData) && drawingsData.standardPages && !_.every(standardPages, 'id')) {
      const {standardPages} = drawingsData;

      const standardPagesWithUUIDs = _.map(standardPages, page => ({id: lib.string.uuid(), ...page}));

      resourceActionDispatchers.updateProject({id: projectId, props: {drawingsData: {...drawingsData, standardPages: standardPagesWithUUIDs}}, hitApi: false});
    }
  }, [standardPages]);

  useEffect(() => {
    if (isLoaded && !visibilityLayers.isExportingDxf) {
      handleSettingsChange({
        dimensions: _.includes(['production', 'fullDetail', 'installation', 'intermediate'], detailLevel),
        projections: _.includes(['production'], detailLevel),
        reveals: _.includes(['production', 'installation'], detailLevel),
        unitNumbers: (_.includes(['production', 'installation'], detailLevel) || (project.companyKey === 'vp' && _.includes(['fullDetail', 'installation', 'intermediate'], detailLevel))),
        grainFlow: _.includes(['production', 'fullDetail', 'installation'], detailLevel),
      });

      global.drawingsMode = detailLevel;
    }
  }, [detailLevel, visibilityLayers.isExportingDxf]);

  function flattenSvgChild(svgChild) {
    if (svgChild.nodeName === 'g') {
      return _.flatten(_.map(_.filter(svgChild.children, (child) => child && child.nodeName !== 'defs'), svgChild => flattenSvgChild(svgChild)));
    }
    else {
      return svgChild;
    }
  }

  useEffect(() => {
    if (visibilityLayers.isExportingDxf) {
      setTimeout(() => {
        var svgs = document.getElementsByTagName('svg');

        // _.flatten(_.map(svgChildren, svgChild => flattenSvgChild(svgChild)));

        var svgStrings = _.map(svgs, (svg) => {
          var svgTags = _.flatten(_.map(_.filter(svg.children, (child) => child && child.nodeName !== 'defs'), (svgChild) => flattenSvgChild(svgChild)));

          return _.join(_.map(svgTags, (svgTag) => svgTag.outerHTML), '\n');
        });

        Drawings.exportToDxf(svgStrings);

        setTimeout(() => {
          setIsExportingDxf(false);

          setTimeout(() => {
            var url = window.location.href;

            url = _.trim(_.replace(url, 'isExportingDxf=true', ''), '&');

            window.location.replace(url);
          });
        }, 1000);
      }, 10000);
    }
  }, [visibilityLayers.isExportingDxf]);

  //HINT takes in an updates Object of the same form as defaultSettings and sets URL search params respectively
  const handleSettingsChange = (updates) => {
    const currentSettings = {
      editing: isEditing,
      finalApproval: isFinalApproval,
      grid: showGrid,
      imageMode,
      detailLevel,
      visibilityLayers,
      fillMode
    };

    const searchParams = createSearchParams(_.omit({...getFlattenedSettings(defaultSettings), ...getFlattenedSettings(currentSettings), ...updates}, ['elevationIndicators']));//_.defaultsDeep(flattenedSettings, getSettingsForCookies(settings)));

    global.visibilityLayers = {
      ...global.visibilityLayers,
      ..._.pick(updates, _.keys(defaultSettings.visibilityLayers))
    };

    setSettings(searchParams);
  };

  const getSettingsForCookies = (settings) => {
    let value = {};

    const visibilityLayerKeys = _.keys(defaultSettings.visibilityLayers);

    _.forEach(_.keys(defaultSettings), key => {
      const val = settings.get(key);

      if (_.includes(visibilityLayerKeys, key)) {
        _.assign(value['visibilityLayers'], {[key]: val});
      }
      else {
        value[key] = val;
      }
    });

    var newSettings = _.defaultsDeep(value, defaultSettings);

    return newSettings;
  };

  const getFlattenedSettings = (settings) => {
    let flattenedSettings = _.defaultsDeep(settings, defaultSettings);

    _.assign(flattenedSettings, flattenedSettings.visibilityLayers);

    return _.omit(flattenedSettings, ['visibilityLayers']);
  };

  var setIsExportingDxf = (isExportingDxf) => {
    handleSettingsChange({isExportingDxf,
      ...(isExportingDxf ? {
        dimensions: false, projections: false, unitNumbers: false, unitLabels: false, productTitles: false, perspective: false, reveals: false, imageMode: 'vector'
      } : {})
    });
  };

  const handleSetFooterHeight = (height) => {
    // footerHeight.current = height;
  };

  const getContainerDimensions = useCallback(() => {
    //HINT based on rem height
    var remFooterHeight = 5 * parseFloat(getComputedStyle(document.documentElement).fontSize);

    return {
      width: 13 * 96, //HINT 13in * 96ppi
      height: (11 * 96) - remFooterHeight////footerHeight.current
    };
  }, []);

  const getScale = () => {
    const maxSizes = Drawings.getMaxSizes({project});
    const {elevation, room} = maxSizes;
    const maxSize = lib.object.max(elevation, room);

    const parentSize = getContainerDimensions();

    const scale = {
      width: (parentSize.width / 2) / (maxSize.width + 100),
      height: (parentSize.height / 2) / (maxSize.height + 87),
    };

    const scaleValue = parseFloat(_.min([scale.width, scale.height]).toFixed(2));

    return scaleValue;
  };

  const handleAutogenerate = async ({override = true, enableContinuousAutogeneration, autogenerateStrategy}) => {
    setIsAutogenerating(true);

    if (!autogenerateStrategy) autogenerateStrategy = drawingsData?.autogenerateStrategy || 'standard';

    await Drawings.autogenerateStandardPages({
      project,
      continuousDrawingsAutogenerationDisabled: !enableContinuousAutogeneration,
      override: override || enableContinuousAutogeneration,
      autogenerateStrategy,
      resourceActionDispatchers,
      containerDimensions: getContainerDimensions()
    });

    setIsAutogenerating(false);
  };

  const handleDrawingsDataChange = async ({pageId, updatedPage}) => {
    const updatedStandardPages = projectRef.current.drawingsData.standardPages.concat();
    const indexOf = _.findIndex(updatedStandardPages, {id: pageId});

    updatedStandardPages.splice(indexOf, 1, updatedPage);

    Drawings.handleUpdateDrawingsData({
      project: projectRef.current,
      standardPages: updatedStandardPages,
      continuousDrawingsAutogenerationDisabled: 1,
      resourceActionDispatchers
    });
  };

  const styles = {
    editButton: {
      height: 20,
      width: 20,
      marginTop: K.spacing
    },
    editingControlsContainer: {
      position: 'fixed',
      top: '2rem',
      left: '2rem',
      padding: '0.5rem',
      zIndex: 3,
      backgroundColor: K.colors.base,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'space-between',
    },
    controls: {
      display: 'flex',
      flexDirection: 'row',
      position: 'absolute',
      bottom: 26,
      left: 13,
      marginRight: K.spacing * 2,
      alignItems: 'center',
      justifyContent: 'center'
    },
    controlsIcon: {
      cursor: 'pointer',
      zIndex: 2,
      bottom: 26,
      marginLeft: K.spacing,
      width: 36,
      height: 36,
      backgroundColor: 'black',
      color: 'white',
      borderRadius: 1000,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    loader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      flex: 1,
    },
    autogenerate: {
      position: 'fixed',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      display: 'flex',
      top: 0, left: 0,
      bottom: 0, right: 0,
      zIndex: 10000,
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  if (!isLoaded || !_.every(standardPages, 'id')) return (<div style={styles.loader}><Loader /></div>);

  const {isEmployee} = project;
  const pages = Drawings.getPageData({standardPages, detailLevel, project, materialTypes});

  //HINT billy made changes on client layer for this version
  var activeDimensionsLayer = getDimensionsLayerForDetailLevel({activeDetailLevel: detailLevel, project});

  const materialFrequencyGroups = Project.getMaterialFrequencyGroups({project, activeFillMode: fillMode});

  var shouldShowGrainFlow = _.includes(['production', 'fullDetail', 'installation'], detailLevel);
  visibilityLayers.grainFlow = shouldShowGrainFlow;
  global.visibilityLayers.grainFlow = shouldShowGrainFlow;

  const leadingPagesCount = pages.length - standardPages.length;

  return (
    <div className={`cfg-drawings-index-view ${detailLevel}-detailLevel`}>
      {isAutogenerating && <div style={styles.autogenerate}>Auto-generating<div className='ellipsis-animation'/></div>}
      {!isPrinting && isEmployee && <DrawingsPageHudElements
        isExportingDxf={visibilityLayers.isExportingDxf}
        {...{
          handleAutogenerate,
          handleSettingsChange,
          detailLevel, visibilityLayers, activeDimensionsLayer, fillMode,
          isFinalApproval, showGrid, imageMode, setIsExportingDxf, leadingPagesCount
        }}
        containerDimensions={getContainerDimensions()}
        isEditing={isEditing && isEmployee}
      />}
      <div className="drawings-content">
        {_.map(pages, (page, i) => {
          const {type, id, isMeasured} = page;

          let conditionalPageProps = {};

          if (type === 'standard') {
            conditionalPageProps = {
              containerDimensions: getContainerDimensions(),
              visibilityLayers, activeDimensionsLayer, fillMode, imageMode,
              notes: project.drawingsData.notes,
              handleUpdateNotes: ({notes}) => {
                Drawings.handleUpdateDrawingsData({project, notes, resourceActionDispatchers})
              }
            };
          }
          else if (type === 'materialsAndAppliances') conditionalPageProps = {showUnitNumbers: visibilityLayers.unitNumbers, isFinalApproval};
          else if (type === 'criticalSchedules') conditionalPageProps = {bindingDimensionsShowing: visibilityLayers.bindingDimensions};
          else if (type === 'cover') {
            conditionalPageProps = {isFinalApproval,
              handleUpdateDrawingsData: ({designerContact}) => {
                Drawings.handleUpdateDrawingsData({
                  project, designerContact, resourceActionDispatchers
                });
              }
            };
          }

          //HINT isMeasured is passed to the first page element that will have a footer
          //     in order to only measure footerHeight once instead of on every page.
          if (isMeasured) {
            conditionalPageProps = {
              ...conditionalPageProps,
              onSetFooterHeight: handleSetFooterHeight,
            };
          }

          if (type === 'imgPage') {
            return <Page
              key={`page-${type}-${id}`}
              {...{id: page.id, title: page.title, type: page.type, url: page.url, page}}
              pageNumber={i + 1}
              pageCount={pages.length}
            />
          }

          return <Page
            {...{type, id, page, detailLevel, fillMode, materialFrequencyGroups}}
            key={`page-${type}-${id}`}
            isEditing={isEditing && isEmployee && !isPrinting}
            pageCount={pages.length}
            pageNumber={i + 1}
            isExportingDxf={visibilityLayers.isExportingDxf}
            handleUpdateDrawingsData={handleDrawingsDataChange}
            {...{...conditionalPageProps}}
          />;
        })}
      </div>
    </div>
  );
};

export default connect({
  mapState: state => {
    return {
      project: _.values(state.resources.projects.byId)[0],
      materialTypes: _.values(state.resources.materialTypes.byId)
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.appliances, ['trackAppliances']),
    ..._.pick(resourceActions.archElements, ['trackArchElements']),
    ..._.pick(resourceActions.archetypes, ['trackArchetypes']),
    ..._.pick(resourceActions.containers, ['trackContainers']),
    ..._.pick(resourceActions.containerTypes, ['trackContainerTypes']),
    ..._.pick(resourceActions.elevations, ['trackElevations']),
    ..._.pick(resourceActions.floors, ['trackFloors']),
    ..._.pick(resourceActions.materialClasses, ['trackMaterialClasses']),
    ..._.pick(resourceActions.materialTypes, ['trackMaterialTypes']),
    ..._.pick(resourceActions.materials, ['trackMaterials']),
    ..._.pick(resourceActions.models, ['trackModels']),
    ..._.pick(resourceActions.pricingRules, ['trackPricingRules']),
    ..._.pick(resourceActions.productCategories, ['trackProductCategories']),
    ..._.pick(resourceActions.productOptions, ['trackProductOptions']),
    ..._.pick(resourceActions.productOptionTypes, ['trackProductOptionTypes']),
    ..._.pick(resourceActions.products, ['trackProducts']),
    ..._.pick(resourceActions.productTypes, ['trackProductTypes']),
    ..._.pick(resourceActions.projectGraphics, ['trackProjectGraphics']),
    ..._.pick(resourceActions.projects, ['trackProjects', 'updateProject']),
    ..._.pick(resourceActions.pulls, ['trackPulls']),
    ..._.pick(resourceActions.rooms, ['trackRooms']),
    ..._.pick(resourceActions.scopes, ['trackScopes']),
    ..._.pick(resourceActions.walls, ['trackWalls']),
    ..._.pick(resourceActions.volumes, ['trackVolumes']),
    ..._.pick(resourceActions.parts, ['trackParts']),
    ..._.pick(resourceActions.media, ['trackMedia']),
  }
})(DrawingsPage);
