import _ from 'lodash';
import lib from 'lib';
import getTextSize from 'helpers/get-text-size';

export default function getProcessedDimensionSets({allPoints, activeLayerDimensionsData, dimensionSets, canvasData}) {
  _.forEach([...dimensionSets], dimensionSet => {
    if (_.some(dimensionSet.targets, 'summarize') && _.filter(dimensionSet.targets, 'summarize').length < dimensionSet.targets.length) {
      dimensionSets.push({
        ...dimensionSet,
        id: dimensionSet.id + '-summary',
        type: 'summary',
        targets: _.map(_.filter(dimensionSet.targets, 'summarize'), ({id, ...target}) => ({...target, id: id + '-summary'}))
      });
    }
  });

  var updateDistancesAndNextEnabledTargets = () => {
    dimensionSets = _.map(dimensionSets, dimensionSet => {
      var {targets, originLine} = dimensionSet;

      targets = _.map(targets, (target, index) => {
        var {positionOnOriginLine} = target;
        var nextEnabledTarget = _.find(targets, (target2, index2) => index2 > index && !target2.positionIsDisabled);

        if (nextEnabledTarget) {
          var nextPositionOnOriginLine = lib.trig.nearestPoint({point: nextEnabledTarget.position, onLine: originLine});
          var distance = lib.trig.distance({fromPoint: positionOnOriginLine, toPoint: nextPositionOnOriginLine});
        }

        if (distance) distance = lib.number.round(distance, {toNearest: 1 / 64});

        var offsetPositionOnOriginLine = positionOnOriginLine;
        var offsetDotPositionOnOriginLine = positionOnOriginLine;
        var offsetNextPositionOnOriginLine = nextPositionOnOriginLine;

        return {...target, distance, nextPositionOnOriginLine, nextEnabledTarget, index, offsetPositionOnOriginLine, offsetDotPositionOnOriginLine, offsetNextPositionOnOriginLine};
      });

      return {...dimensionSet, targets};
    });
  };

  dimensionSets = _.filter(dimensionSets, ({targets}) => targets.length > 0);

  dimensionSets = _.map(dimensionSets, dimensionSet => {
    var {type, alpha, targets, offset} = dimensionSet;

    if (offset === undefined) {
      if (type === 'summary') offset = 22; //34;
      if (type === 'extrudeOutside') offset = 10; //18;
      if (type === 'extrudeLocally') offset = 8;
    }

    var normalizedPositions = _.map(targets, ({position}) => lib.trig.rotate({point: position, byRadians: -alpha}));
    var maxNormalY = _.maxBy(normalizedPositions, 'y').y;

    if (type === 'extrudeOutside' && allPoints.length) {
      var allNormalPoints = _.map(allPoints, point => lib.trig.rotate({point, byRadians: -alpha})); //TODO bring up to optimize perf

      maxNormalY = Math.max(maxNormalY, _.maxBy(allNormalPoints, 'y').y);
    }

    var originPosition = lib.object.sum(lib.trig.rotate({point: {x: 0, y: maxNormalY}, byRadians: alpha}), lib.trig.rotate({point: {x: 0, y: offset}, byRadians: alpha}));
    var originLine = lib.trig.extend({line: {from: originPosition, to: lib.object.sum(originPosition, lib.trig.rotate({point: {x: 1, y: 0}, byRadians: alpha}))}, by: 100000});

    var index = -1;

    targets = _.sortBy(targets, () => {
      index += 1;

      return normalizedPositions[index].x;
    });

    targets = _.map(targets, (target, index) => {
      var positionOnOriginLine = lib.trig.nearestPoint({point: target.position, onLine: originLine});
      var nextTarget = targets[index + 1];

      if (nextTarget) {
        var nextPositionOnOriginLine = lib.trig.nearestPoint({point: nextTarget.position, onLine: originLine});
        var rawDistance = lib.trig.distance({fromPoint: positionOnOriginLine, toPoint: nextPositionOnOriginLine});
      }

      if (rawDistance) rawDistance = lib.number.round(rawDistance, {toNearest: 1 / 64});

      return {...target, ids: [target.id], rawDistance, positionOnOriginLine, nextPositionOnOriginLine, index};
    });

    return {...dimensionSet, type, alpha, targets, offset, originLine, originPosition};
  });

  //consolidate dims with distance 0 into their prev dim
  // dimensionSets = _.filter(dimensionSets, ({targets}) => _.some(targets, target => target.rawDistance > 0));

  dimensionSets = _.map(dimensionSets, dimensionSet => {
    var {targets} = dimensionSet;

    targets = _.reverse([...targets]);

    var targetWithDistance = targets[0]; //last target
    var newTargets = [targetWithDistance];
    var targetIndex = 1;

    while (targetIndex < targets.length) {
      var target = targets[targetIndex];

      if (target.rawDistance) {
        newTargets.push(target);

        targetWithDistance = target;
      }
      else {
        targetWithDistance.ids.push(target.id);
      }

      targetIndex += 1;
    }

    targets = _.reverse(newTargets);

    if (activeLayerDimensionsData) {
      targets = _.map(targets, (target) => {
        var positionIsDisabled = _.some(target.ids, id => activeLayerDimensionsData.disabledPositionIds[id]);
        var lineIsDisabled = activeLayerDimensionsData.disabledLineIds[target.id];

        if (activeLayerDimensionsData.additiveDimensionsEditing && !dimensionSet.isCustomDimension) lineIsDisabled = !lineIsDisabled;

        return {positionIsDisabled, lineIsDisabled, ...target};
      });
    }

    return {...dimensionSet, targets};
  });

  updateDistancesAndNextEnabledTargets();

  _.forEach(dimensionSets, dimensionSet => {
    var {originLine} = dimensionSet;

    if (dimensionSet.type === 'summary') {
      var sourceDimensionSet = _.find(dimensionSets, {id: dimensionSet.id.replace('-summary', '')});

      dimensionSet.targets = _.filter(dimensionSet.targets, (target, targetIndex) => {
        if (target.distance) {
          var sourceTargetIndex = _.findIndex(sourceDimensionSet.targets, {id: target.id.replace('-summary', '')});
          var sourceTarget = sourceDimensionSet.targets[sourceTargetIndex];

          var isLast = targetIndex === dimensionSet.targets.length - 1;
          var isFirst = targetIndex === 0;

          var distanceEquivalent = target.distance === sourceTarget?.distance;
          var prevDistanceEquivalent = dimensionSet.targets[targetIndex - 1]?.distance === sourceDimensionSet.targets[sourceTargetIndex - 1]?.distance;
          var nextDistanceEquivalent = dimensionSet.targets[targetIndex + 1]?.distance === sourceDimensionSet.targets[sourceTargetIndex + 1]?.distance;

          if (distanceEquivalent
            && (
              (isFirst ? true : prevDistanceEquivalent)
            )) {
            return false;
          }
        }
        else {
          var sourceTargetIndex = _.findIndex(sourceDimensionSet.targets, {id: target.id.replace('-summary', '')});
          var sourceTarget = sourceDimensionSet.targets[sourceTargetIndex];

          var prevDistanceEquivalent = dimensionSet.targets[targetIndex - 1]?.distance === sourceDimensionSet.targets[sourceTargetIndex - 1]?.distance;

          if (prevDistanceEquivalent) {
            return false;
          }
        }

        return true;
      });

      dimensionSet.targets = _.map(dimensionSet.targets, (target, index) => {
        var {positionOnOriginLine} = target;
        var nextEnabledTarget = _.find(dimensionSet.targets, (target2, index2) => index2 > index && !target2.positionIsDisabled);

        if (nextEnabledTarget) {
          var nextPositionOnOriginLine = lib.trig.nearestPoint({point: nextEnabledTarget.position, onLine: originLine});
          var distance = lib.trig.distance({fromPoint: positionOnOriginLine, toPoint: nextPositionOnOriginLine});

          distance = lib.number.round(distance, {toNearest: 1 / 64});
        }

        return {...target, distance, nextPositionOnOriginLine, nextEnabledTarget, index, originLine};
      });
    }
  });

  dimensionSets = _.map(dimensionSets, dimensionSet => {
    var {targets, alpha, formatLabel} = dimensionSet;

    var shortScalar = -1;
    var lineIsHorizontal = _.includes([0, 2, 4], Math.round(lib.trig.normalize({radians: alpha}) / Math.PI * 2));

    targets = _.map(targets, (target, index) => {
      var {distance} = target;
      var nextTarget = targets[index + 1];
      var nextEnabledTarget = _.find(targets, (target2, index2) => index2 > index && !target2.positionIsDisabled);
      var label = lib.number.toFraction(Math.abs(lib.number.round(distance, {toNearest: 1/16})));

      if (activeLayerDimensionsData?.shouldHoldTosById[target.id]) label = `HOLD TO ${label}`;
      if (activeLayerDimensionsData?.tolerancesById[target.id] === 'eq') label = 'EQ';

      //HINT get fractionLength now before we modify label
      var fractionLength = label.split(' ')[1]?.length || 0;

      if (formatLabel) label = formatLabel({text: label});

      var isSinkCl = _.includes(label, 'C/L');

      var textSize = getTextSize({text: label, fontSize: 3.5, canvasData});

      var hasFraction = Math.round(distance) !== distance;

      //HINT longer fractions need less size reduction
      if (hasFraction && (Math.abs(distance) > 1 || !lineIsHorizontal)) textSize.width -= fractionLength > 3 ? 0.5 : 1;

      if (isSinkCl) textSize.width -= 4.75;

      var isShort = ((lineIsHorizontal ? textSize.width : 5.5) >= distance);

      if (isShort) {
        shortScalar *= -1;
      }
      else {
        shortScalar = -1;
      }

      var elementShortScalar = shortScalar;

      if (activeLayerDimensionsData?.swapShortDimSideById[target.id]) elementShortScalar *= -1;

      return {...target, textSize, isSinkCl, isShort, label, nextTarget, nextEnabledTarget, shortScalar: elementShortScalar};
    });

    return {...dimensionSet, targets, lineIsHorizontal};
  });

  dimensionSets = _.map(dimensionSets, dimensionSet => {
    var {targets} = dimensionSet;

    targets = _.map(targets, (target, index) => {
      var previousTarget = targets[index - 1];
      var previousEnabledTarget = _.find(_.reverse([...targets]), (target2, index2) => index2 > (targets.length - 1 - index) && !target2.positionIsDisabled);
      var customOffset = !target.lineIsDisabled ? activeLayerDimensionsData?.offsetsById[target.id] : undefined;
      var prevTargetCustomOffset = previousEnabledTarget && (!previousEnabledTarget.lineIsDisabled ? activeLayerDimensionsData?.offsetsById[previousEnabledTarget.id] : undefined);

      var offsetPositionOnOriginLine = target.positionOnOriginLine;
      var offsetDotPositionOnOriginLine = target.positionOnOriginLine;
      var offsetNextPositionOnOriginLine = target.nextPositionOnOriginLine;

      if (customOffset || prevTargetCustomOffset) {
        var yOffset = _.max(_.filter([-customOffset, -prevTargetCustomOffset], value => !isNaN(value))) || 0;

        offsetDotPositionOnOriginLine = lib.object.sum(target.positionOnOriginLine, lib.trig.rotate({point: {x: 0, y: yOffset}, byRadians: dimensionSet.alpha}));
      }

      if (customOffset) {
        offsetPositionOnOriginLine = lib.object.sum(target.positionOnOriginLine, lib.trig.rotate({point: {x: 0, y: -customOffset}, byRadians: dimensionSet.alpha}));
      }

      if (customOffset && target.nextPositionOnOriginLine) {
        offsetNextPositionOnOriginLine = lib.object.sum(target.nextPositionOnOriginLine, lib.trig.rotate({point: {x: 0, y: -customOffset}, byRadians: dimensionSet.alpha}));
      }

      return {...target, offsetPositionOnOriginLine, offsetNextPositionOnOriginLine, previousTarget, previousEnabledTarget, offsetDotPositionOnOriginLine};
    });

    return {...dimensionSet, targets};
  });

  _.forEach(dimensionSets, dimensionSet => {
    if (dimensionSet.type === 'summary') {
      _.forEach(dimensionSet.targets, target => {
        var sourceTarget;

        _.forEach(dimensionSets, dimensionSet => {
          if (!sourceTarget && dimensionSet.type !== 'summary') {
            sourceTarget = _.find(dimensionSet.targets, {id: target.id.replace('-summary', '')});
          }
        });

        if (sourceTarget) target.position = sourceTarget.offsetDotPositionOnOriginLine;
      });
    }
  });

  return dimensionSets;
}
