import React from 'react';

import K from 'k';
import _ from 'lodash';
import Popup from 'components/popup';
import Dropdown from 'components/dropdown';

export default class CopyDimEditsPopup extends React.PureComponent {
  state = {dimTransferFrom: this.props.dimTransferFrom || 'client', dimTransferTo: this.props.dimTransferTo || 'production'};

  handleDropdownValueChange = (key) => (value) => this.setState({[key]: value});

  handleTransferButtonOnClick = async () => {
    var {dimTransferFrom, dimTransferTo} = this.state;

    var updated = await this.props.transferDimensionsDataBetweenLayers({dimTransferFrom, dimTransferTo});

    if (updated) this.props.close();
  };

  render() {
    const {dimTransferFrom, dimTransferTo} = this.state;

    return (
      <Popup style={{minWidth: '20%', display: 'flex'}} wrapperStyle={{position: 'absolute'}} onClose={() => this.props.close()}>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1}}>
          <div style={{marginBottom: K.spacing * 2}}>
            <div style={{marginBottom: K.spacing}}>Copy dim edits from</div>
            <Dropdown contentContainerStyle={{}} hasFixedHeight value={dimTransferFrom} onChange={this.handleDropdownValueChange('dimTransferFrom')} options={[{value: 'client', title: 'Full Design/Intermediate'}, {value: 'production', title: 'Production'}, {value: 'installation', title: 'Installation'}, {value: 'rendering', title: 'Rendering'}, {value: 'schematic', title: 'Schematic'}]}/>
          </div>
          <div style={{marginbottom: K.spacing * 2}}>
            <div style={{marginBottom: K.spacing}}>To</div>
            <Dropdown contentContainerStyle={{}} hasFixedHeight value={dimTransferTo} onChange={this.handleDropdownValueChange('dimTransferTo')} options={[{value: 'client', title: 'Full Design/Intermediate'}, {value: 'production', title: 'Production'}, {value: 'installation', title: 'Installation'}, {value: 'rendering', title: 'Rendering'}, {value: 'schematic', title: 'Schematic'}]}/>
          </div>
          <div style={{borderRadius: 15, border: '1px solid #eee', padding: '2px 10px', alignSelf: 'flex-end', cursor: 'pointer'}} onClick={this.handleTransferButtonOnClick}>Copy</div>
        </div>
      </Popup>
    );
  }
}
