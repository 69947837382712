import React, { Fragment, useContext } from 'react';

import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import PositionHelper from '../helpers/position-helper';

import { CanvasDataContext, CanvasLine, CanvasRect, CanvasPointHandle, CanvasPath, CanvasPortal, CanvasCursorObject, NumericInputContext } from 'canvas';
import { VectorKeyframeTrack } from 'three';

class EditableCanvasLine extends React.PureComponent {
  constructor(props) {
    super(props);

    //TODO in the future, replace this when we implement selectedObjectIds
    this.id = lib.string.uuid();

    this.state = {
      selectedHandleKey: undefined,
      disableSelectionStroke: true,
      shiftMoveModeData: {inShiftMoveMode: false, arrowKeyCode: false, arrowKeyEvent: undefined},
      inAddMode: false,
      addModeFrom: null,
      altKeyPressed: false,
      flipOrientationKeyPressed: false,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('keyup', this.handleKeyUp);

    if ((!this.props.from || !this.props.to) && this.props.canvasData.stage) {
      this.enterAddMode();
    }

    else if (this.props.addModeFrom && this.props.canvasData.stage) {
      this.enterAddMode();

      this.setState({addModeFrom: this.props.addModeFrom});
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keyup', this.handleKeyUp);

    if (this.state.inAddMode) this.exitAddMode();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.loadedAddMode && !this.state.inAddMode && (!this.props.from || !this.props.to) && this.props.canvasData.stage) {
      this.enterAddMode();
    }

    if (!this.state.loadedAddMode && !this.state.inAddMode && (this.props.addModeFrom && this.props.canvasData.stage)) {
      this.enterAddMode();

      this.setState({addModeFrom: this.props.addModeFrom});
    }

    if (!this.state.isDragging && !this.state.isDraggingPoint) {
      this.considerUpdatingState(prevProps, prevState);
    }

    if (
      !_.get(prevProps, 'numericInputData.isNumericInputSubmitted')
        && _.get(this.props, 'numericInputData.isNumericInputSubmitted')
        && _.get(this.props, 'numericInputData.numericInputValue')
    ) {
      if (this.state.inAddMode) {
        this.addPointAtMousePosition();
      }
      else if (this.state.shiftMoveModeData.inShiftMoveMode) {
        this.moveLineAtMousePosition();
      }
    }
  }

  considerUpdatingState = (prevProps, prevState) => {
    var newStateProps = {};

    _.forEach(['from', 'to', 'viewDepth'], stateKey => {
      if (this.state.forceStateUpdate || !_.isEqual(prevProps[stateKey], this.props[stateKey])) {
        newStateProps[stateKey] = this.props[stateKey];
      }
    });

    if (!_.isEmpty(newStateProps)) this.setState({...newStateProps, forceStateUpdate: false});
  };

  enterAddMode = () => {
    var inAddMode = this.state.inAddMode;

    if (!this.state.inAddMode) {
      const {stage} = this.props.canvasData;

      if (stage) {
        stage.on('click', this.handleStageOnClick);

        stage.on('mousemove', this.setCursorPosition);
      }

      if (this.props.canvasData.onEnterAddMode) this.props.canvasData.onEnterAddMode();

      inAddMode = true;

      this.props.numericInputData.toggleNumericInputVisibility((this.state.addModeFrom || this.props.addModeFrom) ? true : false);
    }

    this.setState({loadedAddMode: true, inAddMode});
  };

  exitAddMode = () => {
    if (this.state.inAddMode) {
      const {stage} = this.props.canvasData;

      if (stage) {
        stage.off('click', this.handleStageOnClick);

        stage.off('mousemove', this.setCursorPosition);
      }

      if (this.props.canvasData.onExitAddMode) this.props.canvasData.onExitAddMode();

      if (!this.props.from || !this.props.to) {
        if (this.props.onDelete) this.props.onDelete();
      }

      this.setState({
        inAddMode: false,
        loadedAddMode: false,
        addModeFrom: null,
      });

      this.props.numericInputData.toggleNumericInputVisibility(false);
    }
  };

  enterShiftMoveMode = ({arrowKeyCode, arrowKeyEvent}) => {
    this.setState({shiftMoveModeData: {inShiftMoveMode: true, arrowKeyCode, arrowKeyEvent}});
    this.props.numericInputData.toggleNumericInputVisibility(true);
  };

  exitShiftMoveMode = () => {
    this.setState({shiftMoveModeData: {inShiftMoveMode: false, arrowKeyCode: null, arrowKeyEvent: undefined}});
    this.props.numericInputData.toggleNumericInputVisibility(false);
  };

  setCursorPosition = (event) => {
    const {canvasData} = this.props;
    const {getLastMousePosition} = canvasData;

    if (getLastMousePosition) {
      this.setState({
        cursorPosition: getLastMousePosition(),
      });
    }
  };

  handleStageOnClick = (event) => {
    return this.addPointAtMousePosition({event});
  };

  moveLineAtMousePosition = () => {
    const {numericInputValue, isNumericInputSubmitted, disableIsNumericInputSubmitted, toggleNumericInputVisibility} = this.props.numericInputData;
    var {arrowKeyCode, arrowKeyEvent} = this.state.shiftMoveModeData;
    var {selectedHandleKey} = this.state;

    if (isNumericInputSubmitted) {
      this.setState({shiftMoveModeData: {inShiftMoveMode: false, arrowKeyCode: null, arrowKeyEvent: undefined}});
      disableIsNumericInputSubmitted();
      toggleNumericInputVisibility(false);
    }

    let numericInput;
    if (numericInputValue) {
      numericInput = numericInputValue;
    }

    var transform;
    var transformAmount = numericInput || 1;

    if (arrowKeyCode === 'right') transform = {x: 1, y: 0};
    else if (arrowKeyCode === 'up') transform = {x: 0, y: -1};
    else if (arrowKeyCode === 'left') transform = {x: -1, y: 0};
    else if (arrowKeyCode === 'down') transform = {x: 0, y: 1};

    transformAmount = lib.object.multiply(transform, transformAmount);

    if (lib.event.keyPressed(arrowKeyEvent, 'ctrlcmd') && transformAmount) {
      var alpha = this.props.from && this.props.to ? lib.trig.alpha({p1: this.props.from, p2: this.props.to}) : 0;

      transformAmount = lib.trig.rotate({point: transformAmount, byRadians: alpha});
    }

    if (selectedHandleKey) {
      this.handlePointTransform(transform, transformAmount);
    }
    else {
      var props = _.cloneDeep(_.pick(this.props, ['from', 'to', 'viewDepth']));

      props.from = lib.object.sum(props.from, transformAmount);
      props.to = lib.object.sum(props.to, transformAmount);

      if (this.props.onTransformEnd) this.props.onTransformEnd(props);
    }
  };

  //TODO implement numeric input
  addPointAtMousePosition = () => {
    const {numericInputValue, isNumericInputSubmitted, disableIsNumericInputSubmitted, toggleNumericInputVisibility} = this.props.numericInputData;

    if (isNumericInputSubmitted) {
      disableIsNumericInputSubmitted();
    }

    let numericInput;
    if (numericInputValue) {
      numericInput = numericInputValue;
    }

    const {canvasData, offset} = this.props;
    const {getLastMousePosition, getSnapData, orthoMode} = canvasData;
    var {candidateSnapPositions} = getSnapData();
    const pointPositionInCanvas = getLastMousePosition();

    if (this.state.addModeFrom && this.state.position) {
      candidateSnapPositions = [...candidateSnapPositions, lib.object.sum(this.state.addModeFrom, this.state.position, this.props.offset)];
    }

    const pointPosition = PositionHelper.toReal(pointPositionInCanvas, canvasData);
    const snappedDelta = {x: 0, y: 0};
    let lastPosition = _.clone(pointPosition);
    const {position: snappedPosition, snapped} = PositionHelper.snap({snapPositions: candidateSnapPositions, snapLines: [], lastPosition, position: pointPosition, orthoMode}, canvasData);
    const snappedPointDelta = lib.object.difference(snappedPosition, lastPosition);

    //HINT It's important that x and y are snapped independently because some points might cause difference snaps than others
    if (snapped.x !== undefined) {
      snappedDelta.x = snappedPointDelta.x;
    }
    if (snapped.y !== undefined) {
      snappedDelta.y = snappedPointDelta.y;
    }

    var position = lib.object.sum(lib.object.difference(pointPosition, offset), snappedDelta);

    if (numericInput) {
      lastPosition = this.state.addModeFrom;

      const alpha = lib.trig.alpha({p1: lastPosition, p2: position});

      position = lib.object.sum(lastPosition, lib.trig.rotate({
        point: {x: numericInput, y: 0},
        byRadians: alpha
      }));
    }

    if (this.state.altKeyPressed) {
      this.setState({addModeFrom: position});
      this.props.altClick(position);
    }
    else {
      if (!this.props.isSelected) {
        this.handleSelect();
      }

      if (!this.state.addModeFrom) {
        this.setState({addModeFrom: position});
        this.props.numericInputData.toggleNumericInputVisibility(true);
      }
      else {
        if (this.props.onTransformEnd) this.props.onTransformEnd({from: this.state.addModeFrom, to: position, flipOrientation: this.state.flipOrientationKeyPressed});

        this.exitAddMode();
      }
    }
  };

  addPointPosition = ({numericInput, fromInCanvas} = {}) => {
    const {canvasData} = this.props;
    const {getLastMousePosition, getSnapData, isShifting: orthoMode} = canvasData;
    var {candidateSnapPositions} = getSnapData();

    if (this.state.cursorPosition && this.props.from) {
      candidateSnapPositions = [...candidateSnapPositions, ..._.map([this.state.cursorPosition, fromInCanvas], point => lib.object.sum(point, this.state.cursorPosition, this.props.offset))];
    }

    let lastPosition, position = getLastMousePosition();

    if (!position && !numericInput) return {x: 0, y: 0};

    const pointPosition = _.mapValues(PositionHelper.toReal(position, canvasData), value => {
      return lib.number.round(value, {toNearest: canvasData.precision});
    });

    const snappedDelta = {x: 0, y: 0};

    lastPosition = _.clone(pointPosition);

    const {position: snappedPosition, snapped} = PositionHelper.snap({snapPositions: candidateSnapPositions, snapLines: [], lastPosition: lib.object.sum(this.state.addModeFrom, this.state.position, this.props.offset), position: pointPosition, orthoMode}, canvasData);
    const snappedPointDelta = lib.object.difference(snappedPosition, lastPosition);

    //HINT It's important that x and y are snapped independently because some points might cause difference snaps than others
    if (snapped.x !== undefined) {
      snappedDelta.x = snappedPointDelta.x;
    }
    if (snapped.y !== undefined) {
      snappedDelta.y = snappedPointDelta.y;
    }

    position = PositionHelper.toCanvas(lib.object.sum(pointPosition, snappedDelta), canvasData);

    if (numericInput) {
      if (this.state.points.length > 0) lastPosition = PositionHelper.toCanvas(lib.object.sum(_.last(this.state.points), this.state.cursorPosition, this.props.offset), canvasData);

      const alpha = lib.trig.alpha({p1: lastPosition, p2: position});

      const numericInputPosition = lib.object.sum(lastPosition, lib.trig.rotate({
        point: {x: numericInput * canvasData.scale, y: 0},
        byRadians: alpha
      }));

      return numericInputPosition;
    }

    return position;
  };

  handleSelect = () => {
    if (this.props.onSelect) this.props.onSelect();
  };

  handlePointTransform = (transform, transformAmount) => {
    var {selectedHandleKey} = this.state;
    var props = _.cloneDeep(_.pick(this.props, ['from', 'to', 'viewDepth']));

    if (selectedHandleKey === 'viewDepth') {
      var rotation = lib.trig.radiansToDegrees(lib.trig.alpha({p1: props.to, p2: props.from}));

      props.viewDepth += (transform.x ? -1 : 1) * lib.trig.rotate({point: transformAmount, byDegrees: rotation}).y;

      if (Math.abs(props.viewDepth) < 1) props.viewDepth = 0;
    }
    else {
      props[selectedHandleKey] = {...props[selectedHandleKey], ...lib.object.sum(props[selectedHandleKey], transformAmount)};
    }

    if (this.props.onTransformEnd) this.props.onTransformEnd(props);
  };

  handleKeyDown = (event) => {
    var arrowKeyEvent = event;
    var arrowKeyCode = lib.event.arrowKeyCode(event);
    var {selectedHandleKey} = this.state;

    if (lib.event.keyPressed(event, 'alt')) this.setState({altKeyPressed: true});
    if (event.key === 'f') this.setState({flipOrientationKeyPressed: true});

    if (this.props.isSelected && !this.props.isDisabled && lib.event.keyPressed(event, 'delete') && document.activeElement.tagName === 'BODY') {
      this.handleDelete();
    }
    else if ((this.state.shiftMoveModeData.inShiftMoveMode || this.state.inAddMode) && lib.event.keyPressed(event, 'esc')) {
      this.exitAddMode();
      this.exitShiftMoveMode();
      this.props.onClose();
    }
    else if (arrowKeyCode && !this.state.inAddMode && this.props.isSelected && !this.props.isDisabled && document.activeElement.tagName === 'BODY') {
      var transform;

      if (arrowKeyCode === 'right') transform = {x: 1, y: 0};
      else if (arrowKeyCode === 'left') transform = {x: -1, y: 0};
      else if (arrowKeyCode === 'up') transform = {x: 0, y: -1};
      else if (arrowKeyCode === 'down') transform = {x: 0, y: 1};

      var transformAmount = lib.object.multiply(transform, this.props.canvasData.precision);

      if (lib.event.keyPressed(event, 'shift') && (!this.props.isTextPointer || selectedHandleKey)) {
        this.enterShiftMoveMode({arrowKeyCode, arrowKeyEvent});
      }

      if (!lib.event.keyPressed(event, 'shift')) {
        if (lib.event.keyPressed(arrowKeyEvent, 'ctrlcmd') && transformAmount) {
          arrowKeyEvent.preventDefault();

          var alpha = this.props.from && this.props.to ? lib.trig.alpha({p1: this.props.from, p2: this.props.to}) : 0;

          transformAmount = lib.trig.rotate({point: transformAmount, byRadians: alpha});
        }

        if (selectedHandleKey) {
          this.handlePointTransform(transform, transformAmount);
        }
        else if (!this.props.isTextPointer) {
          var props = _.cloneDeep(_.pick(this.props, ['from', 'to', 'viewDepth']));

          props.from = lib.object.sum(props.from, transformAmount);
          props.to = lib.object.sum(props.to, transformAmount);

          if (this.props.onTransformEnd) this.props.onTransformEnd(props);
        }
      }
    }
  };

  handleKeyUp = (event) => {
    if (lib.event.keyPressed(event, 'alt')) this.setState({altKeyPressed: false});
    if (event.key === 'f') this.setState({flipOrientationKeyPressed: false});
  };

  handleClick = (event) => {
    if (this.props.canvasData.isPressingCtrlCmd && this.props.isSelected) {
      this.handleToggleDisabled();
    }

    if (event.evt.button === 0 && this.props.onClick) this.props.onClick();
  };

  handleDelete() {
    if (this.props.onDelete) this.props.onDelete();
  }

  handleToggleDisabled() {
    if (this.props.onDisabledChange) this.props.onDisabledChange(!this.props.isDisabled);
  }

  handlePointClick = (type) => {
    if (this.state.inAddMode && type === 'to' && this.props.from && this.props.to) {
      this.close();
    }
  };

  handleSelectPoint = (type) => {
    if (!this.state.inAddMode) {
      this.setState({selectedHandleKey: type});

      if (this.props.onSelectedHandleKeyChange) this.props.onSelectedHandleKeyChange(type);
    }
  };

  handleDeselectPoint = () => {
    this.setState({selectedHandleKey: undefined});

    if (this.props.onSelectedHandleKeyChange) this.props.onSelectedHandleKeyChange(undefined);
  };

  handleMoveStartPoint = () => {
    this.setState({
      isDraggingPoint: true,
      from: _.clone(this.props.from),
      to: _.clone(this.props.to),
      cachedFrom: _.clone(this.props.from),
      cachedTo: _.clone(this.props.to),
      viewDepth: this.props.viewDepth,
    });
  };

  handleViewDepthMove = (event) => {
    const {canvasData, centerDepthHandle, offset} = this.props;
    var {getSnapData} = canvasData;
    let {from, to, viewDepth} = this.state;
    if (!from) from = this.props.from;
    if (!to) to = this.props.to;

    var {candidateSnapPositions = []} = getSnapData();
    //HINT adds sibling points to snap candidates
    candidateSnapPositions = [...candidateSnapPositions, lib.object.sum(this.state.to, offset), lib.object.sum(this.state.from, offset)];

    const fromInCanvas = PositionHelper.toCanvas(lib.object.sum(from, this.props.offset), canvasData);
    const toInCanvas = PositionHelper.toCanvas(lib.object.sum(to, this.props.offset), canvasData);
    const line = {from, to};
    const lineInCanvas = lib.trig.extend({line: {from: fromInCanvas, to: toInCanvas}});

    const viewDepthInCanvas = viewDepth * canvasData.scale;

    let viewDepthPoint = lib.trig.extend({line: {from: fromInCanvas, to: toInCanvas}, by: 10}).from;

    if (centerDepthHandle) {
      viewDepthPoint = lib.math.midpoint({p1: fromInCanvas, p2: toInCanvas});
    }

    viewDepthPoint = lib.object.sum(viewDepthPoint, lib.trig.rotate({point: {x: 0, y: viewDepthInCanvas}, byRadians: lib.trig.alpha({p1: to, p2: from, perpendicularAlpha: true})}));

    const newPosition = {x: event.target.x(), y: event.target.y()};
    var unsnappedSwitchedSides = lib.math.linesIntersect({l1: lineInCanvas, l2: {from: newPosition, to: viewDepthPoint}});
    var unsnappedNewViewDepth = lib.number.round(lib.trig.distance({fromPoint: lib.object.difference(PositionHelper.toReal(newPosition, canvasData), this.props.offset), toLine: lib.trig.extend({line})}), {toNearest: K.precision});

    //HINT control Konva position while moving the viewDepth handle
    const newViewDepthInCanvas = unsnappedNewViewDepth * canvasData.scale;

    let newViewDepthPoint = lib.trig.extend({line: {from: fromInCanvas, to: toInCanvas}, by: 10}).from;

    if (centerDepthHandle) {
      newViewDepthPoint = lib.math.midpoint({p1: fromInCanvas, p2: toInCanvas});
    }

    newViewDepthPoint = lib.object.sum(newViewDepthPoint, lib.trig.rotate({point: {x: 0, y: newViewDepthInCanvas}, byRadians: lib.trig.alpha({p1: to, p2: from, perpendicularAlpha: true})}));

    const snappedDelta = {x: 0, y: 0};
    const lastPosition = lib.trig.translate({point: PositionHelper.toReal(viewDepthPoint, canvasData), by: 1, alpha: lib.trig.alpha({p1: to, p2: from, perpendicular: true})});
    var {position: snappedPosition, snapped, snapData} = PositionHelper.snap({snapPositions: candidateSnapPositions, snapLines: [], lastPosition, position: PositionHelper.toReal(newViewDepthPoint, canvasData), orthoMode: true}, canvasData);

    const snappedPointDelta = lib.object.difference(snappedPosition, _.clone(PositionHelper.toReal(newViewDepthPoint, canvasData)));

    //HINT It's important that x and y are snapped independently because some points might cause difference snaps than others
    if (snapped.x !== undefined) {
      snappedDelta.x = snappedPointDelta.x;
    }
    if (snapped.y !== undefined) {
      snappedDelta.y = snappedPointDelta.y;
    }

    newViewDepthPoint = lib.object.sum(newViewDepthPoint, lib.object.multiply(snappedDelta, canvasData.scale));

    var newViewDepth = lib.number.round(lib.trig.distance({fromPoint: lib.object.difference(PositionHelper.toReal(newViewDepthPoint, canvasData), this.props.offset), toLine: lib.trig.extend({line})}), {toNearest: K.precision});

    event.target.x(newViewDepthPoint.x);
    event.target.y(newViewDepthPoint.y);

    //HINT Update state with newViewDepth
    let props = {viewDepth: newViewDepth};

    //HINT aims to see of the position has moved across the line since move start or last moving across the line
    if (unsnappedSwitchedSides && newViewDepth > 1) {
      props = {...props, from: to, to: from};
    }

    this.setState(props);

    if (this.props.onTransform) this.props.onTransform(props);
  };

  handleMovePoint = (type) => (event) => {
    const {canvasData, offset} = this.props;
    const {getSnapData, orthoMode} = canvasData;
    const newPosition = {x: event.target.x(), y: event.target.y()};
    const pointPosition = PositionHelper.toReal(newPosition, canvasData);
    var {candidateSnapPositions = []} = getSnapData();

    //HINT adds sibling points to snap candidates
    candidateSnapPositions = [...candidateSnapPositions, lib.object.sum(type === 'from' ? this.state.to : this.state.from, offset)];

    const snappedDelta = {x: 0, y: 0};
    const lastPosition = lib.object.sum(offset, this.state[`cached${type === 'from' ? 'To' : 'From'}`]);
    var {position: snappedPosition, snapped} = PositionHelper.snap({snapPositions: candidateSnapPositions, snapLines: [], lastPosition, position: pointPosition, orthoMode}, canvasData);

    var {cachedFrom, cachedTo} = this.state;

    var offsetCachedFrom = lib.object.sum(cachedFrom, offset);
    var offsetCachedTo = lib.object.sum(cachedTo, offset);

    //HINT when non-90 snap to the current line to maintain angle
    //HINT when at 90, the snap ortho logic already works
    //and is better because it also snaps to perpendicular
    if (orthoMode && lib.trig.alpha({p1: offsetCachedFrom, p2: offsetCachedTo}) % (Math.PI / 2) !== 0) {
      var {cachedFrom, cachedTo} = this.state;

      var offsetCachedFrom = lib.object.sum(cachedFrom, offset);
      var offsetCachedTo = lib.object.sum(cachedTo, offset);

      //TODO also snap to perpendicular line
      var nearestLine = {
        from: lib.trig.extend({line: {from: offsetCachedFrom, to: offsetCachedTo}, rangeKey: 'from'}).from,
        to: lib.trig.extend({line: {from: offsetCachedFrom, to: offsetCachedTo}, rangeKey: 'to'}).to,
      };

      let nearestPoint = lib.trig.nearestPoint({point: pointPosition, onLine: nearestLine});

      snappedPosition = nearestPoint;
      snapped = {x: true, y: true};
    }

    const snappedPointDelta = lib.object.difference(snappedPosition, _.clone(pointPosition));

    //HINT It's important that x and y are snapped independently because some points might cause difference snaps than others
    if (snapped.x !== undefined) {
      snappedDelta.x = snappedPointDelta.x;
    }
    if (snapped.y !== undefined) {
      snappedDelta.y = snappedPointDelta.y;
    }

    const position = lib.object.sum(lib.object.difference(pointPosition, offset), snappedDelta);

    //HINT control Konva positioning while snapping
    if (!_.isEqual(position, pointPosition)) {
      const positionInCanvas = PositionHelper.toCanvas(lib.object.sum(position, offset), canvasData);
      event.target.x(positionInCanvas.x);
      event.target.y(positionInCanvas.y);
    }

    const props = {};

    props[type] = position;

    this.setState(props);

    if (this.props.onTransform) this.props.onTransform(props);
  };

  handleMoveEndPoint = () => {
    const {from, to, viewDepth} = this.state;

    const props = {from, to};

    if (_.isFinite(viewDepth)) {
      props.viewDepth = viewDepth;
    }

    this.setState({
      isDraggingPoint: false,
      from: undefined,
      to: undefined,
      viewDepth: undefined,
      cachedFrom: undefined,
      cachedTo: undefined
    });

    if (this.props.onTransformEnd) this.props.onTransformEnd(props);
  };

  handleDragStart = (event) => {
    if (!this.state.isDraggingPoint) {
      this.setState({
        isDragging: true,
        initialPosition: {x: event.target.x(), y: event.target.y()},
        cachedPositions: [lib.object.sum(this.props.from, this.props.offset), lib.object.sum(this.props.to, this.props.offset)],
      });
    }
  };

  handleDragMove = (event) => {
    if (!this.state.isDraggingPoint) {
      const {canvasData} = this.props;
      const {cachedPositions} = this.state;
      const {getSnapData, orthoMode} = canvasData;

      const {candidateSnapPositions = []} = getSnapData();

      let newPosition = {x: event.target.x(), y: event.target.y()};

      const deltaInCanvas = lib.object.difference(newPosition, this.state.initialPosition);

      const deltaInReal = _.mapValues(deltaInCanvas, value => {
        return lib.number.round(value / canvasData.scale, {toNearest: canvasData.precision});
      });

      const snappedDelta = deltaInReal;
      const leastSnapDistance = {x: Number.MAX_SAFE_INTEGER, y: Number.MAX_SAFE_INTEGER};

      //Iterate through points and check if any of them snap
      //If so, update the transform to be the snapped transform
      cachedPositions.forEach(pointPosition => {
        const lastPosition = _.clone(pointPosition);

        pointPosition = lib.object.sum(pointPosition, deltaInReal);

        //HINT ortholock doesn't really make sense while moving
        const {position: snappedPosition, snapped, snapData} = PositionHelper.snap({snapPositions: candidateSnapPositions, snapLines: [], lastPosition, position: pointPosition, orthoMode: false}, canvasData);
        const snappedPointDelta = lib.object.difference(snappedPosition, lastPosition);

        //HINT It's important that x and y are snapped independently because some points might cause difference snaps than others
        if (snapped.x !== undefined && (snapData.x.candidateData?.distance < leastSnapDistance.x || orthoMode)) {
          leastSnapDistance.x = orthoMode ? Number.MAX_SAFE_INTEGER : snapData.x.candidateData.distance;
          snappedDelta.x = snappedPointDelta.x;
        }
        if (snapped.y !== undefined && (snapData.y.candidateData?.distance < leastSnapDistance.y || orthoMode)) {
          leastSnapDistance.y = orthoMode ? Number.MAX_SAFE_INTEGER : snapData.y.candidateData.distance;
          snappedDelta.y = snappedPointDelta.y;
        }
      });

      const snappedDeltaInCanvas = _.mapValues(snappedDelta, value => {
        return lib.number.round(value * canvasData.scale, {toNearest: canvasData.precision});
      });

      newPosition = lib.object.sum(this.state.initialPosition, snappedDeltaInCanvas);

      const from = lib.object.sum(this.props.from, snappedDelta);
      const to = lib.object.sum(this.props.to, snappedDelta);

      this.setState({
        position: newPosition,
        from,
        to,
      });

      if (this.props.onTransform) this.props.onTransform({from, to});
    }
  };

  handleDragEnd = () => {
    if (!this.state.isDraggingPoint) {
      const {from, to} = this.state;

      this.setState({
        isDragging: false,
        initialPosition: undefined,
        position: undefined,
      });

      if (this.props.onTransformEnd) this.props.onTransformEnd({from, to});
    }
  };

  render() {
    const {isSelected, isDisabled, isEditable, hideLine, centerDepthHandle, canvasData, stroke, strokeDashArray, opacity = 1, offset = {x: 0, y: 0}, showToArrow, isTextPointer, preventOrthoIndication, portalSelector} = this.props;
    const {inAddMode, addModeFrom, position = {x: 0, y: 0}} = this.state;

    const from = lib.object.sum(_.defaultsDeep(this.state.from, this.props.from), offset);
    const to = lib.object.sum(_.defaultsDeep(this.state.to, this.props.to), offset);
    const viewDepth = _.isFinite(this.state.viewDepth) ? this.state.viewDepth : this.props.viewDepth;

    const isFromValid = !_.isEmpty(this.props.from);
    const isToValid = !_.isEmpty(this.props.to);

    let fromInCanvas;
    let toInCanvas;

    const offsetInCanvas = lib.object.multiply(offset, canvasData.scale);
    const handles = [];

    var strokeWidth = (this.props.strokeWidth || 1) * (this.props.shouldScaleStrokeWidth ? this.props.canvasData.scale : 1);
    if (isFromValid) {
      fromInCanvas = PositionHelper.toCanvas(from, canvasData);

      handles.push({...fromInCanvas, id: 'from'});
    }
    else if (addModeFrom && !isTextPointer) {
      fromInCanvas = PositionHelper.toCanvas(addModeFrom, canvasData);

      handles.push({...fromInCanvas, id: 'from'});
    }
    if (isToValid) {
      toInCanvas = PositionHelper.toCanvas(to, canvasData);

      handles.push({...toInCanvas, id: 'to'});
    }

    if (isTextPointer) _.remove(handles, handle => handle.id === 'from');

    const isViewDepthVisible = isFromValid && isToValid && isSelected && _.isFinite(viewDepth);

    var isRightAngle = lib.trig.alpha({p1: fromInCanvas, p2: toInCanvas}) % (Math.PI / 2) === 0;

    let viewDepthComponent;

    if (isViewDepthVisible) {
      const viewDepthInCanvas = viewDepth * canvasData.scale;
      const rotation = lib.trig.radiansToDegrees(lib.trig.alpha({p1: to, p2: from}));

      let viewDepthPoint = lib.trig.extend({line: {from: fromInCanvas, to: toInCanvas}, by: 10}).from;

      if (centerDepthHandle) {
        viewDepthPoint = lib.math.midpoint({p1: fromInCanvas, p2: toInCanvas});
      }

      viewDepthPoint = lib.object.sum(viewDepthPoint, lib.trig.rotate({point: {x: 0, y: viewDepthInCanvas}, byRadians: lib.trig.alpha({p1: to, p2: from, perpendicularAlpha: true})}));

      const viewDepthRectProps = {
        width: lib.trig.dist(fromInCanvas, toInCanvas),
        height: viewDepthInCanvas,
        rotation,
        position: toInCanvas,
      };

      viewDepthComponent = (<>
        {viewDepth > 0 && !hideLine && <CanvasRect {...viewDepthRectProps}
          stroke={'transparent'} fill={(isSelected && !isRightAngle && !preventOrthoIndication) ? '#aa6eba' : stroke} opacity={0.2} listening={false}
        />}
        <CanvasPointHandle
          {...{point: viewDepthPoint}}
          radius={4}
          isEnabled={!isDisabled}
          isSelected={this.state.selectedHandleKey === 'viewDepth'}
          onSelect={() => this.handleSelectPoint('viewDepth')}
          onDeselect={() => this.handleDeselectPoint('viewDepth')}
          onMove={this.handleViewDepthMove}
          onMoveStart={this.handleMoveStartPoint}
          onMoveEnd={this.handleMoveEndPoint}
          onClick={() => this.handlePointClick('viewDepth')}
          listening={!isDisabled}
        />
      </>);
    }

    let toArrowComponent;

    if (showToArrow) {
      toArrowComponent = (
        <CanvasPath {...{
          ...toInCanvas,
          width: canvasData.scale,
          height: canvasData.scale,
          data: 'M 0 0 L 4 -3 L 4 3 Z',
          fill: stroke || 'black',
          stroke: stroke || 'black',
          strokeWidth,
          opacity: 1,
          listening: false,
          rotation: lib.trig.radiansToDegrees(lib.trig.alpha({p1: to, p2: from})),
        }}/>
      );
    }

    let addPointLine;
    let addPointCursor;

    if (inAddMode) {
      const {cursorPosition} = this.state;

      addPointCursor = <CanvasCursorObject {...{lastMousePosition: cursorPosition, siblingPoints: [lib.object.sum(addModeFrom, position, offset)]}}/>;

      if (cursorPosition && addModeFrom) {
        const pointLine = {from: fromInCanvas, to: lib.object.difference(cursorPosition, offsetInCanvas)};

        addPointLine =
          <CanvasLine
            {...{stroke, strokeWidth}}
            position={position}
            listening={false}
            points={[pointLine.from.x - 0.5, pointLine.from.y - 0.5, pointLine.to.x - 0.5, pointLine.to.y - 0.5]}
          />;
      }
    }

    return (
      <CanvasPortal {...{portalSelector, shouldUsePortal: portalSelector ? true : false}} >
        {addPointLine}
        {addPointCursor}
        {toArrowComponent}
        {!hideLine && (
          <>
            {isFromValid && isToValid && <CanvasLine
              {...{from: lib.object.difference(fromInCanvas, position), to: lib.object.difference(toInCanvas, position), stroke, strokeWidth, opacity, dashEnabled: strokeDashArray && strokeDashArray.length, dash: strokeDashArray}}
              position={position}
              hitStrokeWidth={_.max([strokeWidth, 3])}
              onClick={this.handleClick}
              draggable={!isDisabled && isSelected}
              listening={!(isDisabled && !isEditable)}
              onDragStart={this.handleDragStart}
              onDragMove={this.handleDragMove}
              onDragEnd={this.handleDragEnd}
              stroke={(isSelected && !isRightAngle && !preventOrthoIndication) ? '#aa6eba' : stroke}
            />}
            {isSelected && _.map(handles, point =>
              <CanvasPointHandle
                {...{point}}
                key={`line-handle-${point.id}`}
                radius={4}
                isEnabled={!isDisabled}
                listening={!isDisabled}
                isSelected={this.state.selectedHandleKey === point.id}
                onSelect={() => this.handleSelectPoint(point.id)}
                onDeselect={() => this.handleDeselectPoint(point.id)}
                onMove={this.handleMovePoint(point.id)}
                onMoveStart={this.handleMoveStartPoint}
                onMoveEnd={this.handleMoveEndPoint}
                onClick={() => this.handlePointClick(point.id)}
              />
            )}
          </>
        )}
        {viewDepthComponent}
      </CanvasPortal>
    );
  }
}

export default function EditableCanvasPolylineWithContext(props) {
  const canvasData = useContext(CanvasDataContext);
  const numericInputData = useContext(NumericInputContext);

  return <EditableCanvasLine {...props} {...{canvasData, numericInputData}} />;
}
