import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import updateProductionIds from 'helpers/update-production-ids-helper';

import Container from 'project-helpers/container/';
import DetailsHelper from 'helpers/details-helper';
import Product from 'project-helpers/product';
import Room from 'project-helpers/room';

var ContainerUpdateManagedResourcesHelper = {
  updateManagedResources: ({container, actionKey, resourceActions, scopeChanged, isBatched = false, containerBatched = false}) => {
    var {managedProductInstances: existingManagedProductInstances, project, companyKey, unmanagedProductInstances, room} = Container.get([
      'managedProductInstances', 'project', 'companyKey', 'unmanagedProductInstances', 'room'], {container});
    var typeData = Container.getTypeDataFor({container});
    var {managedProducts} = typeData;
    var newProductInstances = [];
    var oldProductInstances = [];
    var newProductOptionInstances = [];
    var oldProductOptionInstances = [];
    let updatesMap = {
      productOptions: {creations: [], updates: [], deletedIds: []},
      products: {creations: [], updates: [], deletedIds: []},
    };
    let containerCacheUpdate;

    var props = {
      ...container,
      details: _.mapValues(DetailsHelper.getDetailsFor({container}), (detail, detailKey) => {
        if (_.includes(detailKey, 'Material')) {
          detail = _.pick(detail, ['id', 'settings']);
        }

        return detail;
      })
    };

    try {
      var cachedManagedData = {};
      var scribesData = Container.getScribesData({container});

      _.forEach(managedProducts, (product, managedKey) => {
        if (_.includes(['create', 'update'], actionKey)) {
          //TODO OPTIMIZE cache scribesData?
          var data = product.dataFor({props: container, container, scribesData, project});
          var cachedData = _.get(container, `customData.cachedManagedData.productInstances.${managedKey}`);
          var cleanData = data => {
            data = _.cloneDeep(data);

            _.forEach(data.resources, resource => {
              _.unset(resource, 'customData.cachedManagedData');
            });

            return data;
          };

          var dataChanged = scopeChanged || (actionKey === 'update' && (!cachedData || !_.isEqual(cleanData(cachedData), cleanData(data))));

          //HINT handle cases where current cached state is incorrect, ensure we regenerate to the correct state
          if (!dataChanged) {
            var currentManagedProductCount = _.filter(existingManagedProductInstances, ['managedData.managedKey', managedKey]).length;
            //HINT handle case where cache is wrong, delete products that were missed
            var hasManagedProductAndShouldnt = !data.shouldExist && currentManagedProductCount;

            //HINT handle case where too many products were generated, regenerate to delete extras
            var hasIncorrectNumberOfManagedProduct = data.shouldExist && currentManagedProductCount !== data.resources.length;

            if (hasManagedProductAndShouldnt || hasIncorrectNumberOfManagedProduct) {
              dataChanged = true;
            }
            else {
              //HINT shouldn't need to exist, but handling condition where cachedManagedData is different from actual current data
              //could expand condition to include other properties like dims and materials
              //could potentially refactor cached code to just work based on this and stop storing cached values
              if (data.shouldExist && currentManagedProductCount === data.resources.length) {
                _.forEach(_.filter(existingManagedProductInstances, ['managedData.managedKey', managedKey]), managedProduct => {
                  if (!_.find(data.resources, {productId: managedProduct.productId})) {
                    dataChanged = true;
                  }
                });
              }
            }
          }
        }

        if (actionKey === 'destroy' || dataChanged) {
          oldProductInstances.push(
            ..._.filter(existingManagedProductInstances, ['managedData.managedKey', managedKey])
          );
        }

        if (actionKey === 'create' || dataChanged) {
          if (data.shouldExist) {
            _.forEach(data.resources, resource => {
              var existingManagedProductInstance = _.find(_.filter(existingManagedProductInstances, ['managedData.managedKey', managedKey]), productInstance => {
                return !_.isEmpty(_.get(productInstance, 'productionDimensions') || !_.isEmpty(_.get(productInstance, 'notes')));
              });
              var productionDimensions = _.get(existingManagedProductInstance, 'productionDimensions') || {};
              var notes = _.get(existingManagedProductInstance, 'notes') || '';

              let props = {
                ...resource,
                scopeId: container.scopeId,
                projectId: container.projectId,
                versionId: container.versionId,
                containerInstanceId: container.id,
                primaryAssociationKey: 'containerInstance',
                position: _.defaults(resource.position, {x: 0, y: 0, z: 0}),
                productionDimensions,
                notes,
                details: _.mapKeys(resource.materialIds, (id, key) => `${key}Material`),
                managedData: {managedKey}
              };

              if (!props.customData) props.customData = {};

              newProductInstances.push({props});
            });
          }
        }

        _.set(cachedManagedData, `productInstances.${managedKey}`, data);
      });

      //WARNING these product changes need to happen before id updates
      if (oldProductInstances.length > 0) {
        //HINT managed products never have managed products, only managed options
        _.forEach(oldProductInstances, oldProductInstance => {
          var oldProductProductOptions = Product.get('productOptionInstances', {product: oldProductInstance});
          var oldProductChildProducts = _.values(Product.get('childProducts', {product: oldProductInstance}));

          if (oldProductProductOptions.length > 0) {
            oldProductOptionInstances.push(...oldProductProductOptions);
          }

          if (oldProductChildProducts.length > 0) {
            oldProductInstances.push(...oldProductChildProducts);
          }
        });

        if (isBatched) {
          updatesMap.products.deletedIds.push(..._.map(oldProductInstances, 'id'));
        }
        else {

          resourceActions.destroyProducts({ids: _.map(oldProductInstances, 'id')});
        }
      }
      if (newProductInstances.length > 0) {
        if (isBatched) {
          updatesMap.products.creations.push(...newProductInstances);
        }
        else {
          resourceActions.createProducts({propsSets: newProductInstances});
        }
      }

      //< product option instances

      var {type} = typeData;

      var managedOptions = {};

      var allProductOptionInstances = _.flatMap(Container.get('allProducts', {container}), product => {
        return Product.get('productOptionInstances', {product});
      });

      var managedOptions = {};

      if (_.includes(['floatingShelves', 'wall'], type)) {
        managedOptions.lighting = {dataFor: () => {
          var resources = [];

          if (Container.getHasLighting({container})) {
            var {lightingType} = container.customData;
            var lightingProducts = Container.getLightingProducts({container});
            var lightRanges = Container.getLightRanges({container});
            var lightPositions = Container.getLightPositions({container});

            if (companyKey === 'hb') {
              _.forEach(lightingProducts, productInstance => {
                var xRange = Product.getXRange({product: productInstance});

                resources.push({
                  quantity: _.some(lightRanges, ({x1, x2}) => {
                    return x1 <= xRange.from && x2 >= xRange.to;
                  }) ? 1 : 0,
                  productOptionId: 10,
                  productInstanceId: productInstance.id,
                  productInstance
                });
              });
            }
            else if (lightingType === 'puck') {
              //actual lights? based on x position of products
              _.forEach(lightingProducts, productInstance => {
                var xRange = Product.getXRange({product: productInstance});

                resources.push({
                  quantity: _.filter(lightPositions, x => {
                    //WARNING x should never fall at each end so > is ok
                    return x > xRange.from && x < xRange.to;
                  }).length,
                  productOptionId: 39,
                  productInstanceId: productInstance.id,
                  productInstance
                });
              });
            }
            else if (lightingType === 'linear') {
              _.forEach(lightingProducts, productInstance => {
                var xRange = Product.getXRange({product: productInstance});

                resources.push({
                  quantity: _.some(lightRanges, ({x1, x2}) => {
                    return x1 <= xRange.from && x2 >= xRange.to;
                  }) ? 1 : 0,
                  productOptionId: productInstance.dimensions.width >= 42 ? 73 : 72,
                  productInstanceId: productInstance.id,
                  productInstance
                });
              });
            }
          }

          return {resources};
        }};
      }

      if (type === 'vanity') {
        managedOptions.woodDrawers = {dataFor: () => {
          var resources = [];

          if (container.customData.hasWoodDrawers) {
            _.forEach(unmanagedProductInstances, productInstance => {
              if (Product.get('productType', {product: productInstance}).woodDrawerQuantity) {
                //TODO create a separate 1 for each of productInstance.productData.woodDrawerHeights
                resources.push({
                  quantity: Product.get('productType', {product: productInstance}).woodDrawerQuantity || 0,
                  productOptionId: 1,
                  productInstanceId: productInstance.id,
                  productInstance
                });
              }
            });
          }

          return {resources};
        }};
      }

      if (type === 'countertop') {
        managedOptions.countertop = {dataFor: () => {
          var {sinks} = container.customData;
          var productInstance = Container.get('managedProductInstances', {container})[0];
          var resources = [];

          if (sinks && productInstance) {
            resources.push({
              quantity: _.sum(_.map(sinks, sink => sink.includeCutout ? 1 : 0)),
              productOptionId: 7,
              productInstanceId: productInstance.id,
              productInstance
            });

            resources.push({
              quantity: _.sum(_.map(sinks, sink => {
                return {left: 1, right: 1, both: 2}[sink.includeDrainfield] || 0;
              })),
              productOptionId: 8,
              productInstanceId: productInstance.id,
              productInstance
            });
          }

          return {resources};
        }};
      }

      _.forEach(managedOptions, (managedOption, managedKey) => {
        if (_.includes(['create', 'update'], actionKey)) {
          var resources = _.filter(managedOption.dataFor().resources, r => r.quantity > 0);
          var plainData = {resources: _.map(resources, r => _.omit(r, ['productInstance']))};
          var cachedData = _.get(container, `customData.cachedManagedData.productOptionInstances.${managedKey}`, {});
          var dataChanged = actionKey === 'update' && !_.isEqual(cachedData, plainData);
        }

        if (actionKey === 'destroy' || dataChanged) {
          oldProductOptionInstances.push(..._.filter(allProductOptionInstances, ['managedData.managedKey', managedKey]));
        }

        if (actionKey === 'create' || dataChanged) {
          _.forEach(resources, ({quantity, productOptionId, productInstance}) => {
            if (productInstance.id) {
              newProductOptionInstances.push({
                props: {
                  quantity,
                  productOptionId,
                  productInstanceId: productInstance.id,
                  projectId: productInstance.projectId,
                  versionId: productInstance.versionId,
                  scopeId: container.scopeId,
                  managedData: {managedKey}
                }
              });
            }
          });
        }

        _.set(cachedManagedData, `productOptionInstances.${managedKey}`, plainData);
      });

      if (oldProductOptionInstances.length > 0) {
        if (isBatched) {
          updatesMap.productOptions.deletedIds.push(..._.map(oldProductOptionInstances, 'id'));
        }
        else {
          resourceActions.destroyProductOptions({ids: _.map(oldProductOptionInstances, 'id')});
        }
      }
      if (newProductOptionInstances.length > 0) {
        if (isBatched) {
          updatesMap.productOptions.creations.push(...newProductOptionInstances);
        }
        else {
          resourceActions.createProductOptions({propsSets: newProductOptionInstances});
        }
      }

      //> product option instances

      //HINT cached data changed
      if (!_.isEqual(container.customData.cachedManagedData, cachedManagedData)) {
        if (isBatched || containerBatched) {
          containerCacheUpdate = cachedManagedData;
        }
        else {
          setTimeout(() => resourceActions.updateContainer({id: container.id, props: {customData: {...container.customData, cachedManagedData}}}));
        }
      }

      return {managedUpdatesMap: updatesMap, containerCacheUpdate};
    }
    catch (error) {
      console.log(error); //eslint-disable-line
    }
  },
};

export default ContainerUpdateManagedResourcesHelper;