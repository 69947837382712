import React, { Fragment } from 'react';
import { resourceActions, connect } from 'redux/index.js';

import _ from 'lodash';
import lib from 'lib';
import K from 'k';

import ReactTooltip from 'react-tooltip';
import { pluralize } from 'inflection';

import HudElement from './hud-element';
import Container from 'project-helpers/container';
import Product from 'project-helpers/product';
import Elevation from 'project-helpers/elevation';
import ArchElement from 'project-helpers/arch-element';

import arrowIcon from 'assets/up-arrow-black.png';
import InfoIcon from 'assets/info-icon.png';
import selectContainerIcon from 'assets/select-container-icon.png';
import SelectCountertopIcon from 'assets/select-countertop-icon.png';
import SwapProductIcon from 'assets/swap-icon.png';
import unlockIcon from 'assets/controls-hud-element/unlock-icon-black.png';
import lockIcon from 'assets/controls-hud-element/lock-icon-black.png';
import bringForwardIcon from 'assets/controls-hud-element/send-forward-icon.png';
import bringToFrontIcon from 'assets/controls-hud-element/send-to-front-icon.png';
import sendBackwardIcon from 'assets/controls-hud-element/send-backward-icon.png';
import sendToBackIcon from 'assets/controls-hud-element/send-to-back-icon.png';

import deleteIcon from 'assets/trash-icon-black.png';

import 'styles/active-hud-element.scss';

class ActiveItemHudElement extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  toggleLocked() {
    const {activeEntitiesData, activeEntities} = this.props;

    this.props[`update${_.upperFirst(_.first(activeEntitiesData).resourceKey)}`]({id: _.first(activeEntities).id, props: {customData: {..._.first(activeEntities).customData, isLocked: !_.first(activeEntities).customData.isLocked}}});
  }

  render () {
    let {activeViewEntity, activeViewEntityResourceKey, activeEntitiesData, activeEntities, iterable, parameterEditorIsShowing,
      viewMode, toggleInfoPopupShowing, handleDeleteEvent, setActiveEntities, getIncludesActiveEntity, handleActiveHudElementIteration
    } = this.props;

    //HINT only have info to show for products and containers
    let selectedEntityTitle = '';
    let container, parentProduct, parentProductTitle;
    let countertops = [];

    if (_.size(activeEntitiesData) && _.size(activeEntities)) {
      if (getIncludesActiveEntity({resourceKey: 'container'})) {
        selectedEntityTitle = Container.getTypeDataFor({container: _.first(activeEntities)}) ? `${Container.getTypeDataFor({container: _.first(activeEntities)}).title} Container` : '';
        countertops = Container.getCountertops({container: _.first(activeEntities)});
      }
      else if (getIncludesActiveEntity({resourceKey: 'product'})) {
        selectedEntityTitle = `${_.get(activeEntities, '0.productionId') ? `${_.get(activeEntities, '0.productionId')} - ` : ''}${Product.getProductData({product: _.first(activeEntities)}).title}`;
        parentProduct = Product.get('parentProduct', {product: _.first(activeEntities)});
        container = Product.get('container', {product: _.first(activeEntities)});
        countertops = Container.getCountertops({container});

        if (parentProduct) parentProductTitle = `${parentProduct.productionId !== null ? `${parentProduct.productionId} - ` : ''}${Product.getProductData({product: parentProduct}).title}`;
      }
      else if (getIncludesActiveEntity({resourceKey: 'volume'})) {
        selectedEntityTitle = 'Volume';
      }
      else if (getIncludesActiveEntity({resourceKey: 'scope'})) {
        selectedEntityTitle = activeEntities[0].title || 'Unnamed Scope';
      }
      else if (getIncludesActiveEntity({resourceKey: 'archElement'})) {
        selectedEntityTitle = ArchElement.getTypeData({archElement: activeEntities[0]}).title;
      }
      else if (getIncludesActiveEntity({resourceKey: 'projectGraphic'})) {
        selectedEntityTitle = _.upperFirst(_.get(activeEntitiesData, '0.type'));
      }
    }

    var room, roomTitle;

    if (viewMode === 'front') {
      var room = Elevation.get('room', {elevation: activeViewEntity});
      var roomTitle = _.get(room, 'title', '');
    }

    var showInfoButton = getIncludesActiveEntity({resourceKey: 'container'}) || getIncludesActiveEntity({resourceKey: 'product'});

    if (getIncludesActiveEntity({resourceKey: 'container'})) {
      showInfoButton = !Container.getTypeDataFor({container: activeEntities[0]})?.isOrnament;
    }

    var isElevationProjectGraphic = _.get(_.first(activeEntitiesData), 'resourceKey') === 'projectGraphic' && activeEntities[0].elevationId;

    return (
      <HudElement
        x='center'
        y='bottom'
        style={{
          display: 'flex', flexDirection: 'column', alignItems: 'center',
          ...(parameterEditorIsShowing ? {left: '35%'} : {}), zIndex: 1000,
          ...(viewMode === 'threeD' && _.get(activeViewEntity, 'customData.threeDBackgroundColor') ? {backgroundColor: activeViewEntity.customData.threeDBackgroundColor} : {})
        }}
      >
        {!!_.size(activeEntities) && !getIncludesActiveEntity({resourceKey: 'elevation'}) && !getIncludesActiveEntity({resourceKey: 'room'}) && (
          <div style={{display: 'flex', alignItems: 'center'}}>
            {showInfoButton && (<Fragment>
              <ReactTooltip id={`${_.get(activeEntities, '0.id')}-tooltip`} effect='solid'>View {_.upperFirst(_.get(activeEntitiesData, '0.resourceKey'))} Info</ReactTooltip>
              <img src={InfoIcon} alt='info icon' width="16px;" style={{cursor: 'pointer'}}
                onClick={() => toggleInfoPopupShowing({entity: getIncludesActiveEntity({resourceKey: 'container'}) ? Container.get('dbContainerType', {container: _.first(activeEntities)}) : Product.getProductData({product: _.first(activeEntities)}), entityResourceKey: _.get(activeEntitiesData, '0.resourceKey')})} data-tip='tooltip' data-for={`${_.get(activeEntities, '0.id')}-tooltip`}
              />
            </Fragment>)}
            <span style={{'padding': '16px'}}>{selectedEntityTitle}</span>
            <Fragment>
              <ReactTooltip id={`${_.get(activeEntities, '0.id')}-delete`} effect='solid'>Delete {_.startCase(_.get(activeEntitiesData, '0.resourceKey'))}</ReactTooltip>
              <img src={deleteIcon} alt='delete icon' width="18px;" style={{cursor: 'pointer'}}
                onClick={handleDeleteEvent} data-tip='tooltip' data-for={`${_.get(activeEntities, '0.id')}-delete`}
              />
            </Fragment>
            {_.get(_.first(activeEntitiesData), 'resourceKey') !== 'scope' && (
              <Fragment>
                <ReactTooltip id={'locked-tooltip'} effect='solid'>Toggle locked</ReactTooltip>
                <img src={_.get(_.first(activeEntities), 'customData.isLocked') ? lockIcon : unlockIcon} alt='select locked'
                  style={{cursor: 'pointer'}} width="18px;" onClick={() => this.toggleLocked()}
                  data-tip='tooltip' data-for={'locked-tooltip'}
                />
              </Fragment>
            )}
            {/*activeEntityResourceKey === 'product' && (
              <ReactTooltip id={`${activeEntity.id}-tooltip`} effect='solid'>Swap Product</ReactTooltip>
              //TODO get list of swappable products using Product.getSwapProductOptions (need to write) function
              //handler to swap product need to delete managed products and update details and appliance data when relevant
              //then call updatemanagedproducts on the new product
            )*/}
            {isElevationProjectGraphic && (
              <Fragment>
                <ReactTooltip id={'bring-to-front-tooltip'} effect='solid'>Bring To Front</ReactTooltip>
                <img src={bringToFrontIcon} alt='bring to front'
                  style={{cursor: 'pointer'}} width="16px;" onClick={() => this.props.handleZMovementEvent({entity: activeEntities[0], entityResourceKey: 'projectGraphic', sendTo: 'front'})}
                  data-tip='tooltip' data-for={'bring-to-front-tooltip'}
                />
              </Fragment>
            )}
            {isElevationProjectGraphic && (
              <Fragment>
                <ReactTooltip id={'bring-forward-tooltip'} effect='solid'>Bring Forward</ReactTooltip>
                <img src={bringForwardIcon} alt='bring forward'
                  style={{cursor: 'pointer'}} width="18px;" onClick={() => this.props.handleZMovementEvent({entity: activeEntities[0], entityResourceKey: 'projectGraphic', sendTo: 'forward'})}
                  data-tip='tooltip' data-for={'bring-forward-tooltip'}
                />
              </Fragment>
            )}
            {isElevationProjectGraphic && (
              <Fragment>
                <ReactTooltip id={'send-backward-tooltip'} effect='solid'>Send Backward</ReactTooltip>
                <img src={sendBackwardIcon} alt='send backward'
                  style={{cursor: 'pointer'}} width="18px;" onClick={() => this.props.handleZMovementEvent({entity: activeEntities[0], entityResourceKey: 'projectGraphic', sendTo: 'backward'})}
                  data-tip='tooltip' data-for={'send-backward-tooltip'}
                />
              </Fragment>
            )}
            {isElevationProjectGraphic && (
              <Fragment>
                <ReactTooltip id={'send-to-back-tooltip'} effect='solid'>Send To Back</ReactTooltip>
                <img src={sendToBackIcon} alt='send to back'
                  style={{cursor: 'pointer'}} width="18px;" onClick={() => this.props.handleZMovementEvent({entity: activeEntities[0], entityResourceKey: 'projectGraphic', sendTo: 'back'})}
                  data-tip='tooltip' data-for={'send-to-back-tooltip'}
                />
              </Fragment>
            )}
            {parentProduct && (
              <Fragment>
                <ReactTooltip id={`product-${parentProduct.id}-tooltip`} effect='solid'>Select {parentProductTitle}</ReactTooltip>
                <img src={selectContainerIcon} alt='select parentProduct' width="18px;"
                  style={{cursor: 'pointer'}} onClick={() => setActiveEntities({entities: [{resourceKey: 'product', id: parentProduct.id}]})}
                  data-tip='tooltip' data-for={`product-${parentProduct.id}-tooltip`}
                />
              </Fragment>
            )}
            {container && (
              <Fragment>
                <ReactTooltip id={`${container.id}-tooltip`} effect='solid'>Select Container</ReactTooltip>
                <img src={selectContainerIcon} alt='select container' width="18px;"
                  style={{cursor: 'pointer'}} onClick={() => setActiveEntities({entities: [{resourceKey: 'container', id: container.id}]})}
                  data-tip='tooltip' data-for={`${container.id}-tooltip`}
                />
              </Fragment>
            )}
            {_.map(countertops, (countertop, index) => {
              //TODO use production id for countertops
              return (
                <Fragment key={countertop.id}>
                  <ReactTooltip id={`${countertop.id}-tooltip`} effect='solid'>Select Countertop {index + 1}</ReactTooltip>
                  <img
                    src={SelectCountertopIcon} alt={`select countertop ${countertop.id}`} width="18px;"
                    onClick={() => setActiveEntities({entities: [{resourceKey: 'container', id: countertop.id}]})}
                    data-tip='tooltip' data-for={`${countertop.id}-tooltip`}
                    style={{cursor: 'pointer'}}
                  />
                </Fragment>
              );
            })}
          </div>
        )}
        <div style={{display: 'flex', alignItems: 'center'}}>
          {iterable && <img src={arrowIcon} style={{transform: 'rotate(-90deg)', cursor: 'pointer'}} width="24px" onClick={() => handleActiveHudElementIteration('prev')} className='cursorPointer'/>}
          <span style={{marginLeft: '30px', marginRight: '30px', position: 'relative', top: -1, width: '250px', textAlign: 'center', overflowX: 'hidden', ...K.fonts.header}}>
            {viewMode === 'front' ? `${roomTitle ? `${roomTitle} - ` : ''}${Elevation.getTitle({elevation: activeViewEntity})}` : (activeViewEntity.title || `Untitled ${_.upperFirst(activeViewEntityResourceKey)}`)}
          </span>
          {iterable && <img src={arrowIcon} width="24px" style={{position: 'relative', top: -1, transform: 'rotate(90deg)', cursor: 'pointer'}} onClick={() => handleActiveHudElementIteration('next')} className='cursorPointer'/>}
        </div>
      </HudElement>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var {activeEntitiesData} = ownProps;

    var activeEntities = _.map(activeEntitiesData, (activeEntity) => {
      return _.get(state.resources, `[${pluralize(activeEntity.resourceKey)}].byId[${activeEntity.id}]`);
    });

    return {
      activeEntities,
      activeEntitiesData
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.containers, ['updateContainer']),
    ..._.pick(resourceActions.products, ['updateProduct']),
    ..._.pick(resourceActions.archElements, ['updateArchElement']),
    ..._.pick(resourceActions.volumes, ['updateVolume']),
  }
})(ActiveItemHudElement);
