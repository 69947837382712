import React from 'react';
import _ from 'lodash';
import K from 'k';

import installationPage3 from '../../../assets/typical-installation-details-3.png'

const InstallationPage3 = () => {
  return (
    <div style={{display: 'flex', height: '100%', width: '100%', justifyContent: 'center'}}>
      <img src={installationPage3} style={{objectFit: 'contain', width: '100%', height: '100%', margin: 'none'}} />
    </div>
  );
};

export default InstallationPage3