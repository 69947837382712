import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function DragDropList (props) {
  const handleDragEnd = (data) => {

    if (data.destination) {
      const result = Array.from(props.items);
      const [removed] = result.splice(data.source.index, 1);
      result.splice(data.destination.index, 0, removed);

      if (props.onDragEnd) {
        props.onDragEnd(_.map(result, ({draggableId}, index) => ({id: draggableId, index})))
      };
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {_.map(props.items, ({node, draggableId, isDragDisabled}, index) => (
              <Draggable {...{draggableId: `${draggableId}`, index, isDragDisabled}} key={draggableId}>
                {provided => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    {node}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DragDropList;
