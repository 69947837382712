import React, { useState, useRef } from 'react';
import K from 'k';

import upArrowIcon from 'assets/up-arrow-black.png';
import drawingsIcon from 'assets/drawings-icon.png';
import invoiceIcon from 'assets/invoice-icon.png';
import linkIcon from 'assets/link-icon-black.png';
import ReactTooltip from 'react-tooltip';
import Dropdown from 'components/dropdown';
import {withErrorBoundary} from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';

import LinksMenu from './links-menu/links-menu';

const Header = ({
  project, toggleAccessoriesView, toggleMaterialFactorsPopup, toggleShareableLinkPopup, shouldShowShareableLink, setActiveUserLense, activeUserLense
}) => {
  var hbLogo = 'https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/images/logo/hb-logo-light.png';
  var stLogo = 'https://spacetheory-public.s3.amazonaws.com/logos/white-square-100.png';

  const [linksMenuShowing, setLinksMenuShowing] = useState(false);
  const [isCopyingLink, setIsCopyingLink] = useState(false);
  const [isCopyingGroupLink, setIsCopyingGroupLink] = useState(false);

  const linkMenuRef = useRef(null);

  React.useEffect(() => {
    const handleLinkMenuMouseOut = (event) => {
      if (!(!linkMenuRef.current || linkMenuRef.current.contains(event.target))) {
        setLinksMenuShowing(false);
      }
    };

    document.addEventListener('mouseout', handleLinkMenuMouseOut);

    return () => {
      document.removeEventListener('mouseout', handleLinkMenuMouseOut);
    };
  }, [linkMenuRef]);

  var invoiceLink = project.companyKey === 'hb' ? `/pricing_tool/projects/${project.id}/proposals/${project.versionId}` : `/design-engine/projects/${project.id}/drafts/${project.versionId}/invoice`;

  var handleShareableLinkClick = async () => {
    var {id: projectId, versionId} = project;

    setIsCopyingLink(true);

    var response = await lib.api.request({uri: 'de-project/generate-token', body: {projectId, versionId, access: shouldShowShareableLink ? ['configurator'] : ['editor']}, useActualErrorMessages: true});

    navigator.clipboard.writeText(response.data.shareableLink);

    setTimeout(() => {
      setIsCopyingLink(false);
    }, 100);
  }

  var handleGroupLinkClick = async () => {
    var {id: projectId, versionId} = project;

    setIsCopyingGroupLink(true);

    var response = await lib.api.request({uri: 'de-project/generate-group-token', body: {projectId, versionId, groupId: project.groupId, access: ['configurator', 'configuratorIndex']}, useActualErrorMessages: true});

    navigator.clipboard.writeText(response.data.shareableLink);

    setTimeout(() => {
      setIsCopyingGroupLink(false);
    }, 100);
  }

  return (<>
    <div className='header' style={{height: '50px', backgroundColor: 'black', color: 'white', overflow: 'visible'}}>
      <div className='header-background' style={{width: '100%', height: '50px', backgroundColor: 'black', position: 'absolute', top: '0px', display: 'flex', alignItems: 'center', zIndex: 1000}}>
        <a data-tip='tooltip' data-for={'back-tooltip'} style={{marginLeft: 20, marginRight: K.spacing}} href={`${NODE_ENV === 'development' ? `http://localhost:${process.env.HB_PORT || 3000}` : 'https://apps.henrybuilt.com'}/design-engine/projects/`} target="_blank" rel={'noreferrer'}>
          <ReactTooltip id={'back-tooltip'} effect='solid'>Back to all Projects</ReactTooltip>
          <img src={upArrowIcon} style={{...K.defaultIconSize, display: 'block', transform: 'rotate(270deg)', margin: 1, filter: 'invert(100%)'}}></img>
        </a>
        {/* <a data-tip='tooltip' data-for={'drawings-tooltip'} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: K.spacing}} href={`${NODE_ENV === 'development' ? `http://localhost:${process.env.HB_PORT || 3000}` : 'https://henrybuilt.com'}/design-engine/projects/${project.id}/drafts/${project.versionId}/drawings?mode=client`} target={'_blank'} rel={'noreferrer'}> */}
        <a data-tip='tooltip' data-for={'drawings-tooltip'} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: K.spacing}} href={`/projects/${project.id}/drafts/${project.versionId}/drawings?mode=client`} target={'_blank'} rel={'noreferrer'}>
          <ReactTooltip id={'drawings-tooltip'} effect='solid'>Drawings</ReactTooltip>
          <img style={{...K.defaultIconSize, margin: 1, top: -1, position: 'relative'}} src={drawingsIcon} alt='Drawings'></img>
        </a>
        <a data-tip='tooltip' data-for={'invoice-tooltip'} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} href={`${NODE_ENV === 'development' ? `http://localhost:${process.env.HB_PORT || 3000}` : 'https://apps.henrybuilt.com'}${invoiceLink}`} target={'_blank'} rel={'noreferrer'}>
          <ReactTooltip id={'invoice-tooltip'} effect='solid'>Invoice</ReactTooltip>
          <img style={{...K.defaultIconSize, margin: 1}} src={invoiceIcon} alt='Invoice'></img>
        </a>
        <span data-tip='tooltip' data-for={'materials-tooltip'} style={{display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center', color: 'white', fontSize: 16, paddingLeft: 14, paddingRight: 14}} onClick={() => toggleMaterialFactorsPopup()} target={'_blank'} rel={'noreferrer'}>
          <ReactTooltip id={'materials-tooltip'} effect='solid'>Material Key</ReactTooltip>
          ?
        </span>
        {(shouldShowShareableLink || _.includes([5605, 6502], project.userId)) && <span data-tip='tooltip' data-for={'shareable-link-tooltip'} style={{display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center', marginRight: K.spacing}} onClick={async () => await handleShareableLinkClick()}>
          <ReactTooltip id={'shareable-link-tooltip'} effect='solid'>{shouldShowShareableLink ? 'Configurator' : 'Share'} Link</ReactTooltip>
          {(isCopyingLink ? <span>copying...</span> : <img style={{...K.defaultIconSize, width: '17px', height: '17px', margin: 1, filter: 'invert(100%)'}} src={linkIcon} alt='Link'></img>)}
        </span>}
        {project.groupId && <span data-tip='tooltip' data-for={'shareable-group-link-tooltip'} style={{display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center'}} onClick={async () => await handleGroupLinkClick()}>
          <ReactTooltip id={'shareable-group-link-tooltip'} effect='solid'>Group Index Link</ReactTooltip>
          {(isCopyingGroupLink ? <span>copying...</span> : <img style={{...K.defaultIconSize, width: '17px', height: '17px', margin: 1, filter: 'invert(100%)'}} src={linkIcon} alt='Link'></img>)}
        </span>}
        <div style={{flexGrow: 1}}>

        </div>
        <div style={{paddingRight: K.spacing, display: 'flex', flexDirection: 'row'}}>
          <Dropdown
            value={activeUserLense}
            placeholder={'Design'}
            onChange={(value) => setActiveUserLense({key: value})}
            options={_.map([{title: 'Design', key: 'design'}, {title: 'Sales', key: 'sales'}, {title: 'Engineering', key: 'engineering'}], ({key, title}) => ({value: key, title}))}
            showCaret={false}
            style={{marginRight: K.spacing * 2, display: 'flex', zIndex: 4}}
            contentContainerStyle={{marginTop: 30}}
            hasFixedHeight
          />
          <div ref={linkMenuRef}>
            <img
              src={project.companyKey === 'hb' ? hbLogo : stLogo}
              style={{width: 32, height: 32, alignSelf: 'flex-end', borderRadius: 5}}
              onMouseOver={() => setLinksMenuShowing(true)}
            />
            {linksMenuShowing && <LinksMenu project={project}/>}
          </div>
        </div>
      </div>
    </div>
  </>);
};

export default withErrorBoundary(Header, {
  FallbackComponent: ErrorFallback,
  onError: (error, info) => {
    global.handleError({error, info, message: 'header'});
  }
});
