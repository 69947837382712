import React, {Fragment} from 'react';
import { resourceActions, connect } from 'redux/index.js';

import K from 'k';
import _ from 'lodash';
import lib from 'lib';
import { pluralize, singularize } from 'inflection';

import listIconBlack from '../../../assets/list-icon-black.png';
import thumbnailsIconBlack from '../../../assets/thumbnails-icon-black.png';
import xIconWhite from '../../../assets/x-icon-white.png';
import xIconBlack from '../../../assets/x-icon-black.png';
import menuIconWhite from '../../../assets/menu-icon-white.png';

import Floor from 'project-helpers/floor';
import Room from 'project-helpers/room';
import Elevation from 'project-helpers/elevation';
import Scope from 'project-helpers/scope';
import Container from 'project-helpers/container';
import Product from 'project-helpers/product';
import ArchElement from 'project-helpers/arch-element';
import Volume from 'project-helpers/volume';

import HudElement from './hud-element';
import MenuNode from './editor-menu-components/menu-node';
import TextInput from 'components/text-input';
import Thumbnail from './editor-menu-components/thumbnail';
import AddProjectTreeNodePopup from '../popups/add-project-tree-node-popup';

import getProductsByCategoryFor from 'helpers/product-categories-helper';

class EditorMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: true,
      searchInput: '',
      configurationLibraryCategory: 'archetype-group',
      activeProjectTreeNodeIds: {},
      expandedMenuNodeIdsByCategory: {project: {}, containers: {}, products: {}, props: {}, archElements: {}, archetypes: {}, nonSpacialContainers: {}, volumes: {}},
      expandedMenuNodeIdsByCategoryWhileSearching: {project: {}, containers: {}, products: {}, props: {}, archElements: {}, archetypes: {}, nonSpacialContainers: {}, volumes: {}},
      isAddingArchetypes: false,
      isHoveringHideButton: false,
    };
  }

  shouldComponentUpdate(nextProps) {
    return (_.get(nextProps.activeEntities, '0.eventType') !== 'transform' || _.get(this.props.activeEntities, '0.eventType') !== 'transform' || nextProps.viewMode !== this.props.viewMode);
  }

  componentDidUpdate(_prevProps, prevState) {
    var {activeViewEntityResourceKey, activeViewEntity, activeViewEntityId} = this.props;

    if (
      activeViewEntityResourceKey !== _prevProps.activeViewEntityResourceKey ||
      activeViewEntityId !== _prevProps.activeViewEntityId
    ) {
      var parentNodeIds = ['project'];
      var id;

      if (activeViewEntityResourceKey === 'floor') {
        id = `floor-${activeViewEntity.id}`
      }
      else if (activeViewEntityResourceKey === 'room') {
        parentNodeIds.push(`floor-${activeViewEntity.floorId}`)

        id = `room-${activeViewEntity.id}`;
      }
      else if (_.includes(['scope', 'elevation'], activeViewEntityResourceKey)) {
        var room = _.find(this.props.rooms, {id: activeViewEntity.roomId});

        if (room) {
          parentNodeIds.push(`floor-${room.floorId}`)

          id = `room-${room.id}`;
        }
      }

      this.handleMenuNodeIsExpandedChange({parentNodeIds, id, value: true, searchInputUpdate: '', category: 'project', reset: true});
    }
    else if (
      this.props.activeEntitiesData.length === 1
      && this.props.activeEntitiesData !== _prevProps.activeEntitiesData &&
      (this.props.getIncludesActiveEntity({resourceKey: 'container'}) || this.props.getIncludesActiveEntity({resourceKey: 'product'}))
    ) {
      var {viewMode, bothIsShowingElevations, activeEntities, activeEntitiesData} = this.props;
      var someFrontVisible = !_.includes(['schematic'], this.props.activeDetailLevel) && (viewMode === 'front' || (viewMode === 'both' && bothIsShowingElevations) || viewMode === 'lite');

      var containerOrProductSelected = false;

      var resourceKey = this.props.getIncludesActiveEntity({resourceKey: 'product'}) ? 'product' : 'container';

      if (resourceKey === 'container') {
        containerOrProductSelected = !Container.getTypeDataFor({container: _.get(activeEntities, '0')}).isOrnament && _.get(activeEntities, '0.type') !== 'countertop';
      }

      if (resourceKey === 'product') {
        containerOrProductSelected = Product.getHasComponents({product: _.get(activeEntities, '0')});
      }

      var activeEntity = activeEntities[0];
      var container = activeEntity.resourceKey === 'container' ? activeEntity : Product.get('container', {product: activeEntity});
      var activeEntityIsFrontFacing = (viewMode === 'both' && bothIsShowingElevations) || viewMode === 'lite' || (viewMode === 'front' && Container.getSideKey({container, viewKey: 'front', elevation: activeViewEntity}) === 'front');

      if (someFrontVisible && containerOrProductSelected && activeEntityIsFrontFacing) {
        this.setState({isExpanded: true});
        this.handleMenuNodeIsExpandedChange({parentNodeIds: ['products'], searchInputUpdate: '', value: true, category: 'products', reset: true});
      }
    }
    else if (this.state.searchInput === '' && prevState.searchInput !== '') {
      this.setState({searchInput: '', expandedMenuNodeIdsByCategoryWhileSearching: {project: {}, containers: {}, products: {}, props: {}, archElements: {}, archetypes: {}, nonSpacialContainers: {}, volumes: {}}});
    }

    if (_.get(this.props, 'activeEntitiesData.length') === 0 && _.get(_prevProps, 'activeEntitiesData.length') !== 0 && this.state.isExpanded) {
      this.setState({isExpanded: false});
    }

    if (_.some(['menuDisplayMode'], key => prevState[key] !== this.state[key])) {
      lib.cookie.set({scope: 'cfg', key: 'ui-settings', value: _.pick(this.state, ['menuDisplayMode']), domain: window.location.href.includes('localhost') ? 'localhost' : 'henrybuilt.com'});
    }
  }

  componentDidMount() {
    var {activeViewEntityResourceKey, activeViewEntity} = this.props;

    document.addEventListener('mouseup', () => this.props.handleAddMenuMouseUp());

    var uiSettings = _.pick(_.defaultsDeep(lib.cookie.get({scope: 'cfg', key: 'ui-settings'})), ['menuDisplayMode']);

    if (!uiSettings.menuDisplayMode) uiSettings.menuDisplayMode = 'grid';

    this.setState({...uiSettings});

    var parentNodeIds = ['project'];
    var id;

    if (activeViewEntityResourceKey === 'floor') {
      id = `floor-${activeViewEntity.id}`
    }
    else if (activeViewEntityResourceKey === 'room') {
      parentNodeIds.push(`floor-${activeViewEntity.floorId}`)

      id = `room-${activeViewEntity.id}`;
    }
    else if (_.includes(['scope', 'elevation'], activeViewEntityResourceKey)) {
      var room = _.find(this.props.rooms, {id: activeViewEntity.roomId});

      if (room) {
        parentNodeIds.push(`floor-${room.floorId}`)

        id = `room-${room.id}`;
      }
    }

    this.handleMenuNodeIsExpandedChange({parentNodeIds, id, value: true, category: 'project', reset: true});
  }

  componentWillUnmount() {
    document.addEventListener('mouseup', () => this.props.handleAddMenuMouseUp());
  }

  async handleSubmitTextInput({value}) {
    var {id: projectId, versionId} = this.props.project;

    // TODO: add in ai prompt support
    // var aiPromptResponse = (await lib.api.request({uri: 'de-project/ai-prompt', body: {projectId, versionId, input: value}, useActualErrorMessages: true})).data;
  }

  handleAddProjectTreeNode = async ({roomTitle, floorTitle}) => {
    var {projectId, floorId} = this.state.activeProjectTreeNodeIds;
    var {versionId, id: projectId} = this.props.project;

    if (this.state.projectTreeAddingType === 'floor') {
      this.handleAddFloor({projectId, floorTitle, roomTitle})
    };

    if (this.state.projectTreeAddingType === 'room') this.handleAddRoom({floorId, roomTitle, versionId, projectId});

    this.setState({activeProjectTreeNodeIds: {}});
  }

  handleSetActiveAddingNodeIds = ({ids}) => {
    this.setState({activeAddingNodeIds: ids});
  }

  handleAddFloor = async ({projectId, floorTitle = '', roomTitle = ''}) => {
    var floor = await lib.api.create('floor', {props: {versionId: this.props.project.versionId, projectId, title: floorTitle}});

    this.props.trackFloors({floors: [floor]});

    await this.handleAddRoom({floorId: floor.id, roomTitle, projectId, versionId: this.props.project.versionId});

    setTimeout(() => this.props.setActiveViewEntityId('floor', floor.id));
};

  handleAddRoom = async ({floorId, roomTitle = '', versionId, projectId}) => {
    var customData = {};

    var roomProps = {title: roomTitle, floorId, versionId, projectId, plan: {points: []}, customData};

    var siblingRooms = _.find(this.props.floors, {id: floorId}) ? Floor.get('rooms', {floor: _.find(this.props.floors, {id: floorId})}) : [];

    var roomsUsingRank = _.some(siblingRooms, room => room.rank);

    if (roomsUsingRank) {
      roomProps.rank = _.max(_.map(siblingRooms, 'rank')) + 1;
    }

    var room = await lib.api.create('room', {props: roomProps});

    var scope = await lib.api.create('scope', {props: {title: roomTitle, projectId, versionId, roomId: room.id}});

    this.props.trackScopes({scopes: [scope]});
    this.props.trackRooms({rooms: [room]});

    setTimeout(() => {
      if (this.props.viewMode === 'lite') {
        this.props.setActiveViewEntityId('scope', scope.id);
      }
      else {
        this.props.setActiveEntities({entities: [{resourceKey: 'room', id: room.id}]});

        if (this.props.activeViewEntityResourceKey !== 'floor') this.props.setActiveViewEntityId('room', room.id);
      }
    });
  };

  handleAddScopeButtonPress = async ({roomId, floorId}) => {
    var {viewMode} = this.props;

    if (viewMode === 'lite') {
      this.setState({isAddingProjectTreeNode: true, activeFloorId: floorId, activeRoomId: roomId});
    }
    else {
      this.props.onAddScope({roomId, floorId});
    }
  }

  handleAddElevation = () => {
    this.props.onAddElevation();
  };

  handleTreeNodeDelete = ({entity, resourceKey}) => () => {
    var shouldDelete = false;

    if (resourceKey === 'scope') {
      shouldDelete = confirm(`Are you sure you want to delete this scope?`);
    }
    else if (resourceKey === 'elevation') {
      shouldDelete = confirm(`Are you sure you want to delete this elevation?`);
    }
    else {
      shouldDelete = _.trim(prompt(`Please enter "${entity.title || `Untitled ${_.upperFirst(resourceKey)}`}" to confirm deletion. This can't be undone.`)) === _.trim((entity.title || `Untitled ${_.upperFirst(resourceKey)}`));
    }

    if (shouldDelete) {
      if (resourceKey === 'scope') {
        this.props.onDeselect();
        Scope.updateComponents({room: Scope.get('room', {scope: entity}), resourceActions: this.props, excludedScopeIds: [entity.id]});

        this.props.destroyScope({id: entity.id});
      }
      else if (resourceKey === 'elevation') {
        this.props.pushToUndoQueue({type: 'elevation', eventKey: 'destroy', instance: entity});

        this.props.onDeselect();

        this.props.destroyElevation({id: entity.id});
      }
      else {
        var Resource = {
          floor: Floor,
          room: Room
        }[resourceKey];

        this.props.onDeselect();

        Resource.destroy({[resourceKey]: entity, resourceActions: this.props});
      }
    }
  };

  handleEntityClick = (resourceKey, id) => () => {
    this.props.setActiveEntities({entities: [{resourceKey, id}]});
  };

  handleNavigateToEntity = (activeViewEntityResourceKey, activeViewEntityId) => () => {
    this.props.setActiveViewEntityId(activeViewEntityResourceKey, activeViewEntityId);
  };

  handleTreeReorder = ({updates, resourceKey}) => {
    var updates = _.map(updates, ({id, index}) => ({where: {id}, props: {rank: index}}));

    this.props[`update${_.upperFirst(pluralize(resourceKey))}`]({updates});
  }

  getContainerCategoryMenuNodes = (filteredContainerTypes, groupedContainerTypes) => {
    return [
      {
        title: 'Base',
        id: 'base-containers',
        type: 'containerCategory',
        childNodeGroups: [{
          id: 'base-container-group',
          childNodesContainThumbnails: true,
          childNodes: _.filter(filteredContainerTypes, ({type}) => (_.includes(['base', 'floatingBase', 'baseFreestandingAppliance', 'sculptedPanel', 'kick'], type)))
        }]
      },
      {
        title: 'Wall',
        id: 'wall-containers',
        type: 'containerCategory',
        childNodeGroups: [{
          id: 'wall-container-group',
          childNodesContainThumbnails: true,
          childNodes: _.filter(filteredContainerTypes, ({type}) => (_.includes(['wall', 'floatingShelves', 'opencase', 'wallFreestandingAppliance', 'wallPanel', 'wallUnitLiner', 'backsplash', 'cornerCounterTransition'], type)))
        }]
      },
      {
        title: 'Tall',
        id: 'tall-containers',
        type: 'containerCategory',
        childNodeGroups: [{
          id: 'tall-container-group',
          childNodesContainThumbnails: true,
          childNodes: _.filter(filteredContainerTypes, ({type}) => (_.includes(['tall', 'tallFreestandingAppliance', 'sculptedPanel'], type)))
        }]
      },
      {
        title: 'Island',
        id: 'island-containers',
        type: 'containerCategory',
        childNodeGroups: [{
          id: 'island-container-group',
          childNodesContainThumbnails: true,
          childNodes: _.filter(filteredContainerTypes, ({type}) => (_.includes(['base', 'baseWithChase', 'islandSeating', 'islandExtension', 'hbIslandExtension', 'islandBackPanel', 'horizontalBarblock', 'rearFacingBarblock', 'sculptedPanel', 'endPanel', 'daylightIsland', 'kick'], type)))
        }]
      },
      {
        title: 'Opencase',
        id: 'opencase-containers',
        type: 'containerCategory',
        childNodeGroups: [{
          id: 'opencase-container-group',
          childNodesContainThumbnails: true,
          childNodes: _.filter(filteredContainerTypes, ({type}) => (_.includes(['opencase'], type)))
        }]
      },
      {
        title: 'Vanities',
        id: 'vanity-containers',
        type: 'containerCategory',
        childNodeGroups: [{
          id: 'vanity-container-group',
          childNodesContainThumbnails: true,
          childNodes: _.filter(filteredContainerTypes, ({type}) => (_.includes(['vanity', 'kick', 'capPanel', 'endPanel'], type)))
        }]
      },
      {
        title: 'Panels',
        id: 'panel-containers',
        type: 'containerCategory',
        childNodeGroups: [{
          id: 'panel-container-group',
          childNodesContainThumbnails: true,
          childNodes: _.filter(filteredContainerTypes, ({type}) => (_.includes(['endPanel', 'capPanel', 'wallPanel', 'sculptedPanel', 'opencase'], type)))
        }]
      },
      {
        title: 'Wardrobe',
        id: 'wardrobe-containers',
        type: 'containerCategory',
        childNodeGroups: [{
          id: 'wardrobe-container-group',
          childNodesContainThumbnails: true,
          childNodes: _.filter(filteredContainerTypes, ({type}) => (_.includes(['base', 'tall', 'floatingBase', 'valet', 'assembly', 'endPanel', 'capPanel', 'kick'], type)))
        }]
      },
      {
        title: 'Pivot Door',
        id: 'pivot-door-containers',
        type: 'containerCategory',
        childNodeGroups: [{
          id: 'pivot-door-container-group',
          childNodesContainThumbnails: true,
          childNodes: _.filter(filteredContainerTypes, ({type}) => (_.includes(['pivotDoor'], type)))
        }]
      },
      {
        title: 'Countertop',
        id: 'countertop-containers',
        type: 'containerCategory',
        childNodeGroups: [{
          id: 'countertop-container-group',
          childNodesContainThumbnails: true,
          childNodes: _.filter(filteredContainerTypes, ({type}) => (_.includes(['countertop', 'countertopSupport'], type)))
        }]
      },
      {
        title: 'Other',
        id: 'other-containers',
        type: 'containerCategory',
        childNodeGroups: [{
          id: 'other-container-group',
          childNodesContainThumbnails: true,
          childNodes: _.filter(filteredContainerTypes, ({type}) => (!_.includes(groupedContainerTypes, type)))
        }]
      }
    ]
  }

  getOrnamentMenuNodes = (containerTypes) => {
    return _.filter(containerTypes, (type) => {
      if (type.isOrnament) return type;
    })
  }

  getArchElementMenuNodes = (archElements) => {
    return _.map(archElements, archElement => ({
      ...archElement,
      resourceKey: 'archElement',
      isCreatable: true
    }))
  }

  getArchetypeCategoryMenuNodes = (filteredArchetypes) => {
    return _.map(_.groupBy(filteredArchetypes, 'type'), (archetypeGroup, type) => {
      return {
        title: pluralize(_.startCase(type)),
        type,
        childNodeGroups: [{
          childNodesContainThumbnails: true,
          id: `${type}-archetype-group`,
          childNodes: _.map(archetypeGroup, archetype => {
            return {
              ...archetype,
              resourceKey: 'archetype',
              thumbnail: require('assets/container-icons/custom-icon.png'),
              isCreatable: true
            };
          })
        }]
      };
    })
  }

  getVolumeMenuNodeGroups = () => {
    return [{
      title: 'Volume',
      id: 'volume',
      thumbnail: require('assets/container-icons/floating-base-icon.png'),
      isDisabled: false,
      resourceKey: 'volume',
      isCreatable: true
    }];
  }

  getNonSpacialContainerMenuNodeGroups = (nonSpacialContainers) => {
    return _.map(nonSpacialContainers, nonSpacialContainer => ({
      key: nonSpacialContainer.id,
      isSpeccedContainer: true,
      title: `${_.startCase(nonSpacialContainer.type)} - ${nonSpacialContainer.id}`,
      resourceProps: nonSpacialContainer,
      resourceKey: 'nonSpacialContainer',
      ...nonSpacialContainer,
      isCreatable: true
    }))
  }

  getProductCategoryMenuNodes = () => {
    return _.map(getProductsByCategoryFor({[_.get(this.props.activeEntitiesData, '0.resourceKey')]: _.get(this.props.activeEntities, '0')}), productCategory => {
      return {
        id: `${productCategory.id}-products`,
        type: 'productCategory',
        title: productCategory.title,
        childNodeGroups: [{
          id: `${productCategory.id}-group`,
          childNodesContainThumbnails: true,
          childNodes: _.map(productCategory.productTypes, productType => {
            return {
              ...productType,
              resourceKey: 'product',
              thumbnail: `${K.uploadedFilesUrlPrefix}/public/models/thumbnails/dark/${productType.model.id}/${productType.model.versionId}.png`,
              isCreatable: true
            };
          })
        }]
      };
    })
  }

  handleUpdateConfigurationLibraryCategory = ({configurationLibraryCategory}) => {
    this.setState({configurationLibraryCategory});
  }

  createDragItem = (addItem) => {
    var {project, activeEntitiesData, activeEntities, activeViewEntity, viewMode} = this.props;
    var defaultProps = {
      container: {
        resourceKey: 'container',
        props: {projectId: project.id, versionId: project.versionId, rotation: 0,
          customData: {wrap: {overhang: 1 / 8, boldStyle: {left: 'bold', right: 'bold'}},
            panelStyle: 'panel', lightingType: 'puck', lightingTemp: '2700'},
          dimensions: {width: 10, height: 10, depth: 10},
          type: addItem.type,
        }
      },
      product: {
        resourceKey: 'product',
        props: {
          projectId: project.id,
          ...(_.size(activeEntitiesData) === 1 && this.props.getIncludesActiveEntity({resourceKey: _.get(activeEntitiesData, '0.resourceKey'), id: _.get(activeEntitiesData, '0.id')}) ? {[`${_.get(activeEntitiesData, '0.resourceKey')}InstanceId`]: _.get(activeEntitiesData, '0.id')} : {}),
          versionId: project.versionId,
          productId: addItem.id,
          dimensions: {width: 12, height: 10, depth: 10},
          details: {},
          quantity: 1,
          customData: {},
          applianceData: [],
          rotation: 0,
          position: {x: 0, y: 0, z: 0},
          type: addItem.title,
        }
      },
      archElement: {
        props: {
          type: addItem.type,
          projectId: project.id,
          versionId: project.versionId
        },
      },
      archetype: {resourceKey: 'archetype', props: {
        projectId: project.id, versionId: project.versionId,
        floorId: this.props.activeViewEntityId,
        plan: {
          closed: true,
          points: [
            {
              'x': 200,
              'y': 0,
              'id': lib.string.uuid(),
              'isPseudoPoint': false
            },
            {
              'x': 200,
              'y': 200,
              'id': lib.string.uuid(),
              'isPseudoPoint': false
            },
            {
              'x': 0,
              'y': 200,
              'id': lib.string.uuid(),
              'isPseudoPoint': false
            },
            {
              'x': 0,
              'y': 0,
              'id': lib.string.uuid(),
              'isPseudoPoint': false
            }
          ]
        }
      }},
      volume: {
        resourceKey: 'volume',
        props: {
          projectId: project.id,
          versionId: project.versionId,
          position: {x: 0, y: 0, z: 0},
          dimensions: {depth: 30, width: 50, height: 50},
          rotation: 0,
          outlinesBySideKey: {},
          details: {},
          customData: {}
        }
      }
    };

    if (_.includes(['container', 'ornament'], addItem.resourceKey)) {
      var typeData = Container.getTypeDataFor({container: {type: addItem.type, projectId: project.id, versionId: project.versionId}});

      var dimensions = {...defaultProps.container.props.dimensions, ...typeData.resourceProps.dimensions};
      var customData = {...defaultProps.container.props.customData, ...typeData.resourceProps.customData};

      var props = _.defaultsDeep({props: {...typeData.resourceProps, dimensions, customData}}, {resourceKey: 'container', ...defaultProps.container});

      return props;
    }
    if (addItem.resourceKey === 'nonSpacialContainer') {
      return {props: addItem, resourceKey: 'container', isNonSpacial: true};
    }
    if (addItem.resourceKey === 'product' && _.size(activeEntitiesData) === 1 && this.props.getIncludesActiveEntity({resourceKey: 'container'})) {
      var product = {productId: addItem.id, projectId: project.id, versionId: project.versionId};
      var typeData = Product.getProductData({product});
      var defaultCustomData = {...typeData.customData, ...Product.getDefaultCustomData({product})};

      var dimensions = {...defaultProps.product.props.dimensions, ...Product.getDefaultDimensions({product: {...defaultProps.product.props}, container: activeEntities[0]})};
      var customData = {...defaultProps.product.props.customData, ...defaultCustomData};

      var props = _.defaultsDeep({props: {...typeData.resourceProps, customData, dimensions}}, {resourceKey: 'product', ...defaultProps.product});

      return props;
    }
    if (addItem.resourceKey === 'product' && _.size(activeEntitiesData) === 1 && this.props.getIncludesActiveEntity({resourceKey: 'product'})) {
      var product = {productId: addItem.id, projectId: project.id, versionId: project.versionId};

      if (Product.getIsComponentProduct({product}) || Product.getIsApplianceStackComponent({product})) {
        var typeData = Product.getProductData({product});
        var defaultCustomData = {...typeData.customData, ...Product.getDefaultCustomData({product})};

        var dimensions = {...defaultProps.product.props.dimensions, ...Product.getDefaultDimensions({product: {...defaultProps.product.props}, container: Product.get('container', {product: activeEntities[0]})})};
        var customData = {...defaultProps.product.props.customData, ...defaultCustomData};

        var props = _.defaultsDeep({props: {...typeData.resourceProps, customData, dimensions}}, {resourceKey: 'product', ...defaultProps.product});

        return props;
      }
    }
    if (addItem.resourceKey === 'archElement') {
      typeData = ArchElement.getTypeData({archElement: addItem});

      var customData = {...defaultProps.archElement.props.customData, ...typeData.resourceProps.customData};
      var dimensions = {...defaultProps.archElement.props.dimensions, ...typeData.resourceProps.dimensions};

      var props = _.defaultsDeep({props: {...typeData.resourceProps, customData}}, {resourceKey: 'archElement', ...defaultProps.archElement});

      return props;
    }
    else if (addItem.resourceKey === 'archetype') {
      var dragItem = _.defaultsDeep(addItem, defaultProps.archetype);

      dragItem.props.plan = _.cloneDeep(addItem.roomData.plan);

      var isAddingArchetypeToRoom = viewMode === 'both' || (viewMode === 'top' && _.size(this.props.activeEntities) === 1 && this.props.getIncludesActiveEntity({resourceKey: 'room'}));

      // if (isAddingArchetypeToRoom) {
      //   var activeRoom = viewMode === 'both' ? activeViewEntity : activeEntities[0];

      //   if (activeRoom && !activeRoom.plan.closed) {
      //     isAddingArchetypeToRoom = false;
      //     dragItem.props.floorId = activeRoom.floorId;
      //   }
      // }

      dragItem.props.isAddingArchetypeToRoom = isAddingArchetypeToRoom;

      if (isAddingArchetypeToRoom) {
        var {volumes, containerInstances} = addItem.roomData;

        var volumeFootprints = _.map(volumes, volume => Volume.getFootprintInRoom({volume}));
        var containerFootprints = _.map(containerInstances, container => Container.getFootprintInRoom({container}));

        var entityFootprints = _.concat(volumeFootprints, containerFootprints);
        var flattenedFootprints = _.flatten(entityFootprints);

        var maxX = _.max(_.map(flattenedFootprints, 'x'));
        var minX = _.min(_.map(flattenedFootprints, 'x'));
        var maxY = _.max(_.map(flattenedFootprints, 'y'));
        var minY = _.min(_.map(flattenedFootprints, 'y'));

        dragItem.props.plan.position = {x: minX, y: minY};

        dragItem.props.plan.points = [
          {
            'x': minX,
            'y': minY,
            'id': lib.string.uuid(),
            'isPseudoPoint': false
          },
          {
            'x': maxX,
            'y': minY,
            'id': lib.string.uuid(),
            'isPseudoPoint': false
          },
          {
            'x': maxX,
            'y': maxY,
            'id': lib.string.uuid(),
            'isPseudoPoint': false
          },
          {
            'x': minX,
            'y': minY,
            'id': lib.string.uuid(),
            'isPseudoPoint': false
          }
        ];
      }

      return dragItem;
    }
    else if (addItem.resourceKey === 'volume') {
      return defaultProps.volume;
    }
  };

  handleMenuNodeIsExpandedChange = ({id, value, category, searchInputUpdate=false,  parentNodeIds, reset}) => {
    var searchInput = _.clone(this.state.searchInput);
    if (searchInputUpdate !== false) searchInput = searchInputUpdate;

    var isSearching = searchInput !== '';
    var expandedMenuNodeIdsByCategory = {...this.state[isSearching ? 'expandedMenuNodeIdsByCategoryWhileSearching' : 'expandedMenuNodeIdsByCategory']};
    var headerCategories = ['project', 'products', 'containers', 'archElements', 'archetypes', 'nonSpacialContainers', 'props', 'volumes'];

    if (reset) {
      expandedMenuNodeIdsByCategory[category] = {};
    }

    expandedMenuNodeIdsByCategory[category] = {...expandedMenuNodeIdsByCategory[category], [id]: value};

    _.forEach(parentNodeIds, parentNodeId => {
      expandedMenuNodeIdsByCategory[category][parentNodeId] = true;
    })

    if (_.includes(headerCategories, category) && value === true) {
      _.forEach(headerCategories, headerKey => {
        if (headerKey !== category) expandedMenuNodeIdsByCategory[headerKey][headerKey] = undefined;
      })
    }

    this.setState({[isSearching ? 'expandedMenuNodeIdsByCategoryWhileSearching' : 'expandedMenuNodeIdsByCategory']: expandedMenuNodeIdsByCategory, ...(searchInputUpdate !== false ? {searchInput} : {})});
  }

  render() {
    var {activeEntitiesData, activeViewEntityId, activeViewEntityResourceKey, activeViewEntity, bothIsShowingElevations, viewMode, companyKey, setDragData, getIsActiveEntity, activeEntities, dragItem, handleAddMenuMouseUp, isEmployee, room} = this.props;
    var {addableItemGroups, configurationLibraryCategory} = this.state;

    var groupedContainerTypes = ['base', 'floatingBase', 'baseFreestandingAppliance', 'sculptedPanel', 'kick', 'wall', 'floatingShelves', 'opencase', 'wallFreestandingAppliance', 'wallPanel', 'wallUnitLiner', 'backsplash', 'cornerCounterTransition', 'tall', 'tallFreestandingAppliance', 'baseWithChase', 'islandSeating', 'islandExtension', 'hbIslandExtension', 'islandBackPanel', 'horizontalBarblock', 'rearFacingBarblock', 'endPanel', 'daylightIsland', 'vanity', 'pivotDoor', 'countertop', 'countertopSupport', 'valet', 'assembly', 'capPanel'];

    var containerTypes = Container.getTypesFor({viewKey: (viewMode === 'both' && !bothIsShowingElevations) ? 'top' : viewMode, companyKey, isEmployee});
    containerTypes = _.map(containerTypes, type => ({...type, isCreatable: true, resourceKey: 'container'}));

    var filteredContainerTypes = _.filter(containerTypes, ({isOrnament}) => !isOrnament);

    var archElements = ArchElement.getTypesFor({parentType: viewMode !== 'front' ? 'room' : ''});
    archElements = _.reject(archElements, archElement => _.includes(['pillar', 'polygon', 'rectangularFloorArchitecturalElement', 'rectangularWallArchitecturalElement'], archElement.type));

    var nonSpacialContainers = room
      ? Room.get('nonSpacialContainers', {room})
      : []

    var filteredArchetypes = _.sortBy(_.values(this.props.archetypes), archetype => archetype.type === 'buildingBlock' ? 0 : 1);
    var roomIsSelected = _.size(activeEntities) === 1 && activeEntitiesData[0].resourceKey === 'room';
    var includeBuildingBlocks = viewMode === 'both' || (viewMode === 'top' && roomIsSelected);


    //TODO: confirm not important for adding products based on current if statement
    var someFrontVisible = !_.includes(['schematic'], this.props.activeDetailLevel) && (viewMode === 'front' || (viewMode === 'both' && bothIsShowingElevations) || viewMode === 'lite');

    var containerOrProductSelected = false;

    if (
      activeEntitiesData.length === 1 &&
      (this.props.getIncludesActiveEntity({resourceKey: 'container'}) || this.props.getIncludesActiveEntity({resourceKey: 'product'}))
    ) {
      var resourceKey = this.props.getIncludesActiveEntity({resourceKey: 'product'}) ? 'product' : 'container';

      if (resourceKey === 'container') {
        containerOrProductSelected = !Container.getTypeDataFor({container: _.get(activeEntities, '0')}).isOrnament && _.get(activeEntities, '0.type') !== 'countertop';
      }

      if (resourceKey === 'product') {
        containerOrProductSelected = Product.getHasComponents({product: _.get(activeEntities, '0')});
      }
    }

    if (!(includeBuildingBlocks)) {
      filteredArchetypes = _.filter(filteredArchetypes, archetype => !_.includes(['buildingBlock'], archetype.type));
    }

    var orderedFloors = _.orderBy(this.props.floors, [floorsUsingRank ? 'rank' : 'id'], ['asc']);

    var paneWidthMultiplier = this.state.menuDisplayMode === 'list' ? 1.25 : 1.6;

    var floorsUsingRank = _.some(this.props.floors, floor => floor.rank);

    var orderedFloors = _.orderBy(this.props.floors, [floorsUsingRank ? 'rank' : 'id'], ['asc']);

    var scale = 2.5;

    var roomIsSelected = _.size(activeEntities) === 1 && activeEntitiesData[0].resourceKey === 'room';

    if (this.props.searchInput && addList) {
      addList = _.filter(addList, addItem => {
        return lib.string.isSearchMatch({title: addItem.title, input: this.props.searchInput, distance: 0, matchPlurals: true});
      });
    }

    if (!this.state.isExpanded) {
      return (
        <div
          onClick={() => this.setState({isExpanded: true})}
          style={{cursor: 'pointer', position: 'absolute', bottom: K.spacing * 2, left: K.spacing * 2, borderRadius: 75, width: 50, height: 50, minHeight: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black', color: 'white'}}
        >
          <img src={menuIconWhite} style={{width: 20, height: 20}}/>
        </div>
      );
    }

    var menuNodes = []

    var projectMenuNode = {
      type: 'project',
      id: 'project',
      title: 'project',
      childNodeGroups: [
        {
          addButtonData: {
            title: 'Floor',
            onClick: () => this.setState({isAddingProjectTreeNode: true, projectTreeAddingType: 'floor'}),
          },
          id: 'floors',
          childNodes: [],
          onReorder: ({updates}) => this.handleTreeReorder({updates, resourceKey: 'floor'}),
        }
      ]
    };

    menuNodes.push(projectMenuNode);

    if (someFrontVisible && containerOrProductSelected && activeEntitiesData.length === 1) {
      var activeEntity = activeEntities[0];
      var container = activeEntity.resourceKey === 'container' ? activeEntity : Product.get('container', {product: activeEntity});
      var activeEntityIsFrontFacing = (viewMode === 'both' && bothIsShowingElevations) || viewMode === 'lite' || (viewMode === 'front' && Container.getSideKey({container, viewKey: 'front', elevation: activeViewEntity}) === 'front');

      if (activeEntityIsFrontFacing) {
        var productChildNodes = this.getProductCategoryMenuNodes();

        //HINT if there are no addable products, don't add category
        if (productChildNodes.length > 0) {
          menuNodes.push({
            title: 'products',
            id: 'products',
            childNodeGroups: [{id: 'products-group', childNodes: productChildNodes}]
          });
        }
      }
    }

    menuNodes.push({
      title: 'containers',
      id: 'containers',
      childNodeGroups: [{id: 'containers-group', childNodes: this.getContainerCategoryMenuNodes(filteredContainerTypes, groupedContainerTypes)}],
    });

    menuNodes.push({
      title: 'props',
      id: 'props',
      childNodeGroups: [{id: 'ornaments-group', childNodesContainThumbnails: true, childNodes: this.getOrnamentMenuNodes(containerTypes)}
    ]});

    menuNodes.push({
      title: 'doors & windows',
      id: 'archElements',
      childNodeGroups: [{id: 'archElements-group', childNodesContainThumbnails: true, childNodes: this.getArchElementMenuNodes(archElements)}]
    });

    menuNodes.push({
      title: 'volumes',
      id: 'volumes',
      childNodeGroups: [{id: 'volumes-group', childNodesContainThumbnails: true, childNodes: this.getVolumeMenuNodeGroups()}]
    });

    if (nonSpacialContainers.length > 0) {
      menuNodes.push({
        title: 'unplaced containers',
        id: 'nonSpacialContainers',
        childNodeGroups: [{id: 'nonSpacialContainers-group', childNodesContainThumbnails: true, childNodesContainThumbnails: true, childNodes: this.getNonSpacialContainerMenuNodeGroups(nonSpacialContainers)}]
      });
    }

    if (_.includes(['both', 'top'], viewMode)) {
      menuNodes.push({
        title: 'archetypes',
        id: 'archetypes',
        childNodeGroups: [{id: 'archetypes-group', childNodes: this.getArchetypeCategoryMenuNodes(filteredArchetypes)}]
      })
    }

    _.forEach(orderedFloors, floor => {
      var rooms = Floor.get('rooms', {floor});
      var roomsUsingRank = _.some(rooms, room => room.rank);

      rooms = _.orderBy(rooms, [roomsUsingRank ? 'rank' : 'id'], ['asc']);
      var floorMenuNode = {
        title: floor.title || 'Untitled Floor',
        id: `floor-${floor.id}`,
        type: 'floor',
        onTitleChange: ({value}) => this.props.updateFloor({id: floor.id, props: {title: value ? value : 'Untitled Floor'}}),
        onNavigateTo: this.handleNavigateToEntity('floor', floor.id),
        onClick: this.handleEntityClick('floor', floor.id),
        ...(orderedFloors.length !== 1 ? {onDelete: this.handleTreeNodeDelete({entity: floor, resourceKey: 'floor'})} : {}),
        isDxfUploadable: true,
        entity: floor,
        draggableId: floor.id,
        childNodeGroups: [{
          id: `${floor.id}-rooms`,
          childNodes: [],
          onReorder: ({updates}) => this.handleTreeReorder({updates, resourceKey: 'room'}),
          addButtonData: {
            title: 'Room',
            type: 'room',
            onClick: () => {
              var activeProjectTreeNodeIds = this.state.activeProjectTreeNodeIds;
              activeProjectTreeNodeIds.floorId = floor.id;

              this.setState({isAddingProjectTreeNode: true, projectTreeAddingType: 'room', activeProjectTreeNodeIds})
            }
          },
        }]
      };

      _.forEach(rooms, room => {
        var roomMenuNode = {
          title: room.title || 'Untitled Room',
          id: `room-${room.id}`,
          type: 'room',
          onTitleChange: ({value}) => {
            this.props.updateRoom({id: room.id, props: {title: value}});

            //HINT if there's only 1 scope, update its title to match the room
            if (_.values(Room.get('scopes', {room})).length === 1) {
              this.props.updateScopes({ids: _.map(_.values(Room.get('scopes', {room})), 'id'), props: {title: value}});
            }
          },
          onNavigateTo: this.handleNavigateToEntity('room', room.id),
          onClick: this.handleEntityClick('room', room.id),
          ...(rooms.length !== 1 ? {onDelete: this.handleTreeNodeDelete({entity: room, resourceKey: 'room'})} : {}),
          draggableId: room.id,
          childNodeGroups: [
            {
              id: `${room.id}-elevations`,
              title: 'Elevations',
              childNodes: [],
              onReorder: ({updates}) => this.handleTreeReorder({updates, resourceKey: 'elevation'}),
              ...(_.includes(['top', 'both'], viewMode) ? {
                addButtonData: {
                  appendToChildNodeGroupTitle: true,
                  title: 'Elevation',
                  type: 'elevation',
                  onClick: this.handleAddElevation,
                }
              } : {})
            },
            {
              id: `${room.id}-scopes`,
              title: 'Pricing Scopes',
              childNodes: [],
              ...(_.includes(['top', 'both'], viewMode) ? {
                addButtonData: {
                  appendToChildNodeGroupTitle: true,
                  title: 'Pricing Scope',
                  type: 'scope',
                  onClick: () => this.handleAddScopeButtonPress({floorId: floor.id, roomId: room.id})
                }
              } : {})
            }
          ]
        };

        var {sortedElevations: elevations, scopes} = Room.get(['sortedElevations', 'scopes'], {room});

        _.forEach(elevations, elevation => {
          roomMenuNode.childNodeGroups[0].childNodes.push({
            id: `elevation-${elevation.id}`,
            title: Elevation.getTitle({elevation}),
            type: 'elevation',
            onTitleChange: ({value}) => this.props.updateElevation({id: elevation.id, props: {title: value}}),
            onNavigateTo: this.handleNavigateToEntity('elevation', elevation.id),
            onClick: this.handleEntityClick('elevation', elevation.id),
            ...(elevations.length !== 1 ? {onDelete: this.handleTreeNodeDelete({entity: elevation, resourceKey: 'elevation'})} : {}),
            draggableId: elevation.id,
            childNodeGroups: [{
              id: `elevation-${elevation.id}-containers`,
              childNodesContainThumbnails: true,
              childNodes: _.map(_.uniq(Elevation.get('containers', {elevation})), (container) => {
                var containerType = Container.get('containerType', {container});

                return {
                  isOrnament: containerType.isOrnament,
                  draggableId: container.id,
                  type: 'container',
                  isDraggable: false,
                  isProjectMenuNodeContainer: true,
                  id: `container-${container.id}`,
                  onClick: this.handleEntityClick('container', container.id),
                  title: containerType.title,
                  thumbnail: Container.getTypeDataFor({container}).thumbnail
                  // unitNumber: Canvas
                }
              })
            }]
          });
        });

        _.forEach(scopes, scope => {
          roomMenuNode.childNodeGroups[1].childNodes.push({
            title: scope.title || 'Untitled Scope',
            id: `scope-${scope.id}`,
            draggableId: scope.id,
            isDraggable: false,
            type: 'scope',
            onTitleChange: ({value}) => this.props.updateScope({id: scope.id, props: {title: value}}),
            onNavigateTo: this.handleNavigateToEntity('scope', scope.id),
            ...(Object.keys(scopes).length !== 1 ? {onDelete: this.handleTreeNodeDelete({entity: scope, resourceKey: 'scope'})} : {}),
          });
        });

        floorMenuNode.childNodeGroups[0].childNodes.push(roomMenuNode);
      })

      projectMenuNode.childNodeGroups[0].childNodes.push(floorMenuNode);
    });

    if (this.state.searchInput !== '') {
      var getFilterMenuNodes = (menuNodes) => {
        return _.filter(menuNodes, (menuNode) => {
          var hasUnfilteredChildren = false;

          _.forEach(menuNode.childNodeGroups, (childNodeGroup) => {
            childNodeGroup.childNodes = getFilterMenuNodes(childNodeGroup.childNodes);

            if (childNodeGroup.childNodes.length > 0) hasUnfilteredChildren = true;
          });

          menuNode.childNodeGroups = _.filter(menuNode.childNodeGroups, (childNodeGroup) => {
            return childNodeGroup.childNodes.length > 0;
          });

          //HINT don't want to let people search for base and have it pull up all base containers in a project
          //99% of the time they will be wanting to search for base container to add
          if (menuNode.isProjectMenuNodeContainer && menuNode.type === 'container') return false;

          return _.every(_.lowerCase(this.state.searchInput).split(' '), word => _.includes(_.lowerCase(menuNode.title), word)) || hasUnfilteredChildren;
        })
      };

      menuNodes = getFilterMenuNodes(menuNodes);
    }

    return (<>
      {this.state.isExpanded && (<>
        <HudElement style={{width: K.paneWidth * paneWidthMultiplier, borderRight: K.grayBorder, zIndex: 900, height: 'calc(100vh - 50px)', maxHeight: 'calc(100vh - 50px)', display: 'flex', flexDirection: 'column', justifyItems: 'flex-end', backgroundColor: '#f5f5f5', alignItems: 'space-between', padding: 0
          , overflow: 'hidden', minHeight: 0
          }}>
          <div style={{display: 'flex', flexDirection: 'column', flex: '1 1 auto', minHeight: 0
            // overflow: 'auto'
            }}>
            <div style={{paddingLeft: K.spacing * 2.4, display: 'flex', flexDirection: 'row', marginBottom: K.spacing * 2, marginTop: K.spacing * 1.2, justifyContent: 'space-between'}}>
              <div style={{display: 'flex', flexDirection: 'row', gap: K.spacing}}>
                <img src={listIconBlack} style={{...K.defaultIconSize, cursor: 'pointer', ...(this.state.menuDisplayMode === 'grid' ? {opacity: .4} : {opacity: .9})}} onClick={() => this.setState({menuDisplayMode: 'list'})} />
                <img src={thumbnailsIconBlack} style={{...K.defaultIconSize, cursor: 'pointer', ...(this.state.menuDisplayMode === 'list' ? {opacity: .4} : {opacity: .9})}} onClick={() => this.setState({menuDisplayMode: 'grid'})} />
              </div>
              <div
                onMouseEnter={() => this.setState({isHoveringHideButton: true})}
                onMouseLeave={() => this.setState({isHoveringHideButton: false})}
                onClick={() => this.setState({isExpanded: false})}
                style={{...K.fonts.label, cursor: 'pointer'}}
              >
                <img src={xIconBlack} style={{...K.defaultIconSize, opacity: this.state.isHoveringHideButton ? 1 : 0.8, marginRight: K.spacing}} />
              </div>
            </div>
            {_.map(menuNodes, (menuNode, index) => {
              var isSearching = this.state.searchInput !== '';
              var expandedMenuNodeIdsByCategory = this.state[isSearching ? 'expandedMenuNodeIdsByCategoryWhileSearching' : 'expandedMenuNodeIdsByCategory'];
              var shouldAutoExpand = false;

              //HINT easier to hardcode to tell if a sibling is open
              if (menuNode.id === 'project' && !isSearching && !_.find(menuNodes, menuNode => _.get(expandedMenuNodeIdsByCategory, `[${menuNode.id}][${menuNode.id}]`) === true)) shouldAutoExpand = true;

              return <MenuNode
                key={menuNode.id}
                menuNode={menuNode}
                isHeader={true}
                level={1}
                index={index}
                shouldAutoExpand={shouldAutoExpand}
                productsExpanded={_.some(menuNodes, {id: 'products'}) && _.get(expandedMenuNodeIdsByCategory, `products.products`) !== false}
                sharedNodeAttributes={{
                  category: menuNode.id, expandedMenuNodeIds: expandedMenuNodeIdsByCategory[menuNode.id],
                  activeViewEntityId, activeViewEntityResourceKey, activeViewEntity, configurationLibraryCategory, updateFloor: this.props.updateFloor,
                  handleAddMenuMouseUp, isSearching, getIncludesActiveEntity: this.props.getIncludesActiveEntity,
                  createDragItem: this.createDragItem, setDragData: this.props.setDragData, toggleInfoPopupShowing: this.props.toggleInfoPopupShowing, viewMode, showThumbnails: this.state.menuDisplayMode === 'grid', roomIsSelected, configurationLibraryCategory: this.state.configurationLibraryCategory, handleUpdateConfigurationLibrary: this.handleUpdateConfigurationLibraryCategory, activeDetailLevel: this.props.activeDetailLevel, scale, bothIsShowingElevations, companyKey, viewMode, activeAddingNodeIds: this.state.activeAddingNodeIds, handleSetActiveAddingNodeIds: this.handleSetActiveAddingNodeIds, handleMenuNodeIsExpandedChange: this.handleMenuNodeIsExpandedChange
                }}
              />
            })}
            {this.state.isAddingProjectTreeNode && (
              <AddProjectTreeNodePopup
                onClose={() => this.setState({isAddingProjectTreeNode: false, projectTreeAddingType: ''})}
                handleAddProjectTreeNode={({roomTitle, floorTitle}) => this.handleAddProjectTreeNode({roomTitle, floorTitle})}
                type={this.state.projectTreeAddingType}
              />
            )}
            {this.props.isDragging && !this.props.isDraggingOnCanvas && (
              <div style={{position: 'fixed', zIndex: 100, top: this.props.lastMouseEvent.pageY - 45, left: this.props.lastMouseEvent.pageX - 45, pointerEvents: 'none'}}>
                <Thumbnail item={dragItem}/>
              </div>
            )}
          </div>
          <div style={{height: K.spacing * 2}} />
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div
              style={{display: 'flex', flexDirection: 'row', backgroundColor: 'black', height: 50, alignItems: 'center', zIndex: 1100, width: '100%'}}
              ref={this.openaiRef}
            >
              <div style={{...K.defaultIconSize, cursor: 'pointer', zIndex: 1200, marginLeft: 2}} onClick={() => this.setState({searchInput: ''})}>
                {this.state.searchInput !== '' && (<img src={xIconWhite} style={{...K.defaultIconSize, marginLeft: -1, padding: 0, alignSelf: 'center', justifySelf: 'center'}} />)}
              </div>
              <TextInput
                style={{backgroundColor: 'black', color: 'white', flex: 2, fontSize: 16, padding: 0, margin: 0, alignSelf: 'center'}}
                onInput={(value) => this.setState({searchInput: value})}
                onChange={({value}) => this.handleSubmitTextInput({value})}
                placeholder={'Search...'} //replace with 'how can i help?' once ai prompt is working
                value={_.upperCase(this.state.searchInput)}
              />
              {/* TODO: */}
              {/* <div style={{flex: 1, color: 'white', ...K.fonts.label}}>
                View Suggestions
              </div> */}
            </div>
          </div>
        </HudElement>
      </>)}
    </>)
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var {activeEntitiesData} = ownProps;

    var activeEntities = _.map(activeEntitiesData, (activeEntity) => {
      return {..._.get(state.resources, `[${pluralize(activeEntity.resourceKey)}].byId[${activeEntity.id}]`, {}), resourceKey: activeEntity.resourceKey};
    });

    return {
      scopesByRoomId: state.resources.scopes.byFieldKeyIndex.roomId,
      rooms: state.resources.rooms.byId,
      project: _.values(state.resources.projects.byId)[0],
      containerTypes: state.resources.containerTypes.byId,
      containers: state.resources.containers.byId,
      productTypes: state.resources.productTypes.byId,
      activeEntities,
      activeEntitiesData,
      archetypes: state.resources.archetypes.byId,
      floors: state.resources.floors.byId,
      elevations: state.resources.elevations.byId,
      walls: state.resources.walls.byId,
      scopes: state.resources.scopes.byId,
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.elevations, ['trackElevations', 'createElevation', 'createElevations', 'updateElevations', 'updateElevation', 'destroyElevation', 'destroyElevations', 'modifyElevations']),
    ..._.pick(resourceActions.containers, ['trackContainers', 'updateContainer', 'updateContainers', 'createContainers', 'destroyContainers', 'modifyContainers']),
    ..._.pick(resourceActions.containerTypes, ['trackContainerTypes']),
    ..._.pick(resourceActions.rooms, ['createRoom', 'trackRooms', 'updateRoom', 'updateRooms', 'destroyRoom', 'destroyRooms']),
    ..._.pick(resourceActions.scopes, ['trackScopes', 'createScopes', 'updateScope', 'updateScopes', 'destroyScopes', 'destroyScope']),
    ..._.pick(resourceActions.products, ['trackProducts', 'updateProduct', 'updateProducts', 'createProducts', 'destroyProducts', 'modifyProducts']),
    ..._.pick(resourceActions.productOptions, ['trackProductOptions', 'createProductOptions', 'destroyProductOptions', 'modifyProductOptions']),
    ..._.pick(resourceActions.archElements, ['trackArchElements', 'destroyArchElements']),
    ..._.pick(resourceActions.walls, ['trackWalls', 'destroyWall', 'destroyWalls', 'updateWall', 'updateWalls', 'createWall', 'createWalls', 'modifyWalls']),
    ..._.pick(resourceActions.projectGraphics, ['trackProjectGraphics', 'destroyProjectGraphics']),
    ..._.pick(resourceActions.volumes, ['trackVolumes', 'destroyVolumes']),
    ..._.pick(resourceActions.parts, ['updateParts']),
    ..._.pick(resourceActions.floors, ['createFloor', 'trackFloors', 'updateFloor', 'updateFloors', 'destroyFloor']),
  }
})(EditorMenu);
