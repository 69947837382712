import React, {useState, useEffect, useCallback} from 'react';
import _ from 'lodash';
import K from 'k';

import leftArrowIconWhite from '../assets/left-arrow-icon-white.png';
import xIconWhite from '../assets/x-icon-white.png';

const Slideshow = ({media, updateActiveSlideshowMediumId, onClose, closeDisabled, containerStyle, imageStyle}) => {
  const [activeMediumIndex, setActiveMediumIndex] = useState(0);
  const [isHoveringCloseButton, setIsHoveringCloseButton] = useState(false);
  const [isHoveringBackButton, setIsHoveringBackButton] = useState(false);
  const [isHoveringForwardButton, setIsHoveringForwardButton] = useState(false);

  let activeMedium = media[activeMediumIndex];

  const styles = {
    controlButton: {height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
    },
    hoverOverControl: {
      backgroundColor: '#282828'
    },
    controlButtonIcon: {
      height: 27, width: 27, filter: 'invert(100%)'
    },
    indexIndicator: {
      color: K.colors.white, fontSize: 20, letterSpacing: 0.2, textAlign: 'center'
    }
  };

  const updateIndex = useCallback((index) => {
    setActiveMediumIndex(index);
  });

  const handleKeyPress = (e) => {
    if (e.key === 'ArrowRight') updateIndex(activeMediumIndex === media.length - 1 ? 0 : activeMediumIndex + 1);

    if (e.key === 'ArrowLeft') updateIndex(activeMediumIndex === 0 ? media.length - 1 : activeMediumIndex - 1);

    if (e.key === 'Escape') onClose();
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return (() => window.removeEventListener('keydown', handleKeyPress));
  }, [handleKeyPress]);

  if (!media.length || !media || !activeMedium) return null;

  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <div
        onClick={() => updateIndex(activeMediumIndex === 0 ? media.length - 1 : activeMediumIndex - 1)}
        onMouseEnter={() => setIsHoveringBackButton(true)}
        onMouseLeave={() => setIsHoveringBackButton(false)}
        style={{...styles.controlButton, marginRight: 15, ...(media.length === 1 ? {display: 'none'} : null)}}
      >
        <img src={leftArrowIconWhite} style={styles.controlButtonIcon} />
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          ...containerStyle
        }}
      >
        <img
          src={activeMedium.url}
          style={{objectFit: 'contain', ...imageStyle}}
        />
        {activeMedium.caption ? (
          <div style={{position: 'absolute', padding: 30, height: imageStyle.height || '100%', flexDirection: 'column', justifyContent: 'flex-end', background: 'linear-gradient(transparent 75%, rgba(0, 0, 0, .85))', display: 'flex', width: imageStyle.width || '100%'}}>
            <div style={{color: '#eee', display: 'flex', flexDirection: 'row'}}>{activeMedium.caption}</div>
          </div>
        ) : <></>}
      </div>
      <div
        onClick={() => updateIndex(activeMediumIndex === media.length - 1 ? 0 : activeMediumIndex + 1)}
        onMouseEnter={() => setIsHoveringForwardButton(true)}
        onMouseLeave={() => setIsHoveringForwardButton(false)}
        style={{...styles.controlButton, marginLeft: 15, ...(media.length === 1 ? {display: 'none'} : null)}}
      >
        <img
          src={leftArrowIconWhite}
          style={{...styles.controlButtonIcon, transform: 'rotate(180deg)'}}
        />
      </div>
    </div>);
};

export default Slideshow;