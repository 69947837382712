import React, { useContext, useRef, useState } from 'react';
import K from 'k';
import _ from 'lodash';

import { Transformer } from 'react-konva';

import CanvasRect from 'canvas/canvas-rect';
import CanvasLine from 'canvas/canvas-line';
import CanvasPortal from 'canvas/canvas-portal';
import CanvasDataContext from 'contexts/canvas-data-context';
import NumericInputContext from 'contexts/numeric-input-context';
import PositionHelper from 'helpers/position-helper';
import lib from 'lib';

const CanvasTransformer = ({
  shapeProps,
  transformerProps: customProps,
  isSelected,
  isDisabled,
  multipleEntitiesSelected,
  constraints, // {width, height, step: number} or {fixed: Array<number>[]}
  dropzoneSize, // {width, height}
  dropzoneInset, // {x, y}
  snapToLines, // Array<{from, to}>[]
  disabledAnchors,
  onTransform,
  onTransformEnd,
  customAnchorDragBoundFunc,
  customDragBoundFunc,
  onClick,
  transformerOffset,
  includeOriginalPosition = false,
  isRotatable = true,
  isScalable = true,
  isDraggable = true,
  preventInfinitePrecision = false,
  isMask,
  hideSelectFill,
  isMultiSelectTransformer
}) => {
  const canvasData = useContext(CanvasDataContext);
  const numericInputData = useContext(NumericInputContext);

  const [isRotating, setRotating] = useState();
  const [isScaling, setScaling] = useState();
  const [isMoving, setMoving] = useState();
  const [initialPosition, setInitialPosition] = useState();
  const [cachedPointPositions, cachePositions] = useState();
  const [snapToRotation, setSnapToRotation] = useState();
  const [arrowUpdateType, setArrowUpdateType] = useState();
  const [xSnapLine, setXSnapLine] = useState(null);
  const [ySnapLine, setYSnapLine] = useState(null);
  const [candidateSnapAngles, setCandidateSnapAngles] = useState([0, 90, 180, 270]);

  var infinitePrecision = canvasData.infinitePrecision && !preventInfinitePrecision;

  const shapeRef = useRef();
  const fillRectRef = useRef();
  const transformerRef = useRef();
  const firstAnchorDragPosition = useRef();
  const storeArrowKeyEventRef = useRef();
  const snapAnglesOrthoModeCache = useRef(canvasData.orthoMode);

  React.useEffect(() => {
    const shouldCalculateSnapAngles = (isRotatable && isSelected && ((!isRotating && !isScaling && !isMoving) || snapAnglesOrthoModeCache !== canvasData.orthoMode) && canvasData.scale && canvasData.getSnapData) ? true : false;

    if (shouldCalculateSnapAngles) {
      var updatedSnapAngles = [];
      if (canvasData.orthoMode) {
        updatedSnapAngles = _.map([0, 1, 2, 3, 4], n => shapeProps.rotation % 90 + (90 * n));
      }
      else {
        updatedSnapAngles = canvasData.getSnapData().candidateSnapAngles;
      }

      snapAnglesOrthoModeCache.current = canvasData.orthoMode;

      setCandidateSnapAngles(updatedSnapAngles);
    }
  }, [isSelected, isRotatable, isRotating, isScaling, isMoving, canvasData.scale, canvasData.getSnapData, canvasData.orthoMode]);

  React.useEffect(() => {
    if (isSelected) {
      fillRectRef.current.moveToTop();

      if (!multipleEntitiesSelected) {
        //HINT we need to attach transformer manually
        transformerRef.current.nodes([shapeRef.current]);
        transformerRef.current.getLayer().batchDraw();
        transformerRef.current.moveToTop();
      }
      else {
        // transformerRef.current.nodes([]);
      }
    }
    else {
      fillRectRef.current?.moveToBottom();
      shapeRef.current?.moveToBottom();
    }
  }, [isSelected, multipleEntitiesSelected]);

  //HINT pulled out because we need to trigger whenever shape props change
  React.useEffect(() => {
    if (isSelected && !multipleEntitiesSelected) {
      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isSelected, shapeProps, canvasData, constraints, multipleEntitiesSelected]);

  React.useEffect(() => {
    return () => {
      if (canvasData.stage) {
        canvasData.stage.container().style.cursor = 'inherit';
      }
    }
  }, []);

  const handleMouseMove = (event) => {
    if (isSelected && isDraggable) {
      const container = event.target.getStage().container();

      if (container.style.cursor !== 'move') container.style.cursor = 'move';
    }
  };

  const handleMouseLeave = (event) => {
    if (isSelected && isDraggable) {
      const container = event.target.getStage().container();

      if (container.style.cursor !== 'inherit') container.style.cursor = 'inherit';
    }
  };

  const handleDragMove = (event) => {
    // HINT only drag on left mouse click
    if (event.evt.which !== 1) {
      event.target.stopDrag();
      return;
    }

    if (!isRotating && !isScaling) {
      let transformations = {
        ...shapeProps,
        position: lib.object.sum(PositionHelper.toReal({x: event.target.x(), y: event.target.y()}, {...canvasData, infinitePrecision}), transformerOffset),
      };

      if (snapToRotation) {
        transformations.rotation = snapToRotation;
      }

      if (includeOriginalPosition && transformerRef?.current) {
        const widthOffset = lib.trig.rotate({point: {x: shapeProps.size.width / 2, y: 0}, byDegrees: shapeProps.rotation});

        let cursorPosition = lib.object.difference(transformerRef?.current.getStage().getPointerPosition(), lib.object.multiply(widthOffset, canvasData.scale));

        transformations.mousePosition = lib.object.sum(PositionHelper.toReal(cursorPosition, {...canvasData, infinitePrecision}), transformerOffset);
      }

      onTransform(transformations);
    }
  };

  const handleScaleAndRotate = () => {
    if (!isMoving) {
      /**HINT
       * Transformer is changing scale of the node and NOT its width or height
       * but in the data model we have only size. To match the data better we will
       * reset Konva.scale.
       */
      const node = shapeRef.current;
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();
      let position = lib.object.sum(PositionHelper.toReal({x: node.x(), y: node.y()}, {...canvasData, infinitePrecision}), transformerOffset);

      // Scale logic
      let attemptedSize = {
        width: shapeProps.size.width * scaleX,
        height: shapeProps.size.height * scaleY,
      };

      attemptedSize = _.mapValues(attemptedSize, value => lib.number.round(value, {toNearest: K.minPrecision}));

      if (!_.isEqual(attemptedSize, shapeProps.size) && !isScaling) {
        setScaling(true);
      }

      // Rotation logic
      var attemptedRotation = lib.trig.normalize({degrees: node.rotation()});

      if (attemptedRotation !== shapeProps.rotation && !isRotating) {
        setRotating(true);
      }

      // we will reset it back
      node.scaleX(1);
      node.scaleY(1);

      let mousePosition;
      if (includeOriginalPosition && transformerRef?.current) {
        const widthOffset = lib.trig.rotate({point: {x: attemptedSize.width / 2, y: 0}, byDegrees: attemptedRotation});

        let cursorPosition = lib.object.difference(transformerRef?.current.getStage().getPointerPosition(), lib.object.multiply(widthOffset, canvasData.scale));

        mousePosition = lib.object.sum(PositionHelper.toReal(cursorPosition, {...canvasData, infinitePrecision}), transformerOffset);
      }

      onTransform({
        ...shapeProps,
        position,
        rotation: attemptedRotation,
        size: attemptedSize,
        mousePosition,
        anchorKey: transformerRef?.current.getActiveAnchor()
      });
    }
  };

  //HINT position should be in real
  const constrainPosition = ({position}) => {
    if (dropzoneInset && dropzoneSize) {
      const origin = dropzoneInset;

      const boundingRect = {...origin, ...dropzoneSize};

      const maxX = boundingRect.width + boundingRect.x - shapeProps.size.width;
      const minX = boundingRect.x;
      const maxY = boundingRect.height + boundingRect.y - shapeProps.size.height;
      const minY = boundingRect.y;

      const x = lib.number.constrain(position.x, {min: minX, max: maxX});
      const y = lib.number.constrain(position.y, {min: minY, max: maxY});

      position = {...position, x, y};
    }

    return position;
  };

  //HINT scale snapping
  const anchorDragBoundFunc = (oldPosition, newPosition, event) => {
    if (customAnchorDragBoundFunc) {
      return customAnchorDragBoundFunc(oldPosition, newPosition, event);
    }
    else {
      //HINT do not snap rotating point
      if (transformerRef?.current.getActiveAnchor() === 'rotater') {
        return newPosition;
      }

      const {
        candidateSnapPositions = [],
      } = canvasData.getSnapData();

      const pointPosition = PositionHelper.toReal(lib.object.sum(newPosition, transformerOffset), {...canvasData, infinitePrecision});
      const oldPositionInReal = PositionHelper.toReal(lib.object.sum(oldPosition, transformerOffset), {...canvasData, infinitePrecision});

      if (!firstAnchorDragPosition.current) {
        firstAnchorDragPosition.current = oldPositionInReal;
      }

      let deltaInReal = lib.object.difference(pointPosition, oldPositionInReal);

      //HINT round to current precision setting
      if (Math.abs(deltaInReal.x) > Number.EPSILON) {
        const totalXDelta = pointPosition.x - firstAnchorDragPosition.current.x;
        const difference = lib.number.round(totalXDelta, {toNearest: canvasData.precision}) - totalXDelta;

        deltaInReal.x += difference;
      }

      if (Math.abs(deltaInReal.y) > Number.EPSILON) {
        const totalYDelta = pointPosition.y - firstAnchorDragPosition.current.y;
        const difference = lib.number.round(totalYDelta, {toNearest: canvasData.precision}) - totalYDelta;

        deltaInReal.y += difference;
      }

      // Snap logic
      const lastPosition = lib.object.sum(deltaInReal, oldPositionInReal);

      var snappedData = {};

      const {position: snappedPosition, snapped, snapData} = PositionHelper.snap({snapPositions: candidateSnapPositions, snapLines: [], lastPosition, position: lib.object.sum(deltaInReal, oldPositionInReal), orthoMode: false}, canvasData);
      const snappedPointDelta = lib.object.difference(snappedPosition, lastPosition);

      //HINT It's important that x and y are snapped independently because some points might cause difference snaps than others
      if (snapped.x !== undefined) {
        deltaInReal.x += snappedPointDelta.x;

        snappedData.x = {...snapData.x, sourcePosition: oldPositionInReal};
      }
      if (snapped.y !== undefined) {
        deltaInReal.y += snappedPointDelta.y;

        snappedData.y = {...snapData.y, sourcePosition: oldPositionInReal};
      }

      const deltaAxis = _.filter(_.keys(deltaInReal), key => Math.abs(deltaInReal[key]) > Number.EPSILON);

      var preConstrainedPosition = _.clone(lastPosition);

      // Constraints logic
      if (!_.isEmpty(constraints)) {
        const isLeftOrTop = _.includes(['top-center', 'middle-left'], transformerRef?.current.getActiveAnchor());
        const dimensionConstrainer = new lib.DimensionConstrainer({constraints});

        var modifiedSizeKey = _.includes(['middle-left', 'middle-right'], transformerRef?.current.getActiveAnchor()) ? 'width' : 'height';

        var sizeChange = (isLeftOrTop ? -1 : 1) * lib.trig.rotate({point: deltaInReal, byDegrees: -shapeProps.rotation})[modifiedSizeKey === 'width' ? 'x' : 'y'];//Math.sqrt(deltaInReal.x ** 2 + deltaInReal.y ** 2);

        //TODO scale correctly
        var attemptedSize = {width: shapeProps.size.width + (modifiedSizeKey === 'width' ? sizeChange : 0), height: shapeProps.size.height + (modifiedSizeKey === 'height' ? sizeChange : 0)};

        const constrainedSize = dimensionConstrainer.constrainDimensions(attemptedSize, dimensionConstrainer.computedConstraints);
        const sizeDelta = lib.object.difference(constrainedSize, attemptedSize);

        var rotationOffset = {
          'top-center': 270,
          'middle-left': 180,
          'bottom-center': 90,
          'middle-right': 0
        }[transformerRef?.current.getActiveAnchor()];

        deltaInReal = {
          x: deltaInReal.x + Math.cos(lib.trig.degreesToRadians(shapeProps.rotation + rotationOffset)) * sizeDelta[modifiedSizeKey],//* deltaScalar.x,
          y: deltaInReal.y + Math.sin(lib.trig.degreesToRadians(shapeProps.rotation + rotationOffset)) * sizeDelta[modifiedSizeKey] //* deltaScalar.y
        }
      }

      //HINT dropzone contraining logic
      if (dropzoneInset && dropzoneSize) {
        const origin = dropzoneInset;
        const boundingRect = {...origin, ...dropzoneSize};

        const maxX = boundingRect.width + boundingRect.x;
        const minX = boundingRect.x;
        const maxY = boundingRect.height + boundingRect.y;
        const minY = boundingRect.y;

        const attemptedPosition = lib.object.sum(oldPositionInReal, deltaInReal);
        const constrainDeltaX = lib.number.constrain(attemptedPosition.x, {min: minX, max: maxX}) - attemptedPosition.x;
        const constrainDeltaY = lib.number.constrain(attemptedPosition.y, {min: minY, max: maxY}) - attemptedPosition.y;

        if (_.includes(deltaAxis, 'x') && Math.abs(constrainDeltaX) > Number.EPSILON) {
          deltaInReal = {...deltaInReal, x: deltaInReal.x + constrainDeltaX};
        }
        if (_.includes(deltaAxis, 'y') && Math.abs(constrainDeltaY) > Number.EPSILON) {
          deltaInReal = {...deltaInReal, y: deltaInReal.y + constrainDeltaY};
        }
      }

      _.forEach(['x', 'y'], axis => {
        var setLine = axis === 'x' ? setXSnapLine : setYSnapLine;

        var line = undefined;

        // if (_.isEqual(preConstrainedPosition, lastPosition)) {
          var snapData = _.get(snappedData, axis);

          if (snapData && snapData.isSnapped) {
            var newSnappedSourcePosition = lib.object.sum(snapData.sourcePosition, deltaInReal);

            if (newSnappedSourcePosition[axis] !== snapData.candidateData.snapPosition[axis]) {
              console.log('issue with snapping, SF investigate', newSnappedSourcePosition, oldPositionInReal, deltaInReal, snapData, axis);
            }
            else {
              var oppositeAxisKey = axis === 'x' ? 'y' : 'x';
              var axisValue = snapData.positionValue;

              var oppositeAxisValues = [
                ..._.map(_.filter(snapData.candidatesData, candidate => candidate.snapPosition[axis] === axisValue), `snapPosition.${oppositeAxisKey}`),
                newSnappedSourcePosition[oppositeAxisKey]
              ];

              line = {from: {[axis]: axisValue, [oppositeAxisKey]: _.min(oppositeAxisValues)}, to: {[axis]: axisValue, [oppositeAxisKey]: _.max(oppositeAxisValues)}};
            }
          }
        // }

        setLine(line);
      });

      const position = lib.object.sum(oldPositionInReal, deltaInReal);

      return PositionHelper.toCanvas(position, canvasData);
    }
  };

  const handleTransformEnd = () => {
    setMoving(false);
    setRotating(false);
    setScaling(false);
    setSnapToRotation(null);
    setXSnapLine(null);
    setYSnapLine(null);

    firstAnchorDragPosition.current = null;

    if (onTransformEnd) onTransformEnd();
  };

  const handleKeyDown = (event) => {
    if (document.activeElement.tagName === 'BODY') {
      event.preventDefault();

      let transformAmount = _.max([canvasData.precision, 1/16]);
      const arrowKeyPressed = (
        lib.event.keyPressed(event, 'right') ||
        lib.event.keyPressed(event, 'left') ||
        lib.event.keyPressed(event, 'up') ||
        lib.event.keyPressed(event, 'down')
      );

      if (lib.event.keyPressed(event, 'alt') && arrowKeyPressed && isScalable) {
        numericInputData.toggleNumericInputVisibility(true);
        storeArrowKeyEventRef.current = event;
        setArrowUpdateType('scale');
      }
      else if (canvasData.isShifting && arrowKeyPressed && isDraggable) {
        numericInputData.toggleNumericInputVisibility(true);
        storeArrowKeyEventRef.current = event;
        setArrowUpdateType('transform');
      }
      else if (isDraggable) {
        handleArrowKeyTransform({arrowKeyEvent: event, transformAmount});
      }
    }
  };

  const handleArrowKeyTransform = ({arrowKeyEvent, transformAmount}) => {
    let transform;
    if (lib.event.keyPressed(arrowKeyEvent, 'right')) transform = {x: 1, y: 0};
    else if (lib.event.keyPressed(arrowKeyEvent, 'left')) transform = {x: -1, y: 0};
    else if (lib.event.keyPressed(arrowKeyEvent, 'up')) transform = {x: 0, y: -1};
    else if (lib.event.keyPressed(arrowKeyEvent, 'down')) transform = {x: 0, y: 1};

    if (transform) {
      arrowKeyEvent.preventDefault();

      transform = lib.object.multiply(transform, transformAmount);
      transform = lib.object.round(transform, {toNearest: K.minPrecision});

      if (lib.event.keyPressed(arrowKeyEvent, 'ctrlcmd') && transform) {
        transform = lib.trig.rotate({point: transform, byDegrees: shapeProps.rotation});
      }

      let position;

      if (customDragBoundFunc) {
        position = customDragBoundFunc(PositionHelper.toCanvas(lib.object.sum(shapeProps.position, transform), canvasData), canvasData);
        position = PositionHelper.toReal(position, {...canvasData, infinitePrecision});
      }
      else {
        position = constrainPosition({position: lib.object.sum(shapeProps.position, transform)});
      }

      if (!_.isEqual(position, shapeProps.position)) onTransformEnd({...shapeProps, position});
    }
  };

  const handleArrowKeyScale = ({arrowKeyEvent, transformAmount}) => {
    let transform;
    var sideKey;
    if (lib.event.keyPressed(arrowKeyEvent, 'right')) sideKey = 'right';
    else if (lib.event.keyPressed(arrowKeyEvent, 'left')) sideKey = 'left';
    else if (lib.event.keyPressed(arrowKeyEvent, 'up')) sideKey = 'top';
    else if (lib.event.keyPressed(arrowKeyEvent, 'down')) sideKey = 'bottom';

    if (sideKey) {
      arrowKeyEvent.preventDefault();

      var size = {...shapeProps.size};
      var {position} = shapeProps;

      var sideKeys = ['bottom', 'left', 'top', 'right'];
      var sideKeyIndex = _.indexOf(sideKeys, sideKey);
      let effectiveRotation = shapeProps.rotation;
      //hint not 100% sure why necessary
      if (_.includes([270, 90], shapeProps.rotation)) effectiveRotation = effectiveRotation === 90 ? 270 : 90;
      var effectiveSideKey = sideKeys[(sideKeyIndex + Math.floor(effectiveRotation / 90)) % 4];
      let sizeKey = _.includes(['left', 'right'], effectiveSideKey) ? 'width' : 'height';

      size[sizeKey] += _.toNumber(transformAmount);

      if (!_.isEmpty(constraints)) {
        const clonedSize = _.clone(size);
        const dimensionConstrainer = new lib.DimensionConstrainer({constraints});
        size = dimensionConstrainer.constrainDimensions(size, dimensionConstrainer.computedConstraints);

        if (!_.isEqual(clonedSize, size)) {
          transformAmount = transformAmount - (clonedSize[sizeKey] - size[sizeKey]);
        }
      }

      var sizeChanged = !_.isEqual(size, shapeProps.size);

      if (sizeChanged) {
        var willModifyPosition = false;

        if (_.includes(['left', 'top'], effectiveSideKey)) {
          willModifyPosition = true;
        }

        if (willModifyPosition) {
          if (sideKey === 'right') transform = {x: 1, y: 0};
          else if (sideKey === 'left') transform = {x: -1, y: 0};
          else if (sideKey === 'top') transform = {x: 0, y: -1};
          else if (sideKey === 'bottom') transform = {x: 0, y: 1};

          var constrainedPosition = constrainPosition({position: lib.object.sum(position, lib.object.multiply(transform, transformAmount))});

          //check if position was constrained to something smaller than the attempted size change
          if (!_.isEqual(constrainedPosition, lib.object.sum(position, lib.object.multiply(transform, transformAmount)))) {
            let axisKey = sizeKey === 'width' ? 'x' : 'y';

            size[sizeKey] = size[sizeKey] - Math.abs((constrainedPosition[axisKey] - lib.object.sum(position, lib.object.multiply(transform, transformAmount))[axisKey]));
          }

          position = constrainedPosition;
        }
        else {
          if (dropzoneInset && dropzoneSize) {
            let axisKey = sizeKey === 'width' ? 'x' : 'y';
            const origin = dropzoneInset;
            const boundingRect = {...origin, ...dropzoneSize};
            if (position[axisKey] + size[sizeKey] > boundingRect[sizeKey] + boundingRect[axisKey]) {
              size[sizeKey] = size[sizeKey] - Math.abs(((position[axisKey] + size[sizeKey]) - (boundingRect[sizeKey] + boundingRect[axisKey])));
            }
          }
        }
      }

      if (sizeChanged || !_.isEqual(position, shapeProps.position)) {
        onTransformEnd({
          ...shapeProps,
          size,
          position,
          anchorKey: {
            'top': 'top-center',
            'bottom': 'bottom-center',
            'left': 'middle-left',
            'right': 'middle-right'
          }[effectiveSideKey]
        });
      }
    }
  };

  if (isSelected && numericInputData.isNumericInputSubmitted) {
    if (storeArrowKeyEventRef.current) {
      (arrowUpdateType === 'scale' ? handleArrowKeyScale : handleArrowKeyTransform)({arrowKeyEvent: storeArrowKeyEventRef.current, transformAmount: numericInputData.numericInputValue});
    }
    numericInputData.disableIsNumericInputSubmitted();
    numericInputData.toggleNumericInputVisibility(false);
    storeArrowKeyEventRef.current = null;
  }

  //HINT supports restricting dragging to a dropzone when provided via props
  const dragBoundFunc = (newPosition) => {
    if (customDragBoundFunc) {
      return customDragBoundFunc(newPosition, canvasData);
    }
    else {
      const {position: origin, rotation} = shapeProps;
      const {
        candidateSnapPositions = [],
        sourceSnapPositions = [],
      } = canvasData.getSnapData();

      let initial = initialPosition;
      let cachedPositions = cachedPointPositions;

      const pointPositions = _.cloneDeep(sourceSnapPositions);

      if (!isMoving) {
        setInitialPosition(origin);

        initial = origin;

        cachedPositions = pointPositions.map(position => {
          return {...position, ...lib.trig.rotate({point: lib.object.sum(position, initial), byDegrees: rotation, aroundOrigin: origin})};
        });

        cachePositions(cachedPositions);

        setMoving(true);
      }

      let newPositionInReal = PositionHelper.toReal(newPosition, {...canvasData, infinitePrecision});

      var nearestLineSnapAxes = [];

      if (snapToLines?.length > 0) {
        var nearestLine = _.minBy(_.values(snapToLines), line => lib.trig.distance({fromPoint: newPositionInReal, toLine: line}));

        let nearestPoint = lib.trig.nearestPoint({point: newPositionInReal, onLine: nearestLine});

        //hint fixes axis swapping bug for front view, arch elements in top view still inconsistent
        nearestPoint = lib.object.sum(nearestPoint, transformerOffset, lib.trig.rotate({point: {x: 0, y: -shapeProps.size.height}, byDegrees: rotation}));

        if (nearestLine.from.x !== nearestLine.to.x) nearestLineSnapAxes.push('y');
        if (nearestLine.from.y !== nearestLine.to.y) nearestLineSnapAxes.push('x');

        setSnapToRotation(lib.trig.radiansToDegrees(lib.trig.normalize({radians: lib.trig.alpha({p1: nearestLine.from, p2: nearestLine.to})})));

        newPositionInReal = nearestPoint;
      }

      const deltaInReal = lib.object.difference(newPositionInReal, initial);

      //HINT round to current precision setting
      if (Math.abs(deltaInReal.x) > Number.EPSILON) {
        deltaInReal.x = lib.number.round(deltaInReal.x, {toNearest: canvasData.precision});
      }

      if (Math.abs(deltaInReal.y) > Number.EPSILON) {
        deltaInReal.y = lib.number.round(deltaInReal.y, {toNearest: canvasData.precision});
      }

      const snappedDelta = _.cloneDeep(deltaInReal);
      const leastSnapDistance = {x: Number.MAX_SAFE_INTEGER, y: Number.MAX_SAFE_INTEGER};

      var snappedData = {};

      //Iterate through points and check if any of them snap
      //If so, update the transform to be the snapped transform
      var orthoSnappedAxes = {x: false, y: false};
      cachedPositions.forEach(cachedPointPosition => {
        const lastPosition = _.cloneDeep(cachedPointPosition);

        var pointPosition = lib.object.sum(cachedPointPosition, deltaInReal);

        const {position: snappedPosition, snapped, orthoSnapped, snapData} = PositionHelper.snap({snapPositions: candidateSnapPositions, snapLines: [], lastPosition, position: pointPosition, orthoMode: canvasData.isShifting}, canvasData);
        const snappedPointDelta = lib.object.difference(snappedPosition, lastPosition);

        //HINT It's important that x and y are snapped independently because some points might cause difference snaps than others
        if (snapped.x !== undefined && !orthoSnappedAxes.x && (orthoSnapped.x || leastSnapDistance.x === undefined || snapData.x.candidateData?.distance < leastSnapDistance.x)) {
          if (!_.includes(nearestLineSnapAxes, 'x')) {
            orthoSnappedAxes.x = orthoSnapped.x;
            leastSnapDistance.x = orthoSnapped.x ? 0 : snapData.x.candidateData.distance;
            snappedDelta.x = snappedPointDelta.x;

            snappedData.x = {...snapData.x, sourcePosition: lastPosition};
          }
        }
        if (snapped.y !== undefined && !orthoSnappedAxes.y && (orthoSnapped.y || leastSnapDistance.y === undefined || snapData.y.candidateData?.distance < leastSnapDistance.y)) {
          if (!_.includes(nearestLineSnapAxes, 'y')) {
            orthoSnappedAxes.y = orthoSnapped.y;
            leastSnapDistance.y = orthoSnapped.y ? 0 : snapData.y.candidateData.distance;
            snappedDelta.y = snappedPointDelta.y;

            snappedData.y = {...snapData.y, sourcePosition: lastPosition};
          }
        }
      });

      let position = lib.object.sum(initial, snappedDelta);
      var preConstrainedPosition = _.clone(position);

      position = constrainPosition({position});

      _.forEach(['x', 'y'], axis => {
        var setLine = axis === 'x' ? setXSnapLine : setYSnapLine;

        var line = undefined;

        if (_.isEqual(preConstrainedPosition, position)) {
          var snapData = _.get(snappedData, axis);

          if (snapData && snapData.isSnapped) {
            var newSnappedSourcePosition = lib.object.sum(snapData.sourcePosition, snappedDelta);

            if (newSnappedSourcePosition[axis] !== snapData.candidateData.snapPosition[axis]) {
              console.log('issue with snapping, SF investigate', newSnappedSourcePosition, position, snappedDelta, snapData, axis);
            }
            else {
              var oppositeAxisKey = axis === 'x' ? 'y' : 'x';
              var axisValue = snapData.positionValue;
              //TODO also include other source snap positions on the line
              var oppositeAxisValues = [
                ..._.map(_.filter(snapData.candidatesData, candidate => candidate.snapPosition[axis] === axisValue), `snapPosition.${oppositeAxisKey}`),
                ..._.map(_.filter(cachedPointPositions, pointPosition => pointPosition[axis] + snappedDelta[axis] === newSnappedSourcePosition[axis]), pointPosition => pointPosition[oppositeAxisKey] + snappedDelta[oppositeAxisKey]),
                newSnappedSourcePosition[oppositeAxisKey]
              ];

              line = {from: {[axis]: axisValue, [oppositeAxisKey]: _.min(oppositeAxisValues)}, to: {[axis]: axisValue, [oppositeAxisKey]: _.max(oppositeAxisValues)}};
            }
          }
        }

        setLine(line);
      });

      return PositionHelper.toCanvas(position, canvasData);
    }
  };

  //HINT used to prevent canvas objects from being flipped while scaling, additionally manages constraintsFor logic
  const boundBoxFunc = (oldBoundBox, newBoundBox) => {
    // "boundBox" is an object with
    // x, y, width, height and rotation properties
    // transformer tool will try to fit nodes into that box

    //HINT don't need to constrain if the shape was only rotated
    if (oldBoundBox.width === newBoundBox.width && oldBoundBox.height === newBoundBox.height) return newBoundBox;

    //HINT restrict shapes from being flipped while scaling
    if (newBoundBox.width <= 0 || newBoundBox.height <= 0) return oldBoundBox;

    return newBoundBox;
  };

  const appearanceProps = {
    anchorStroke: 'black',
    anchorCornerRadius: 8,
    anchorSize: 8,
    borderStroke: '#9BCCE1',
  };

  const transformerProps = {
    rotationSnaps: candidateSnapAngles,
    //HINT enforces 90 degree rotation when in orthomode
    rotationSnapTolerance: canvasData.orthoMode ? 45 : 10,
    ignoreStroke: true,
    resizeEnabled: isScalable,
    rotateEnabled: isRotatable,
    enabledAnchors: _.difference(['top-center', 'bottom-center', 'middle-left', 'middle-right'], disabledAnchors), //HINT remove disabledAnchors
    boundBoxFunc,
    anchorDragBoundFunc,
    ...appearanceProps,
    ...customProps,
  };

  const canvasShapeProps = {
    ...(shapeProps.size ? lib.object.multiply(shapeProps.size, canvasData.scale) : {width: shapeProps.width, height: shapeProps.height}),
    ...(shapeProps.position ? PositionHelper.toCanvas(lib.object.sum(shapeProps.position, transformerOffset), canvasData) : {x: shapeProps.x, y: shapeProps.y}),
    rotation: shapeProps.rotation,
    name:shapeProps.name,
    resourceData:shapeProps.resourceData
  };

  const enabledRectProps = {
    draggable: isDraggable,
    onTransform: handleScaleAndRotate,
    onTransformEnd: handleTransformEnd,
    onDragMove: handleDragMove,
    onDragEnd: handleTransformEnd,
    dragBoundFunc,
    onMouseLeave: handleMouseLeave,
    onMouseMove: handleMouseMove,
    name: 'is-selected'
  };

  const rectProps = {
    ...canvasShapeProps,
    ...(isSelected ? enabledRectProps : {}),
    onClick,
    ...({
      stroke: 'transparent',
      fill: 'transparent'
    }),
    ...(isMultiSelectTransformer && canvasData.isShifting ? {listening: false} : {}),
    ...((multipleEntitiesSelected && !canvasData.isShifting) ? {listening: false} : {}),
    ...(isDisabled ? {listening: false} : {})
  };

  return (
    (isSelected ? (
      <>
        <CanvasPortal portalSelector={isMask ? ".masking-layer" : ".hud-layer"}>
          <CanvasRect innerRef={shapeRef} {...rectProps} />
          {(isSelected && !multipleEntitiesSelected) && (<Transformer ref={transformerRef} {...transformerProps} />)}
          {!!xSnapLine && <CanvasLine stroke={'red'} {..._.mapValues(xSnapLine, point => PositionHelper.toCanvas(point, canvasData))} closed/>}
          {!!ySnapLine && <CanvasLine stroke={'red'} {..._.mapValues(ySnapLine, point => PositionHelper.toCanvas(point, canvasData))} closed/>}
        </CanvasPortal>
        <CanvasRect innerRef={fillRectRef} listening={false}
          {...canvasShapeProps}
          {...(!(isSelected && !hideSelectFill) ? {stroke: 'transparent', fill: 'transparent'} : {stroke: '#9BCCE1', fill: '#9BCCE1', opacity: 0.5})}
        />
      </>
    ) : (
      <>
        <CanvasRect innerRef={shapeRef} {...rectProps}/>
        <CanvasRect innerRef={fillRectRef} listening={false}
          {...canvasShapeProps}
          {...(!(isSelected && !hideSelectFill) ? {stroke: 'transparent', fill: 'transparent'} : {stroke: '#9BCCE1', fill: '#9BCCE1', opacity: 0.5})}
        />
        {(isSelected && !multipleEntitiesSelected) && (<Transformer ref={transformerRef} {...transformerProps} />)}
      </>
    ))
  );
};

export default CanvasTransformer;
