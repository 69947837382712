import React from 'react';
import _ from 'lodash';
import K from 'k';

import installationPage2 from '../../../assets/typical-installation-details-2.png'

const InstallationPage2 = () => {
  return (
    <div style={{display: 'flex', height: '100%', width: '100%', justifyContent: 'center'}}>
      <img src={installationPage2} style={{objectFit: 'contain', width: '100%', height: '100%', margin: 'none'}} />
    </div>
  );
};

export default InstallationPage2