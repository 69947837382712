import './App.css';
import React from 'react';
import EditorPage from './pages/editor/editor-page';
import DrawingsPage from './pages/drawings/drawings-page';
import ConfiguratorPage from './pages/configurator/configurator-page';
import ConfiguratorProjectsIndexPage from './pages/configurator-projects-index/configurator-projects-index-page';
import TestPage from './pages/test/test-page';
import Errors from './components/errors';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { store } from 'redux/index.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

global.NODE_ENV = process.env.NODE_ENV;
global.API_DOMAIN = process.env.REACT_APP_API_DOMAIN;


//HINT not working, seems like the function isn't defined when components are being declared
// global.getHandleError = (errorContextProps) => {
//   return ({error, info}) => {
//     global.pushError({error, info, id: lib.string.uuid(), ...errorContextProps});
//   }
// }

function App() {
  var [errors, setErrors] = React.useState([]);
  var [isClientFacing, setIsClientFacing] = React.useState(false);

  global.pushError = (errorData) => {
    if (!_.find(errors, {message: errorData.message})) {
      setErrors([...errors, errorData]);
    }
  }

  global.dismissError = (errorId) => {
    setErrors(_.filter(errors, (error) => error.id !== errorId));
  }

  global.handleError = ({error, info, ...errorContextProps}) => {
    console.log(error);
    global.pushError({error, info, id: lib.string.uuid(), ...errorContextProps});
  }

  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <div style={{height: '100%'}}>
          <Router>
            <Routes>
              <Route exact path='/projects/:projectId/drafts/:versionId/layout' element={<EditorPage onIsClientFacingChange={(value) => setIsClientFacing(value)}/>}/>
              <Route exact path='/projects/:projectId/drafts/:versionId/drawings' element={<DrawingsPage />}/>
              <Route exact path='/projects/:projectId/drafts/:versionId/configurator' element={<ConfiguratorPage />}/>
              <Route exact path='/index/:groupId' element={<ConfiguratorProjectsIndexPage />}/>
              {/* <Route exact path='/index' element={<ConfiguratorProjectsIndexPage />}/> */}
              <Route exact path='/test' element={<TestPage />}/>
              <Route render={() => <div>404</div>}/>
            </Routes>
          </Router>
          {errors.length > 0 && (
            <Errors isClientFacing={isClientFacing} errors={errors}/>
          )}
        </div>
      </DndProvider>
    </Provider>
  );
}

export default App;
