import K from 'k';
import React, { useState } from 'react';
import _ from 'lodash';

import Popup from '../../../components/popup';
import { detailLevelOptions, fillModeOptions, imageModeOptions } from '../drawings-page';

const DrawingsPageSettingsPopup = ({
  onClose, isEditing, detailLevel, visibilityLayers, isFinalApproval,
  showGrid, fillMode, onSubmit, imageMode,
}) => {
  const [updates, setUpdates] = useState(null);
  const [hoveredKey, setHoveredKey] = useState(null);

  const {
    dimensions,
    unitLabels,
    productTitles,
    unitNumbers,
    bindingDimensions,
    perspective,
  } = visibilityLayers;

  const settings = _.defaults(updates, {
    detailLevel,
    unitNumbers,
    unitLabels,
    productTitles,
    dimensions,
    perspective,
    grid: showGrid,
    finalApproval: isFinalApproval,
    bindingDimensions,
    editing: isEditing,
    fillMode,
    imageMode
  });

  const styles = {
    button: (selected) => ({
      marginBottom: 0,
      marginRight: K.spacing,
      fontWeight: selected ? 'bold' : 'normal',
      cursor: 'pointer'
    }),
    buttonsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    action: (key) => ({
      color: K.colors.white,
      background: K.colors.black,
      border: '0px solid transparent',
      ...K.fonts.label,
      height: 20,
      opacity: hoveredKey === key ? 0.7 : 0.9
    }),
    actions: {marginTop: K.spacing, display: 'flex', gap: K.spacing, justifyContent: 'flex-end'}
  };

  const handleSettingsChange = (key, value) => () => {
    if (_.includes(_.keys(visibilityLayers), key)) {
      setUpdates({
        ...updates,
        [key]: value,
        visibilityLayers: _.assign(visibilityLayers, {[key]: value}),
      });
    }
    else {
      setUpdates({...updates, [key]: value});
    }
  };

  const handleApplyClick = () => {
    onSubmit?.(updates);
  };

  const handleCancelClick = () => {
    onClose?.();
  };

  const handleMouseEnter = (key) => () => setHoveredKey(key);
  const handleMouseLeave = () => setHoveredKey(null);

  const getValueLabel = (value) => {
    if ((/true/i).test(value)) return 'On';
    else if ((/false/i).test(value)) return 'Off';

    if (value === 'fullDetail') return 'Full Design';

    return _.startCase(value);
  };

  const items = [
    {label: 'Detail Level', key: 'detailLevel', value: settings.detailLevel, options: _.values(detailLevelOptions)},
    {label: 'Unit Numbers', key: 'unitNumbers', value: settings.unitNumbers},
    {label: 'Plan Labels', key: 'unitLabels', value: settings.unitLabels},
    {label: 'Product Titles', key: 'productTitles', value: settings.productTitles},
    {label: 'Dimensions', key: 'dimensions', value: settings.dimensions},
    // {label: '3" Grid', key: 'grid', value: settings.grid},
    {label: 'Final Approval', key: 'finalApproval', value: settings.finalApproval},
    {label: 'Binding Dimensions', key: 'bindingDimensions', value: settings.bindingDimensions, options: ['true', 'only', 'false']},
    {label: 'Editing Mode', key: 'editing', value: settings.editing},
    // {label: 'Show perspective (in renderings)', key: 'perspective', value: settings.perspective},
    {label: 'Fill Mode', key: 'fillMode', value: settings.fillMode, options: _.values(fillModeOptions)},
    {label: 'Image Quality', key: 'imageMode', value: settings.imageMode, options: _.values(imageModeOptions)}
  ];

  return (<Popup {...{onClose}}>
    <div className="drawings-options-menu">
      {_.map(items, ({label, key, value, options = [true, false]}) => {
        return (
          <div {...{key}} className="drawings-footer-item" style={styles.container}>
            <div>{label}:</div>
            <div style={styles.buttonsContainer}>
              {_.map(options, option => (
                <span key={`${key}-${option}`} className="mode-toggle-option" onClick={handleSettingsChange(key, option)} style={styles.button(`${value}` === `${option}`)}>{getValueLabel(option)}</span>
              ))}
            </div>
          </div>
        );
      })}
      <div style={styles.actions}>
        <button style={styles.action('cancel')} onClick={handleCancelClick} onMouseEnter={handleMouseEnter('cancel')} onMouseLeave={handleMouseLeave}>Cancel</button>
        <button style={styles.action('apply')} onClick={handleApplyClick} onMouseEnter={handleMouseEnter('apply')} onMouseLeave={handleMouseLeave}>Apply</button>
      </div>
    </div>
  </Popup>);
};

export default DrawingsPageSettingsPopup;