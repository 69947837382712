import lib from 'lib';
import React from 'react';
import moment from 'moment';

import TextInput from 'components/text-input';

import '../drawings-page.scss';

export default class CoverPage extends React.PureComponent {
  render() {
    var {project = {}, detailLevel, isFinalApproval} = this.props;
    var companyNameHtml = project.companyKey === 'vp' ? <><span>SPACE.</span><span className="accent-color">THEORY</span></> : 'HENRYBUILT';

    return (
      <React.Fragment>
        {isFinalApproval && (
          project.companyKey === 'vp' ? (
            <div className="approval-container">
              <div className="approval-container-content">
                <h1 className="fir-heading">This is your final sign off package</h1>
                <h1 className="sec-heading">Notes:</h1>
                <div className="final-approval">
                {`These drawings supercede any and all previous drawings, specifications, invoice notes or other prior communication regarding this project. Excluding installation and trim kits, the information in these drawings represents the entirety of your order. Please review accordingly. In order to proceed with production, you must review and confirm that the drawings accurately reflect all items in the list below. If you have questions, please contact us immediately.\n`}
                <br/><b>Please note: No changes are to be made after your project has signed off and been placed into production.</b><br/><br/>
                    {`You are responsible for confirming the following:\n
                    1. All architectural bounding features (walls, ceilings, windows, trim etc) are correctly dimensioned in these drawings. All Space Theory components will be sized to fit within the approved dimensions. All dimensions are to finished surface and take precedence over scale.
                      2. Appliance and fixture specifications and locations.
                      3. Drawer and door positions and door swings.
                      4. Material specifications for all Space Theory supplied materials: fronts, carcasses, panels, pulls, countertop & backsplash, if applicable.
                      5. Overall layout configuration.
                      6. Check individual unit sizes and sizes of product in packaging to assure that sufficient access is available to allow all of the units to be delivered into the home.`}
                    {`\n\nIf approved, please sign each page where indicated to place the project into production.`}
                </div>
              </div>
            </div>
          ) : (
          <div className="approval-container">
            <div className="approval-container-content">
              <h1 className="fir-heading">This is your final sign off package</h1>
              <h1 className="sec-heading">Notes:</h1>
              <div className="final-approval">
              {`These drawings supercede any and all previous drawings, specifications, invoice notes or other prior communication regarding this project. Excluding installation and trim kits, the information in these drawings represents the entirety of your order. Please review accordingly. In order to proceed with production, you must review and confirm that the drawings accurately reflect all items in the list below. If you have questions, please contact us immediately.\n
                  You are responsible for confirming the following:\n
                  1. All architectural bounding features (walls, ceilings, windows, trim etc) are correctly dimensioned in these drawings. All Henrybuilt components will be sized to fit within the approved dimensions. All dimensions are to finished surface and take precedence over scale
                  2. Appliance and fixture specifications and locations
                  3. Drawer and door positions and door swings
                  4. Grain direction
                  5. Material specifications for all Henrybuilt-supplied materials: fronts, carcasses, panels, pulls, countertop & backsplash, if applicable.
                  6. Overall layout configuration
                  7. Check individual unit sizes to assure that sufficient access is available to allow all of the units to be delivered into the home.\n\n`}
                  <b>Any changes made after sign-off will result in project cost increases and production delays.</b>
                  {`\n\nPlease sign each page to approve, or clearly note any changes that are necessary.`}
              </div>
            </div>
          </div>
        ))}
        {!isFinalApproval && detailLevel === 'installation' && (
          <div className="approval-container">
            <div className="approval-container-content">
              <h1 className="fir-heading">IMPORTANT NOTICE:</h1>
              <div className="final-approval">
                {`- It is important to carefully review these drawings and our installation instructions before beginning your installation.\n
                - The starting point for installation is critical. The height of refrigerator cabinets should be established before other cabinets are set to assure proper fit, upper alignment and if refer is paneled, to establish proper panel layout. Wall oven fit is carefully designed based on specific appliances. Note installation instructions for alignment.\n
                - The installer is responsible for confirming all appliance dimensions prior to beginning installation.\n
                - If any questions arise as you read these instructions or during the installation process contact your designer:`}
                <TextInput
                  placeholder="ENTER DESIGNER CONTACT INFORMATION"
                  value={_.get(project, 'drawingsData.designerContact')}
                  onChange={({value}) => this.props.handleUpdateDrawingsData({designerContact: value})}
                  noFill={true}
                  style={{
                    height: 12,
                    width: 'calc(100% - 3rem)',
                    padding: '0.75rem 1.5rem',
                    fontSize: '0.8rem',
                  }}
                />
                <br/><br/>
                {`All designs are property of Henrybuilt Corporation. Reproduction, modification, and use for construction by any party other than Henrybuilt is prohibited.`}
              </div>
              <h1 className="fir-heading" style={{color: 'red', textTransform: 'none'}}>General Notes:</h1>
              <div className="final-approval" style={{color: 'red'}}>
                <div className="general-notes-emphasized" style={{border: '1px solid red'}}>NO EXPOSED FASTENERS ON ANY EXPOSED SURFACES</div><br/>
                <div className="general-notes-emphasized" style={{border: '1px solid red'}}>INSTALLER TO PROVIDE BLOCKING AS REQUIRED, TYP.</div><br/>
                {`See Standard Drawings for installation details and suggested site preparation. Confirm appliance clearance requirements w/ manufacturer specs. before locating adjacent cabinetry to ensure proper fit.\n
                Remove decor strips before installing dishwasher, note opening size. Kick may need to be cut down for door clearance.\n
                Kick should be continuous past the appliance opening. To allow future access to appliance, secure kick, using provided clips, to the leveller foot on an adjacent unit.\n
                Fixed panels oversized to be sized and cut on site.
                Drawer glides and all hardware should be protected or removed when cutouts are required.\n
                Chase wall to be built by installer to support countertop and island. Note chase size on the install drawings.\n
                Outlets and switches not shown or shown for reference only. Cutouts and installation by others.\n
                Stainless steel trim may be supplied loose for appliance openings. To be applied on site by installer as applicable.\n
                When scribing to a wall, ceiling or floor the cuts should be straight to minimize appearance of unevenness.\n`}
              </div>
            </div>
          </div>
        )}
        <div className="cover-container">
          <div className="company-name">{companyNameHtml}</div>
          <div className="project-name">
            {
              `${detailLevel === 'installation' ? project.title + ' - For Installation' : project.title}`||
              project.clientName}{detailLevel === 'production' ? ` - #${project.id}` : ''
            }
          </div>
          <div className="delivery-address">
            <div className="delivery-street-address">{project.deliveryStreetAddress} {project.deliveryStreetAddress2}</div>
            <div className="delivery-city-state-zip">{project.deliveryCity}, {project.deliveryState} {project.deliveryZip}</div>
          </div>
          <div className="issue-date">{moment().format('M / D / YYYY')}</div>
          <div className="signature-container">
            <div className="signature-label">CLIENT SIGNATURE</div>
            <div className="date-label">DATE</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
