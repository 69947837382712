import React, { Fragment } from 'react';
import _ from 'lodash';
import K from 'k';

import Project from 'project-helpers/project';
import Product from 'project-helpers/product';

import {connect } from 'redux/index.js';
import MaterialsAndAppliancesDrawingsDataHelper from '../helpers/materials-and-appliances-drawings-data';

import '../drawings-page.scss';

class MaterialsAndAppliancesPage extends React.Component {
  hideBrokenImage ({event}) {
    //HINT hide images that didn't load
    event.target.style.display = 'none';
  }

  descriptiveThumbnail ({id, title, thumbnail, notes, isPull}) {
    var {paginate} = this.props.page;
    var isNarrow = isPull && !paginate;

    return (
      <div key={`${id}-${title}`} style={{display: 'flex', flexDirection: 'row', width: isNarrow ? 200 : 300, paddingTop: K.spacing * (paginate ? 2 : 1), paddingRight: K.spacing * 2}}>
        <div style={{minWidth: 65, height: 65, border: '1px solid black'}}>
          {thumbnail && (<img src={thumbnail} onError={(event) => this.hideBrokenImage({event})} style={{width: 65, height: 65, ...(this.props.project.companyKey === 'vp' && isPull ? {objectFit: 'contain'} : {})}} />)}
        </div>
        <div style={{marginLeft: K.spacing * (isNarrow ? 1 : 2)}}>
          <div style={{fontWeight: 'bold'}}>{title}</div>
          {_.map(notes, (note, i) => {
            return <div key={i} style={{display: 'flex'}}>
              <div style={{flexDirection: 'row', display: 'flex', width: note.description ? 45 : (isNarrow ? 135 : 235)}}>{note.title || ''}</div>
              <div style={{alignItems: 'center', flexDirection: 'row', display: 'flex', maxWidth: isNarrow ? 100 : 150}}>{note.description || ''}</div>
            </div>;
          })}
        </div>
      </div>
    );
  }

  render() {
    var {project, page, showUnitNumbers, isFinalApproval} = this.props;

    var rooms = Project.get('rooms', {project});
    var products = Project.get('products', {project});
    var {companyKey} = project;
    var companyTitle = companyKey === 'hb' ? 'Henrybuilt' : 'Space Theory';
    var countertopProducts = MaterialsAndAppliancesDrawingsDataHelper.getCountertopProducts({products});
    var sinkCutoutProducts = MaterialsAndAppliancesDrawingsDataHelper.getSinkCutoutProducts({products});
    var drainFieldProducts = MaterialsAndAppliancesDrawingsDataHelper.getDrainFieldProducts({products});
    var lightingProducts = MaterialsAndAppliancesDrawingsDataHelper.getLightingProducts({products});

    var {data, paginate} = page;
    var sortedAppliedMaterialsPages = _.get(data, 'sortedAppliedMaterialsPages');
    var sortedAppliedPullsPages = _.get(data, 'sortedAppliedPullsPages');
    var appliancesByRooms = _.get(data, 'appliancesByRooms');

    _.forEach(appliancesByRooms, ({appliances: applianceSets}, index) => {
      appliancesByRooms[index].appliances = _.map(applianceSets, appliances => _.sortBy(appliances, appliance => _.trim(appliance.title)));
    })

    var styles = {
      section: {padding: K.spacing * 3},
      sectionHeader: {fontSize: '1.25rem'},
      subSection: {padding: K.spacing / 2, paddingBottom: 0, paddingLeft: 0}
    };

    if (paginate && sortedAppliedPullsPages) {
      // var updatedSortedAppliedPullsPages = _.flattenDeep(sortedAppliedPullsPages);

      // sortedAppliedPullsPages = [_.chunk(updatedSortedAppliedPullsPages, 4)];
    }

    return (
      <div style={{display: 'flex', height: '100%'}}>
        {/* <div style={{display: 'flex',  flex: 1, flexDirection: 'row', padding: K.spacing * 3}}> */}
          {(!paginate || (paginate && !appliancesByRooms)) && (<div style={{display: 'flex',  flex: 1, flexDirection: paginate ? 'row' : 'column', ...(paginate ? {padding: K.spacing * 3, paddingRight: 0} : {maxWidth: '50%'})}}>
            <div style={{...(paginate ? {flex: 1} : {...styles.section, maxHeight: '50%'})}}>
              <div style={styles.sectionHeader}>{'Materials'}</div>
              <div style={{display: 'flex'}}>
                {_.map(sortedAppliedMaterialsPages[0], (sortedAppliedMaterialsColumns, i) => (
                  <div key={i} style={{flexDirection: 'column', overflow: 'hidden'}}>
                    {_.map(sortedAppliedMaterialsColumns, ({material, applications}) => {
                      var {id, title, thumbnail, materialType} = material;
                      var locations = [];
                      var uses = [];
                      var dbMaterial = _.find(this.props.materials, {id: material.id});

                      if (dbMaterial) {
                        var pullMedia = _.flatMap(this.props.pulls, 'media');

                        var materialMedium = _.find(dbMaterial.media, medium => {
                          return medium.subjectType === 'icon' && !_.find(pullMedia, {id: medium.id});
                        });

                        thumbnail = materialMedium ? materialMedium.url : thumbnail;
                      }

                      _.forEach(applications, ({appliedDetail, productInstances}) => {
                        uses.push(appliedDetail.compatibleDetail.title);

                        _.forEach(productInstances, (product) => {
                          var room = Product.get('room', {product});

                          locations.push(_.get(room, 'title', 'TBD'));
                        });
                      });

                      var notes = [
                        // `${materialType}`,
                        {title: 'Use:', description: `${_.join(_.map(_.uniq(_.compact(uses)), use => _.startCase(_.replace(use, 'Material', ''))), ', ')}`},
                        {title: 'Location:', description: `${_.join(_.uniq(_.compact(locations)), ', ')}`}
                      ];

                      return this.descriptiveThumbnail({id, title, thumbnail, notes});
                    })}
                  </div>
                ))}
              </div>
            </div>
            {!paginate && (<div style={{height: 1, borderTop: K.grayBorder, marginLeft: K.spacing * 3, marginRight: K.spacing * 3}} />)}
            {paginate && (<div style={{width: 1, borderLeft: K.grayBorder}} />)}
            <div style={{...(paginate ? {flex: 1, marginLeft: K.spacing * 3} : {...styles.section, maxHeight: '50%'})}}>
              <div style={styles.sectionHeader}>{'Pulls'}</div>
              <div style={{display: 'flex', flexDirection: paginate ? 'row' : 'column'}}>
                {_.map(sortedAppliedPullsPages[0], (sortedAppliedPullsRows, i) => {
                  return (<div key={i} style={{display: 'flex', gap: K.spacing, ...(paginate ? {flexDirection: 'column', overflow: 'hidden'} : {})}}>
                    {_.map(sortedAppliedPullsRows, ({pull, pullMaterial, applications}) => {
                      var {id, title, thumbnail} = pull;
                      var locations = [];
                      var materialUses = [];

                      var dbPullId = id;

                      if (companyKey === 'vp') {
                        //HINT
                        //ST pulls are hardcoded in the DE and were later added to the DB
                        //So the ids are not matching
                        dbPullId = _.find([
                          {hardcoded: 1, db: 27}, //   {id: 1, title: 'Radius Staple'},
                          {hardcoded: 2, db: 28}, //   {id: 2, title: 'Radius Flat Tab'},
                          {hardcoded: 3, db: 30}, //   {id: 3, title: 'Knurled Knob'},
                          {hardcoded: 4, db: 31}, //   {id: 4, title: 'Curved Knob'},
                          {hardcoded: 5, db: 32}, //   {id: 5, title: 'Notched Knob'},
                          {hardcoded: 6, db: 34}, //   {id: 6, title: 'No Pulls'},
                          {hardcoded: 7, db: 33}, //   {id: 7, title: 'Touch Latch'},
                          {hardcoded: 8, db: 29}, //   {id: 8, title: 'Wire Radius Staple'},
                          {hardcoded: 9, db: 35}, //   {id: 9, title: 'Pulls by Others'},
                          {hardcoded: 10, db: 36}, //   //{id: 10, title: 'Scooped Bottom'}
                        ], {hardcoded: id}).db;
                      }

                      var dbPull = this.props.pulls[dbPullId] || {};

                      //find media associated with pull and pull material, if no pull material or no media with both, get a baseline graphic
                      var pullMedia;

                      var hasIcon = _.some(dbPull.media, {subjectType: 'icon'});

                      if (pullMaterial) {
                        var dbPullMaterial = _.find(this.props.materials, {id: pullMaterial.id});
                        var dbPullMaterialMedia = dbPullMaterial ? dbPullMaterial.media : [];

                        pullMedia = _.find(dbPull.media, media => {
                          return _.find(dbPullMaterialMedia, {id: media.id});
                        });

                        //HINT hb wants to use icons no matter what
                        if (!pullMedia || companyKey === 'hb') {
                          //HINT if no photo of pull in specified material, use generic pull photo
                          pullMedia = _.find(dbPull.media, media => {
                            return (hasIcon ? media.subjectType === 'icon' : true) && !_.find(dbPullMaterialMedia, {id: media.id});
                          });
                        }
                      }
                      else {
                        pullMedia = _.find(dbPull.media, media => {
                          return (hasIcon ? media.subjectType === 'icon' : true);
                        });
                      }

                      _.forEach(applications, ({appliedDetail, pullMaterialAppliedDetails, productInstances}) => {
                        _.forEach(productInstances, product => {
                          var room = Product.get('room', {product});
                          var {title, id} = room;

                          if (!_.find(locations, title)) locations.push(title);
                        });

                        if (pullMaterialAppliedDetails) materialUses.push(pullMaterialAppliedDetails.title);
                      });

                      return (
                        this.descriptiveThumbnail({
                          id: `${pull.id}-${pullMaterial ? pullMaterial.id : ''}`,
                          title,
                          thumbnail: _.get(pullMedia, 'presignedUrl'),
                          isPull: true,
                          notes: [
                            {title: `${pullMaterial ? pullMaterial.title : ''}`},
                            {title: 'Location:', description: `${_.join(_.uniq(_.compact(locations)), ', ')}`}
                          ]
                        })
                      );
                    })}
                  </div>);
                })}
              </div>
            </div>
          </div>)}
          {!paginate && (<div style={{width: 1, borderLeft: K.grayBorder, marginTop: K.spacing * 2}} />)}
          {(!paginate || (paginate && appliancesByRooms)) && (<div style={{flex: 1, ...styles.section}}>
            <div style={{position: 'relative', display: 'flex', flexWrap: 'wrap', alignContent: 'space-between', flexDirection: 'column', ...(!paginate ? {height: '100%'} : {})}}>
              {companyKey === 'vp' && (<div style={{position: 'absolute', right: 0, justifyContent: 'right', textAlign: 'right', width: 250, opacity: 0.7}}>{'Note: All appliances and fixtures below are supplied by others, unless noted otherwise.'}</div>)}
              <div style={styles.sectionHeader}>{'Appliances & Fixtures'}</div>
              <div style={{marginTop: K.spacing * 2, ...(paginate ? {display: 'flex', flexDirection: 'row'} : {flex: 1})}}>
                <div style={{display:'flex', flex: 1, flexDirection: 'column', ...(paginate ? {minWidth: 675} : {})}}>
                  {_.map((paginate ? _.slice(appliancesByRooms, 0, Math.max(3, Math.ceil(appliancesByRooms.length / 2))) : appliancesByRooms), ({roomIds, appliances}) => {
                    var roomTitles = _.map(roomIds, (roomId) => (_.find(rooms, ['id', roomId]).title));

                    return (
                      <div key={roomIds[0]} style={{marginBottom: K.spacing * 2}}>
                        <div style={{fontWeight: 'bold', marginBottom: K.spacing}}>{_.toUpper(_.join(roomTitles, ', '))}</div>
                        {_.map(_.flatten(appliances), (applianceData, i) => (
                          <div key={`${roomIds[0]}-${i}`} style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', width: 500}}>
                            <div style={{width: 100, fontWeight: 'bold'}}>
                              {_.get(applianceData, 'applianceType') !== undefined
                                ? _.capitalize(applianceData.applianceType) + ': '
                                : 'Other: '
                              }
                            </div>
                            <div style={{marginLeft: K.spacing, display: 'flex', alignItems: 'center'}}>{applianceData.label}</div>
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </div>
                {paginate && (<div style={{display: 'flex', flexDirection: 'column'}}>
                  {_.map(_.slice(appliancesByRooms, Math.max(3, Math.ceil(appliancesByRooms.length / 2)), appliancesByRooms.length), ({roomIds, appliances}) => {
                    var roomTitles = _.map(roomIds, (roomId) => (_.find(rooms, ['id', roomId]).title));

                    return (
                      <div key={roomIds[0]} style={{marginBottom: K.spacing * 2}}>
                        <div style={{fontWeight: 'bold', marginBottom: K.spacing}}>{_.toUpper(_.join(roomTitles, ', '))}</div>
                        {_.map(_.flatten(appliances), (applianceData, i) => (
                          <div key={`${roomIds[0]}-${i}`} style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', width: 500}}>
                            <div style={{minWidth: 90, maxWidth: 90, fontWeight: 'bold'}}>
                              {_.get(applianceData, 'applianceType') !== undefined
                                ? _.capitalize(applianceData.applianceType) + ': '
                                : 'Other: '
                              }
                            </div>
                            <div style={{marginLeft: K.spacing, display: 'flex', alignItems: 'center'}}>
                              {applianceData.label}
                            </div>
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </div>)}
              </div>
            </div>
          </div>)}
        {/* </div> */}
        <div className="drawings-side-panel-container clearfix">
          <div className="drawings-side-panel">
            <div className="drawings-side-panel-content" style={styles.section}>
            {(!paginate || (paginate && !appliancesByRooms)) && (<>
              <div style={styles.sectionHeader}>Project Options</div>
              <div style={{fontWeight: 'bold', paddingTop: K.spacing}}>Lighting</div>
              <div>
                {lightingProducts.length ? (
                  <div style={styles.subSection}>{`Provided by ${companyTitle} where noted. All other locations by others.`}</div>
                ) : (
                  <div style={styles.subSection}>None Included</div>
                )}
              </div>
              <div style={{fontWeight: 'bold', paddingTop: K.spacing}}>Countertops</div>
              {countertopProducts.length > 0 ? (
                <>
                  <div style={styles.subSection}>
                    {`Provided by ${companyTitle} as noted below and on drawings. All other countertops provided by others.`}
                  </div>
                  <div style={{...styles.subSection, paddingTop: K.spacing}}>
                    {showUnitNumbers ? `Units: ${_.join(_.filter(_.map(countertopProducts, product => product.productionId)), ', ')}` :
                      `Countertops by ${companyTitle} In: ${_.join(_.uniq(_.map(countertopProducts, product => {
                        var room = Product.get('room', {product});

                        return _.get(room, 'title', '');
                      })), ', ')}`
                    }
                  </div>
                </>
              ) : (
                <div style={styles.subSection}>All countertops provided by others.</div>
              )}
              {companyKey === 'vp' && (
                <>
                  <div style={{fontWeight: 'bold', paddingTop: K.spacing}}>
                    {`Sink Cutouts by ${companyTitle}${(showUnitNumbers || sinkCutoutProducts.length < 1) ? '' : ' In'}:`}
                  </div>
                  <div style={{...styles.subSection}}>
                    {sinkCutoutProducts.length ? (showUnitNumbers ? _.join(_.map(sinkCutoutProducts, product => product.productionId), ', ') : _.join(_.uniq(_.map(sinkCutoutProducts, product => {
                        var room = Product.get('room', {product});

                        return _.get(room, 'title', '');
                      })), ', ')) : 'None'}
                  </div>
                  <div style={{fontWeight: 'bold', paddingTop: K.spacing}}>
                    {`Drainfields by ${companyTitle}${(showUnitNumbers || drainFieldProducts.length < 1) ? '' : ' In'}:`}
                  </div>
                  <div style={{...styles.subSection}}>
                    {drainFieldProducts.length ? (showUnitNumbers ? _.join(_.map(drainFieldProducts, product => product.productionId), ', ') : _.join(_.uniq(_.map(drainFieldProducts, product => {
                        var room = Product.get('room', {product});

                        return _.get(room, 'title', '');
                      })), ', ')) : 'None'}
                  </div>
                  <div style={{...styles.subSection, paddingTop: K.spacing}}>
                    All other cutouts to be completed by installer onsite.
                  </div>
                </>
              )}
            </>)}
            </div>
            <div style={{...styles.section, paddingLeft: K.spacing * 3, paddingRight: K.spacing * 3, marginBottom: 0}}>
              {isFinalApproval && (!paginate || (paginate && appliancesByRooms)) && (<div style={{display: 'flex', flexDirection: 'column', marginBottom: K.spacing}}>
                <div style={{...styles.sectionHeader}}>
                  Final Approval
                </div>
                <div style={{fontSize: '1rem', paddingTop: K.spacing * 2}}>
                  This is your final approval package. All materials, pulls, appliances, fixtures, and product options listed above are final and cannot be adjusted once placed into production. By signing below, client confirms all decisions are accurate and correct.
                </div>
              </div>)}
            </div>
            <div className="signature-container">
              <div className="signature-content">
                <div className="signature-label">CLIENT SIGNATURE</div>
                <div className="date-label">DATE</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    return {
      materialClasses: state.resources.materialClasses.byId,
      materialTypes: state.resources.materialTypes.byId,
      pulls: state.resources.pulls.byId,
      // media: _.orderBy(state.resources.media.byId, ['rank'], ['desc']),
      materials: state.resources.materials.byId
    };
  }
})(MaterialsAndAppliancesPage);
