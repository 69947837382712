import _ from 'lodash';

export default function getDimensionsLayerForDetailLevel({activeDetailLevel, project}) {
  var dimensionsLayer = 'client';

  if (project.companyKey === 'vp') return 'client';

  if (_.includes(['production'], activeDetailLevel)) dimensionsLayer = 'production';
  if (_.includes(['fullDetail', 'intermediate'], activeDetailLevel)) dimensionsLayer = 'client';
  if (_.includes(['installation'], activeDetailLevel)) dimensionsLayer = 'installation';
  if (_.includes(['rendering'], activeDetailLevel)) dimensionsLayer = 'rendering';
  if (_.includes(['schematic'], activeDetailLevel)) dimensionsLayer = 'schematic';

  return dimensionsLayer;
}
